// @flow

import React from 'react'

import Banner from 'react-ui/components/Banner'
import pageReload from 'services/pageReload'
import { Button } from 'care-ui'

const ReleaseWarningBanner = () => (
  <Banner>
    <Button onClick={pageReload} variant="text">
      <b>A new version of the app has been released.</b> Please{' '}
      <u>click here</u> to reload and avoid errors.
    </Button>
  </Banner>
)

export default ReleaseWarningBanner
