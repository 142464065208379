// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { synBlue } from 'react-ui/themes/synergy-base-theme/colors'

type Props = {
  contract: ?string,
  privacyUrl: ?string,
}

const styledRulesLink = () => ({
  color: synBlue['500'],
})

const UserRemoveInstructions = ({ contract, privacyUrl }: Props) => {
  const { css } = useFela()

  const isAhs = contract === 'ahs'
  const isLondon = contract === 'london'

  const emailAddress = isAhs ? 'emh@ahs.com' : 'femapresearch@lhsc.on.ca'

  return isAhs || isLondon ? (
    <>
      To be removed from the Innowell platform, email your request to:{' '}
      <a className={css(styledRulesLink)} href={`mailto:${emailAddress}`}>
        {emailAddress}
      </a>
      {isLondon && <span> or call 519-646-6000, ext. 65196</span>}
      <br />
      Please refer to the{' '}
      <a className={css(styledRulesLink)} href={privacyUrl}>
        privacy policy
      </a>{' '}
      for further details.
    </>
  ) : null
}

export default UserRemoveInstructions
