// @flow

import React, { useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { upperCase } from 'lodash'
import { flatMap, startCase } from 'lodash/fp'

import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import {
  Box,
  Button,
  DropDownMenu,
  FlexContainer,
  Heading,
  Icon,
} from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import AssessmentRequesteesListItem from './AssessmentRequesteesListItem'

import type { AssessmentRequesteesList_individual$key } from './__generated__/AssessmentRequesteesList_individual.graphql'

type Props = {
  individual: ?AssessmentRequesteesList_individual$key,
  requesteeRoleId: ?string,
  setRequesteeRoleId: string => void,
}

const fragment = graphql`
  fragment AssessmentRequesteesList_individual on IndividualRole {
    id
    legal_name
    support_person_assignments {
      id
      accepted_invitation
      relationship
      support_person {
        id
        legal_name
      }
    }
  }
`

const AssessmentRequesteesList = ({ individual: fragRef }: Props) => {
  const individual = useFragment(fragment, fragRef)
  const {
    setAssessmentRequesteeId,
    assessmentRequesteeId,
    setPreSelected,
  } = useAssessmentRequesteeContext()

  const [closeDropDown, setCloseDropDown] = useState(true)

  const { id: individualId, legal_name, support_person_assignments } =
    individual || {}

  const activeSupportPersons = flatMap(assignment => {
    return assignment.accepted_invitation
      ? { ...assignment.support_person, relationship: assignment.relationship }
      : []
  })(support_person_assignments)

  if (activeSupportPersons.length === 0) return null

  const allOptions = [{ id: individualId, legal_name }, ...activeSupportPersons]

  const selectedOption = allOptions.find(
    option => option.id === assessmentRequesteeId,
  )

  const handleClick = () => {
    setPreSelected(null)
    setAssessmentRequesteeId(individualId)
    setCloseDropDown(true)
  }

  const handleSupportClick = requesteeId => {
    setPreSelected(null)
    setAssessmentRequesteeId(requesteeId)
    setCloseDropDown(true)
  }

  const toggleDropDownMenu = () => {
    setCloseDropDown(!closeDropDown)
  }

  return (
    <Box width="100%">
      <div style={{ marginBottom: '8px' }}>
        <Heading level={6}>Select person to send a request to</Heading>
      </div>

      <DropDownMenu
        fullWidth
        closeDropDown={closeDropDown}
        setCloseDropDown={setCloseDropDown}
        dropDownTrigger={
          <Button
            variant="secondary"
            onClick={toggleDropDownMenu}
            dataTestId="drop-down-trigger"
            fullWidth
          >
            <Box width="100%">
              <FlexContainer
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <AssessmentRequesteesListItem
                  title={selectedOption?.legal_name || 'Select a person'}
                  subtitle={selectedOption?.relationship || 'INDIVIDUAL'}
                  listItem={false}
                />
                <Icon as={ArrowDown} scale={0.5} />
              </FlexContainer>
            </Box>
          </Button>
        }
      >
        <>
          <AssessmentRequesteesListItem
            title={legal_name || 'Individual'}
            subtitle="INDIVIDUAL"
            onClick={handleClick}
          />

          {activeSupportPersons.map(activeSupportPerson => (
            <AssessmentRequesteesListItem
              title={activeSupportPerson.legal_name}
              subtitle={startCase(upperCase(activeSupportPerson.relationship))}
              onClick={() => handleSupportClick(activeSupportPerson.id)}
            />
          ))}
        </>
      </DropDownMenu>
    </Box>
  )
}

export default AssessmentRequesteesList
