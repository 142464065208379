// @flow

import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { format, isAfter, isSameDay } from 'date-fns'

import { getIntervalMethod } from 'shared/utils/GraphUtils'

import { type QueryVariableType } from './useInsights'

// Utility function for date formatting
const formatDate = date => format(date, 'yyyy-MM-dd')

type UseGraphPaginationProps = {
  loadQuery: (queryVariables: QueryVariableType) => void,
  queryVariables: QueryVariableType,
}

const useGraphPagination = (props: UseGraphPaginationProps) => {
  const { queryVariables, loadQuery } = props
  const { start_date, end_date, interval } = queryVariables

  const defaultPaginationState = {
    startDate: start_date,
    endDate: end_date,
    isRightPaginationDisabled: true,
  }

  const [paginationState, setPaginationState] = useSetState(
    defaultPaginationState,
  )
  const { startDate, endDate, isRightPaginationDisabled } = paginationState

  const { sub, add } = getIntervalMethod(interval)

  const onPaginate = (direction: 'left' | 'right') => {
    const newStartDate = formatDate(
      direction === 'left'
        ? sub(new Date(startDate ?? ''), 1)
        : add(new Date(startDate ?? ''), 1),
    )
    const newEndDate = formatDate(
      direction === 'left'
        ? sub(new Date(endDate ?? ''), 1)
        : add(new Date(endDate ?? ''), 1),
    )

    setPaginationState({
      startDate: newStartDate,
      endDate: newEndDate,
    })

    loadQuery({
      ...queryVariables,
      start_date: newStartDate,
      end_date: newEndDate,
    })

    // Disable Right Pagination
    const shouldDisabledRightPagination =
      isSameDay(new Date(newEndDate ?? ''), new Date()) ||
      isAfter(new Date(newEndDate ?? ''), new Date())

    setPaginationState({
      isRightPaginationDisabled: shouldDisabledRightPagination,
    })
  }

  // update pagination state if global interval selector is updated
  useEffect(
    () => {
      setPaginationState({
        startDate: start_date,
        endDate: end_date,
        isRightPaginationDisabled: true,
      })
    },
    [queryVariables],
  )

  return {
    onPaginate,
    isRightPaginationDisabled,
  }
}

export default useGraphPagination
