// @flow

import React from 'react'
import { useFela } from 'react-fela'

import AlertIcon from 'react-ui/assets/icons/alert-circle.svg'
import Card from 'react-ui/components/Card'
import { H2 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { Button } from 'care-ui'

const pageStyle = ({ theme }) => ({
  backgroundColor: theme.palette.bodyBackgroundBlue,
  height: 'calc(100vh - 4.5rem)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const cardStyle = () => ({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  maxWidth: '480px',
  minHeight: '400px',
  justifyContent: 'center',
})

const cardHeaderStyle = () => ({
  maxWidth: '400px',
})

const messageStyle = () => ({
  fontSize: '1.5rem',
  marginTop: '0',
  marginBottom: '2rem',
  padding: '1rem',
})

const UnauthorizedPage = () => {
  const { css } = useFela()
  const { homepage_path } = useCurrentUser()

  return (
    <div className={css(pageStyle)}>
      <Card extend={cardStyle}>
        <Icon as={AlertIcon} scale={4} />
        <H2 extend={cardHeaderStyle}>Unauthorised Access</H2>
        <p className={css(messageStyle)}>
          You are not authorised to access this page. For access, please contact
          your supervisor or systems admin.
        </p>
        <Button variant="primary" to={homepage_path}>
          Back to Home
        </Button>
      </Card>
    </div>
  )
}

export default UnauthorizedPage
