// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import DataGrid from 'react-ui/components/DataGrid'
import { timeColumnConfig } from 'react-ui/components/Tables/columns'

import type { MyEscalationsTable_escalations } from './__generated__/MyEscalationsTable_escalations.graphql'

type PropsType = {
  escalations: MyEscalationsTable_escalations,
}

const customStyles = () => ({
  body: {
    minWidth: 'auto',
  },
  headerVisible: {
    minWidth: 'auto',
  },
})

const MyEscalationsTable = ({ escalations }: PropsType) => (
  <DataGrid
    data={escalations}
    defaultSort={{ key: 'created_at', direction: 1 }}
    columns={['label', 'created_at']}
    extend={customStyles}
    columnConfig={{
      label: {
        label: 'Health Card',
      },
      created_at: {
        ...timeColumnConfig(false),
        label: 'Date / Time',
      },
    }}
  />
)

export const MyEscalationsTableLoader = createFragmentContainer(
  MyEscalationsTable,
  {
    escalations: graphql`
      fragment MyEscalationsTable_escalations on Escalation
        @relay(plural: true) {
        created_at
        label
      }
    `,
  },
)
