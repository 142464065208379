// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'

import { AccordionItem } from 'react-ui/components/Accordion'
import ToggleButton from 'react-ui/components/Form/ToggleButton'
import * as colors from 'react-ui/themes/synergy-base-theme/colors'
import { commit } from 'mutations/UserResearchProgramsResponseCreate'
import { createFlash } from 'platform_web/containers/Flash'

type PropsType = {
  researchProgram: Object,
  userResponse: Object,
}

const accordionItemStyle = () => ({
  AccordionItem: {
    marginTop: '1.2rem',
    marginBottom: '0',
    backgroundColor: 'white',
    borderRadius: '0.3rem',
    boxShadow: `${colors.greyscale['200']} 0 0 0.4rem`,
  },
  header: {
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '0.3rem',
    paddingRight: 0,
    borderRadius: '0.3rem',
  },
  headerContainer: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '0.3rem',
    height: '2.6rem',
    paddingTop: '0.6rem',
  },
  heading: {
    fontSize: '1rem',
    borderRadius: '0.3rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: 'white',
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '1.9rem',
    paddingRight: '1.9rem',
    borderRadius: '0 0 4px 4px',
  },
})

const offButtonStyle = () => ({
  paddingRight: '1rem',
  position: 'relative',
  bottom: '0.31rem',
})

const completeSubmit = () => {
  createFlash({
    message: 'Thank you, your selection was recorded.',
    type: 'notice',
    timeout: 0,
  })
}

const offButtonData = (css, title, isChecked, onChange) => (
  <div className={css(offButtonStyle)}>
    <ToggleButton id={title} checked={isChecked} onChange={onChange} />
  </div>
)

const UserResearchProgramsResponse = ({
  researchProgram: { title, description },
  userResponse: { accepted, user_id, tenant_research_program_id },
}: PropsType) => {
  const { css } = useFela()
  const environment = useRelayEnvironment()
  const [isOpen, setOpen] = useState(false)
  const [isChecked, setChecked] = useState(accepted)
  const onChange = () => {
    const answers = [
      {
        accepted: !isChecked,
        user_id,
        tenant_research_program_id,
      },
    ]
    commit({
      onCompleted: () => completeSubmit(),
      environment,
      variables: {
        input: {
          user_research_programs_response_attributes: answers,
        },
      },
    })
    setChecked(!isChecked)
  }

  return (
    <AccordionItem
      withArrow
      extend={accordionItemStyle}
      heading={title}
      offButton={offButtonData(css, title, isChecked, onChange)}
      isOpen={isOpen}
      onToggleOpen={() => setOpen(!isOpen)}
    >
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </AccordionItem>
  )
}

export default UserResearchProgramsResponse
