// @flow

import React from 'react'
import { useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { type FormikProps } from 'formik'

import Heading from 'react-ui/components/Heading'
import { Welcome2Col } from 'react-ui/components/Layout'
import Link from 'react-ui/components/Link'
import { commit as commitUserMfaAuthenticate } from 'mutations/UserMfaAuthenticate'
import MfaForm from 'platform_web/pages/authentication/components/MultiFactorAuthenticationForm'

import type { MultiFactorAuthentication_user } from './__generated__/MultiFactorAuthentication_user.graphql'

type PropsType = {
  user: MultiFactorAuthentication_user,
}

type FormProps = {
  code: string,
}

const qrCodeContainerStyle = () => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '1em',
})

const qrCodeImgStyle = () => ({
  width: '40%',
  padding: '5px',
  border: '1px solid black',
})

export const MultiFactorAuthentication = ({ user }: PropsType) => {
  const { mfa_qr_code, mfa_last_verified_at } = user

  const { css } = useFela()

  const qrCodeImg = `data:image/svg+xml;utf8,${encodeURIComponent(mfa_qr_code)}`

  const environment = useRelayEnvironment()

  const handleSubmit = (
    { code }: FormProps,
    { setSubmitting, setStatus }: FormikProps,
  ) => {
    setStatus()

    const onCompleted = (_, errors) => {
      const errorMsg = errors?.length ? 'Invalid verification code' : null
      setSubmitting(false)
      setStatus({ error: errorMsg })
    }

    commitUserMfaAuthenticate({
      environment,
      onCompleted,
      variables: {
        input: {
          code,
        },
      },
    })
  }

  const signOutMsg = mfa_last_verified_at
    ? "I don't have the code now, log me out"
    : "I can't set it up now, log me out"

  return (
    <Welcome2Col data-testid="MfaLoader">
      <Heading level={3}>Secure your account</Heading>
      {mfa_last_verified_at ? (
        <div>
          <p>Please enter your authenticator app verification code below.</p>
        </div>
      ) : (
        <>
          <div>
            <p>
              Scan the QR code with your authentication app and enter the
              verification code below.
            </p>
          </div>
          <div className={css(qrCodeContainerStyle)}>
            <img
              alt="QR Code"
              src={qrCodeImg}
              className={css(qrCodeImgStyle)}
              id="qrCode"
            />
          </div>
        </>
      )}

      <MfaForm onSubmit={handleSubmit} />
      <Link to="signingOut" style={{ marginTop: '1rem' }}>
        {signOutMsg}
      </Link>
    </Welcome2Col>
  )
}

export const MultiFactorAuthenticationLoader = createFragmentContainer(
  MultiFactorAuthentication,
  {
    user: graphql`
      fragment MultiFactorAuthentication_user on User {
        mfa_qr_code
        mfa_last_verified_at
      }
    `,
  },
)
