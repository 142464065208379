// @flow

import { useRouter } from 'found'
import { get } from 'lodash'
import * as yup from 'yup'

import { useFeatureToggle } from 'react-ui/hooks/useFeatureToggle'
import { getFormIntialValues } from 'platform_web/utility/form'

const getFormFields = (SMS_INVITE, intialEmailValue) => {
  const formFields = [
    {
      id: SMS_INVITE ? 'username' : 'email',
      label: SMS_INVITE ? 'Username' : 'Email',
      type: SMS_INVITE ? 'text' : 'email',
      placeholder: SMS_INVITE ? 'Username' : 'name@domain',
      initialValue: SMS_INVITE ? '' : intialEmailValue,
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
    },
    {
      id: 'rememberMe',
      type: 'checkbox',
      label: 'Keep me signed in',
      initialValue: false,
    },
  ]

  return formFields
}

const getValidationSchema = SMS_INVITE => {
  const emailValidation = yup
    .string()
    .label('Email')
    .email()
    .required()

  const usernameValidation = yup
    .string()
    .label('Username')
    .required()

  const passwordValidation = yup
    .string()
    .label('Password')
    .min(8)
    .required()

  return {
    email: SMS_INVITE ? undefined : emailValidation,
    password: passwordValidation,
    username: SMS_INVITE ? usernameValidation : undefined,
    rememberMe: yup.boolean(),
  }
}

const useSignInForm = () => {
  const { SMS_INVITE } = useFeatureToggle()

  // Getting email from url after region selector redirects to new url
  const { match } = useRouter()
  const email = get(match, 'location.query.email')
  const intialEmailValue = email ? decodeURIComponent(email) : ''

  // Form Fields
  const formFields = getFormFields(SMS_INVITE, intialEmailValue)
  // Initial Form Values
  const initialValues = getFormIntialValues(formFields)

  return {
    formFields,
    initialValues,
    validationSchema: getValidationSchema(SMS_INVITE),
  }
}

export default useSignInForm
