// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import parser from 'html-react-parser'

import { longDateFormat } from 'services/dateTime'
import { Text } from 'care-ui'

import type { ClinicianNoteCard_clinician_note$key } from './__generated__/ClinicianNoteCard_clinician_note.graphql'

type PropsType = {
  clinicianNote: ClinicianNoteCard_clinician_note$key,
}

const cardStyle = ({ theme }) => ({
  padding: `${theme.care.spacing.xxs} ${theme.care.spacing.sm}`,
  background: theme.care.palette.surface.subtle,
  borderRadius: theme.care.radius.sm,
  width: '-webkit-fill-available',
})

const dateStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  marginBottom: theme.care.spacing.xxs,
})

const infoStyle = () => ({
  display: 'block',
  whiteSpace: 'pre-wrap',
})

const fragment = graphql`
  fragment ClinicianNoteCard_clinician_note on ClinicianNote {
    text
    created_at
  }
`

const ClinicianNoteCard = ({ clinicianNote: fragmentRef }: PropsType) => {
  const clinicianNote = useFragment(fragment, fragmentRef)
  const { text, created_at } = clinicianNote
  const { css } = useFela()

  const parsedDisplayText = parser(text)
  const parsedOccuredAt = longDateFormat(new Date(created_at))

  return (
    <div className={css(cardStyle)}>
      <Text size="sm" color="title" extend={dateStyle}>
        {parsedOccuredAt}
      </Text>
      <Text size="lg" color="title" extend={infoStyle}>
        {parsedDisplayText}
      </Text>
    </div>
  )
}

export default ClinicianNoteCard
