// @flow

import React from 'react'
import { useFela } from 'react-fela'
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from 'react-relay'

import Card from 'react-ui/components/Card'
import ToggleButton from 'react-ui/components/Form/ToggleButton'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import { commit as commitNotificationSettings } from 'mutations/IndividualDetail/NotificationPreferenceUpdateInput'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { SuspenseLoader } from 'care-ui'

const cardStyle = () => ({
  display: 'flex',
})

const toggleStyle = () => ({
  lineHeight: '20px',
})

const query = graphql`
  query UserNotificationsPage_Query {
    viewer {
      currentUser {
        ...UserNotificationsPage_user
      }
    }
  }
`
const fragment = graphql`
  fragment UserNotificationsPage_user on User {
    notification_preference {
      sms
      id
    }
  }
`

const UserNotificationsPage = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const user = useFragment(fragment, currentUser)
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const setPreferences = () => {
    commitNotificationSettings({
      environment,
      variables: {
        input: {
          id: user.notification_preference.id,
          notification_preference: {
            sms: !user.notification_preference.sms,
          },
        },
      },
    })
  }

  return (
    <>
      <Heading level={3}>SMS notifications</Heading>
      <Card>
        <div className={css(cardStyle)}>
          <div className={css(toggleStyle)}>
            <ToggleButton
              id="notification_settings"
              onChange={setPreferences}
              checked={user.notification_preference.sms}
            />
          </div>
        </div>

        <p>
          Your mobile number will be used to send you reminders and
          notifications. You can turn SMS notifications on or off anytime.
        </p>
        <p>
          Innowell will never share your mobile number. If you want more
          information on your data please see our
          <Link to={privacyPolicyUrl()}> privacy policy.</Link>
        </p>
      </Card>
    </>
  )
}

export const UserNotificationsPageLoader = () => (
  <SuspenseLoader message="Notifications Settings">
    <UserNotificationsPage />
  </SuspenseLoader>
)
