// @flow

import React, { type Node, Fragment } from 'react'
import { connect } from 'react-fela'

import Heading from 'react-ui/components/Heading'
import Card from 'shared/ui/Layout/Card/Card'
import { Circle } from 'shared/ui/Shapes/Circle'
import { Tick } from 'shared/ui/Typography/Icons'
import { Button } from 'care-ui'

import OnboardingTemplate from './OnboardingTemplate'
import styleRules from './styles'

import { type FelaRulesType } from 'react-ui/typing'

type PropsType = {
  handleQuestionsSubmit?: (event?: SyntheticEvent<any>) => any,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
  userName: Node,
}

const OnboardingFinished = ({
  styles = {},
  rules,
  userName,
  handleQuestionsSubmit,
}: PropsType) => (
  <OnboardingTemplate
    pageTitle={<Fragment>Great job {userName}!</Fragment>}
    pageContent="Remember, you can edit your information at any time from the
    profile settings in your main menu"
  >
    <Card className={styles.cardContainer}>
      <br />
      <Circle
        fill="green"
        alignment="none"
        size="xl"
        className={styles.circleFinished}
      >
        <Tick size="xl" color="white" />
      </Circle>
      <Heading level={3} extend={rules.cardTitleFinished}>
        Your Profile
      </Heading>
      <div className={styles.cardContentFinished}>
        <p>Completed</p>
      </div>
      <Button variant="primary" onClick={handleQuestionsSubmit}>
        Continue
      </Button>
    </Card>
  </OnboardingTemplate>
)

export default connect(styleRules)(OnboardingFinished)
