// @flow

import React, { useEffect } from 'react'
import { withFormik } from 'formik'
import { useRouter } from 'found'
import Link from 'found/Link'
import { get } from 'lodash'
import * as yup from 'yup'

import Heading from 'react-ui/components/Heading'
import { Form } from 'shared/ui/Forms'
import { Input } from 'shared/ui/Forms/Fields'
import { Layout as l } from 'shared/ui/Styles'
import { Box, Button } from 'care-ui'

import RegionSelector from './RegionSelector'

import s from './PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type NewPasswordFormValues = {
  email: string,
}

type NewPasswordFormPropsType = FormikProps & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  values: NewPasswordFormValues,
}

const NewPasswordForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  status = {},
  touched,
  values,
  setValues,
}: NewPasswordFormPropsType) => {
  // Getting email from url after region selector redirects to new url
  const { match } = useRouter()
  const email = get(match, 'location.query.email')

  useEffect(() => {
    if (email) {
      setValues({ ...values, email: decodeURIComponent(email) })
    }
  }, [])

  return (
    <div>
      <Heading
        level={3}
        extend={({ theme }) => ({
          color: theme.palette.component.primary.base,
        })}
      >
        Forgot Your Password?
      </Heading>
      <p>
        Please enter your email below to receive password reset instructions.
      </p>
      <Form onSubmit={handleSubmit}>
        {status.error && <p className={s.mainError}>{status.error}</p>}
        <Input
          name="email"
          type="email"
          id="user_email"
          label="Email"
          placeholder="Email"
          autoComplete="email"
          fullWidth
          lightGrayLabel
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />

        <RegionSelector email={values.email} />

        <Box marginY="sm">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Send instructions
          </Button>
        </Box>

        <Link to="/users/sign_in" className={l.smallLink}>
          Back to login
        </Link>
      </Form>
    </div>
  )
}

export default withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({ email: '' }),

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required('Email is required'),
  }),
})(NewPasswordForm)
