// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { Select } from 'care-ui'

import type { FeedbackTopicSelect_viewer } from './__generated__/FeedbackTopicSelect_viewer.graphql'

type PropsType = {
  +value: string,
  +viewer: FeedbackTopicSelect_viewer,
}

const FeedbackTopicSelect = (props: PropsType) => {
  const {
    value,
    viewer: { enums: { feedback: { topic_list } } },
    ...other
  } = props

  return (
    <Select
      value={value}
      options={[{ key: '', value: '--Please Select--' }, ...topic_list]}
      valueKey="key"
      labelKey="value"
      {...(other: any)}
    />
  )
}

export const FeedbackTopicSelectLoader = createFragmentContainer(
  FeedbackTopicSelect,
  {
    viewer: graphql`
      fragment FeedbackTopicSelect_viewer on Viewer {
        enums {
          feedback {
            topic_list {
              key
              value
            }
          }
        }
      }
    `,
  },
)
