// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useRouter } from 'found'

import { Button, DropDownMenu, DropDownMenuItem, Icon } from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import type { TrackableDropdown_user } from './__generated__/TrackableDropdown_user.graphql'

type PropsType = {
  user: ?TrackableDropdown_user,
}

const TrackableDropdownBase = ({ user }: PropsType) => {
  const { router } = useRouter()

  const { summaryUserTrackables } = user || {}
  const { nodes: userTrackables } = summaryUserTrackables || {}

  const routeToBOCByTrackableId = ({ trackable_id }) => () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: user?.id,
        trackable_id,
      },
    })
  }

  const dropDownItems = userTrackables?.map(userTrackable => {
    const { trackable } = userTrackable || {}
    const routeParams = {
      trackable_id: trackable.id,
    }

    return (
      <DropDownMenuItem
        key={`${trackable.label}-dropdown`}
        onClick={routeToBOCByTrackableId(routeParams)}
        dataTestId={trackable.label}
        ariaLabel={trackable.label}
      >
        {trackable.label}
      </DropDownMenuItem>
    )
  })

  return (
    <DropDownMenu
      position="end"
      dropDownTrigger={
        <Button
          variant="text"
          size="md"
          ariaLabel="See more details"
          dataTestId="see-more-details"
        >
          See more details
          <Icon as={ArrowDown} scale={0.6} />
        </Button>
      }
    >
      <>{dropDownItems}</>
    </DropDownMenu>
  )
}

const TrackableDropdown = createFragmentContainer(TrackableDropdownBase, {
  user: graphql`
    fragment TrackableDropdown_user on User {
      id
      summaryUserTrackables: user_trackables(kinds: [gauge]) {
        nodes {
          trackable {
            id
            label
          }
        }
      }
    }
  `,
})

export default TrackableDropdown
