// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { getOr } from 'lodash/fp'

import { conditionalFormulaRangeDescription } from 'components/UserTrackable/services'
import { UserTrackableRawScoreLoader } from 'components/UserTrackable/UserTrackableRawScore'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { healthCardNoRating } from 'services/colours'
import { Box, FlexContainer, Heading } from 'care-ui'

import ScoreCardsNotifications from './ScoreCardsNotifications'

import type { CurrentResultCardFormula_user_trackable } from './__generated__/CurrentResultCardFormula_user_trackable.graphql'

type PropsType = {
  careOptionSupported: boolean,
  user_trackable: CurrentResultCardFormula_user_trackable,
}

const updatedStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyMd,
  color: theme.palette.component.muted.mutedBase,
})

const descriptionStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const rawScoreStyle = () => ({
  RawScore: {
    background: 'white',
    margin: '0',
    minHeight: '1.9rem',
    fontWeight: 'lighter',
    justifyContent: 'flex-start',
    '> div:last-child': {
      marginLeft: '1rem',
    },
  },
})

const CurrentResultCardFormulaComponent = ({
  careOptionSupported,
  user_trackable,
  user_trackable: { time_since_updated },
}: PropsType) => {
  const [completedNotification, setNotification] = useState(false)
  const policies = usePolicies()
  const defaultDescription =
    'To find out your score simply complete the questionnaire.'
  const color = getOr(healthCardNoRating, 'formula_range.color', user_trackable)
  const description = getOr(
    defaultDescription,
    'formula_range.description',
    user_trackable,
  )
  const { css } = useFela({ color })

  const label = getOr(null, 'formula_range.label', user_trackable)
  const showNotifications =
    user_trackable.escalated &&
    user_trackable.assigned_to_me &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  return (
    <Box
      dataTestId="CurrentResultCardFormula"
      paddingX="xs"
      paddingY="xs"
      borderTop={`8px solid ${color}`}
      borderRadius="md"
      height="100%"
      width="100%"
    >
      <FlexContainer direction="column" gap="xxxs" height="100%" wrap="nowrap">
        <Heading level={3}>Current Result: {label}</Heading>
        <div className={css(updatedStyle)}>
          Last updated: {time_since_updated} ago
        </div>

        <UserTrackableRawScoreLoader
          extend={rawScoreStyle}
          user_trackable={user_trackable}
        />

        {showNotifications &&
          !completedNotification && (
            <ScoreCardsNotifications
              setNotification={setNotification}
              userTrackable={user_trackable}
              careOptionSupported={careOptionSupported}
            />
          )}

        {(!showNotifications || completedNotification) && (
          <div className={css(descriptionStyle)}>
            {conditionalFormulaRangeDescription({ label, description })}
          </div>
        )}
      </FlexContainer>
    </Box>
  )
}

export const CurrentResultCardFormula = createFragmentContainer(
  CurrentResultCardFormulaComponent,
  {
    user_trackable: graphql`
      fragment CurrentResultCardFormula_user_trackable on UserTrackable {
        id
        escalated
        assigned_to_me
        time_since_updated
        suggested_user_resources {
          ...ScoreCardsCareOptionsList_user_resources @relay(mask: false)
        }
        resolution_option_list {
          key
          value
          enabled
        }
        formula_range {
          ... on FormulaRange {
            id
          }
          ... on FormulaRangeInterface {
            color
            label
            description
          }
        }
        ...UserTrackableRawScore_user_trackable
      }
    `,
  },
)
