/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type DataGridRowFooterContentLoader_user$ref = any;
type StaffActions_user$ref = any;
type UserCardLoader_user$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffEmployeesTableLoader_staff_admin$ref: FragmentReference;
declare export opaque type StaffEmployeesTableLoader_staff_admin$fragmentType: StaffEmployeesTableLoader_staff_admin$ref;
export type StaffEmployeesTableLoader_staff_admin = {|
  +staff: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +user: {|
          +email: string,
          +invitation_created_at: ?any,
          +legal_name: ?string,
          +invitation_delta: ?string,
          +$fragmentRefs: AvatarLoader_user$ref & UserCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref & StaffActions_user$ref,
        |},
        +roles: $ReadOnlyArray<{|
          +id?: string,
          +role_type?: RoleTypes,
          +role_status?: string,
          +description?: string,
          +tenant?: ?{|
            +name: string,
            +requires_mfa: boolean,
          |},
        |}>,
      |}
    |}>
  |},
  +$refType: StaffEmployeesTableLoader_staff_admin$ref,
|};
export type StaffEmployeesTableLoader_staff_admin$data = StaffEmployeesTableLoader_staff_admin;
export type StaffEmployeesTableLoader_staff_admin$key = {
  +$data?: StaffEmployeesTableLoader_staff_admin$data,
  +$fragmentRefs: StaffEmployeesTableLoader_staff_admin$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "query_type"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "staff"
        ]
      }
    ]
  },
  "name": "StaffEmployeesTableLoader_staff_admin",
  "selections": [
    {
      "alias": "staff",
      "args": [
        {
          "kind": "Variable",
          "name": "query_type",
          "variableName": "query_type"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        }
      ],
      "concreteType": "StaffConnection",
      "kind": "LinkedField",
      "name": "__StaffEmployeesTableLoader_staff_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StaffEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Staff",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invitation_created_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "legal_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invitation_delta",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AvatarLoader_user"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserCardLoader_user"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DataGridRowFooterContentLoader_user"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StaffActions_user"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "roles",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role_type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role_status",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Tenant",
                          "kind": "LinkedField",
                          "name": "tenant",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "requires_mfa",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "RoleInterface",
                      "abstractKey": "__isRoleInterface"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StaffAdmin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0e4357756bebb6655f727819f4718a5f';

module.exports = node;
