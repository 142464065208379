// @flow

import React, { useEffect, useState } from 'react'
import { useRouter } from 'found'
import { size } from 'lodash/fp'

import { UserQuestionnaireLoader } from 'components/UserQuestionnaire/UserQuestionnaire'
import QuestionnaireComplete from 'react-ui/components/QuestionnaireCompletePage'
import { OnboardingStepContextProvider } from 'react-ui/contexts/OnboardingStepContext'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import useOnboardingSteps from 'react-ui/hooks/useOnboardingSteps'
import { individualOnboardingStepNumber } from 'services/onboardingSteps'

import type { QuestionnaireShowPage_role } from './__generated__/QuestionnaireShowPage_role.graphql'
import type { QuestionnaireShowPage_supportees } from './__generated__/QuestionnaireShowPage_supportees.graphql'
import type { QuestionnaireShowPage_user_questionnaire } from './__generated__/QuestionnaireShowPage_user_questionnaire.graphql'
import type { QuestionnaireShowPage_user } from './__generated__/QuestionnaireShowPage_user.graphql'

type PropsType = {
  relay: Object,
  role: QuestionnaireShowPage_role,
  router: Object,
  supportees: QuestionnaireShowPage_supportees,
  user: QuestionnaireShowPage_user,
  user_questionnaire: QuestionnaireShowPage_user_questionnaire,
}

// This page also accepts data loaded from `QuestionnaireShowPageLoader`
export const QuestionnairePage = (props: PropsType) => {
  const [shouldRender, setShouldRender] = useState(false)
  const { router } = useRouter()
  const { user_questionnaire, role, supportees, user } = props

  const roleTypeFromProps = role.role_type
  const { hasClinicianRole } = useCurrentUserRoles()
  const role_type = hasClinicianRole ? 'CLINICIAN' : roleTypeFromProps

  const {
    user_research_programs_responses,
    unanswered_tenant_research_programs,
  } = useCurrentUser()

  const { onboardingSteps, setCurrentStep } = useOnboardingSteps()

  const { INITIAL_QUESTIONNAIRE, VIEW_RESULTS } = individualOnboardingStepNumber

  const tenantResearchExist =
    size(user_research_programs_responses) +
    size(unanswered_tenant_research_programs)
  const {
    RESEARCH_AND_DATA_SHARING,
    ...restOfOnboardingSteps
  } = onboardingSteps
  const currentOnboardingSteps = tenantResearchExist
    ? { RESEARCH_AND_DATA_SHARING, ...(restOfOnboardingSteps: any) }
    : restOfOnboardingSteps

  const {
    ready_to_complete,
    finished,
    thank_you_page_relevant,
    first_questionnaire_interaction,
  } = user_questionnaire

  const shouldRedirect =
    (ready_to_complete || finished) && !thank_you_page_relevant

  useEffect(
    () => {
      if (shouldRedirect) {
        let name

        switch (role_type) {
          case 'INDIVIDUAL': {
            name = 'dashboard'
            break
          }
          case 'CLINICIAN': {
            name = 'clinician_individuals'
            break
          }
          case 'SUPPORT_PERSON': {
            name = 'support_person_supportee_selection'
            break
          }
          default: {
            name = 'root'
            break
          }
        }
        router.replace({ name })
      }
      setShouldRender(true)
    },
    [shouldRedirect],
  )

  useEffect(() => {
    setCurrentStep(INITIAL_QUESTIONNAIRE)
  }, [])

  useEffect(
    () => {
      if (ready_to_complete) {
        setCurrentStep(VIEW_RESULTS)
      }
    },
    [ready_to_complete],
  )

  const renderQuestionnaire = () => {
    // --- Initial Questionnaire flow ---
    const shouldShowInitialQuestionnaire =
      role_type === 'INDIVIDUAL' && first_questionnaire_interaction
    if (shouldShowInitialQuestionnaire) {
      return (
        <UserQuestionnaireLoader
          user_questionnaire={user_questionnaire}
          role={role}
        />
      )
    }

    const shouldShowThankYouPage =
      (ready_to_complete || finished) && thank_you_page_relevant
    // --- Normal Questionnaire flow ---
    if (shouldShowThankYouPage) {
      return (
        <QuestionnaireComplete
          roleType={role_type}
          supportees={supportees}
          userQuestionnaire={user_questionnaire}
          user={user}
        />
      )
    }

    return (
      <UserQuestionnaireLoader
        user_questionnaire={user_questionnaire}
        role={role}
      />
    )
  }

  return (
    shouldRender && (
      <OnboardingStepContextProvider
        currentOnboardingSteps={currentOnboardingSteps}
        setCurrentStep={setCurrentStep}
      >
        {renderQuestionnaire()}
      </OnboardingStepContextProvider>
    )
  )
}
