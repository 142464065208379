// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useSelector } from 'react-redux'
import { createFragmentContainer, graphql } from 'react-relay'
import { startCase } from 'lodash'

import { UserTrackableChartControls } from 'components/UserTrackable/UserTrackableChartControls'
import { Box, Container, FlexContainer, PageHeader } from 'care-ui'

import { CurrentResultCard } from './CurrentResultCard'
import { ScoreCardsCareOptions } from './ScoreCardsCareOptions'
import { graphStyle } from './ScoreCardsPage.style'
import { ScoreCardsPageAbout } from './ScoreCardsPageAbout'
import { ScoreCardsPreviousAnswers } from './ScoreCardsPreviousAnswers'

import type { ScoreCardsPage_user_trackable } from './__generated__/ScoreCardsPage_user_trackable.graphql'
import type { ScoreCardsPage_viewer } from './__generated__/ScoreCardsPage_viewer.graphql'

type PropsType = {
  componentId?: string,
  +user_trackable: ScoreCardsPage_user_trackable,
  +viewer: ScoreCardsPage_viewer,
}

const ScoreCardsPageBase = ({
  user_trackable,
  user_trackable: {
    primary_user_questionnaire,
    trackable,
    trackable: { formula: { formula_ranges } },
  },
  viewer,
}: PropsType) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const { css } = useFela()

  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const dashboardRoute = isIndividual ? 'dashboard' : 'user_dashboard'

  if (!primary_user_questionnaire) {
    return ''
  }
  const { user_question_sets } = primary_user_questionnaire
  const broadcastPointData = event => {
    const data = event?.point?.category
    setSelectedAnswer(data)
  }

  const linkTo = {
    name: dashboardRoute,
    params: { id: user_trackable.user.id },
  }

  return (
    <>
      <PageHeader linkTo={linkTo}>{startCase(trackable.label)}</PageHeader>

      <Container>
        <ScoreCardsPageAbout trackable={trackable} />

        <Box
          border="lightBackground"
          boxShadow={1}
          paddingX="md"
          paddingY="md"
          borderRadius="sm"
        >
          <FlexContainer direction="column">
            <FlexContainer direction="row" width="100%">
              <CurrentResultCard user_trackable={user_trackable} />

              <div className={css(graphStyle)}>
                <UserTrackableChartControls
                  broadcastPointData={broadcastPointData}
                  formulaRanges={formula_ranges}
                  user={user_trackable.user}
                />
              </div>
            </FlexContainer>

            <ScoreCardsPreviousAnswers
              broadcastPointData={broadcastPointData}
              selected_answer={selectedAnswer}
              user_question_sets={user_question_sets}
              user_trackable={user_trackable}
            />
          </FlexContainer>
        </Box>

        <ScoreCardsCareOptions
          user_trackable={user_trackable}
          viewer={viewer}
        />
      </Container>
    </>
  )
}

export const ScoreCardsPage = createFragmentContainer(ScoreCardsPageBase, {
  user_trackable: graphql`
    fragment ScoreCardsPage_user_trackable on UserTrackable {
      ...ScoreCardsCareOptions_user_trackable
      ...CurrentResultCard_user_trackable
      ...ScoreCardsPreviousAnswers_user_trackable
      primary_user_questionnaire {
        id
        user {
          id
        }
        questionnaire {
          label
          id
        }
        user_question_sets {
          for_role_type
          ...ScoreCardsPreviousAnswers_user_question_sets
        }
      }
      user {
        id
        ...UserTrackableChartControls_user
      }
      id
      trackable {
        ...ScoreCardsPageAbout_trackable
        formula {
          formula_ranges {
            ...UserTrackableChartControls_formulaRanges
          }
        }
        label
      }
    }
  `,
  viewer: graphql`
    fragment ScoreCardsPage_viewer on Viewer {
      ...ScoreCardsCareOptions_viewer
      currentUser {
        id
      }
    }
  `,
})
