// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RoleStaffInviteMutationResponse,
  RoleStaffInviteMutationVariables,
} from './__generated__/RoleStaffInviteMutation.graphql'

const mutation = graphql`
  mutation RoleStaffInviteMutation($input: UserInviteInput!) {
    userInvite(input: $input) {
      staff {
        ...StaffEmployeesTableLoader_staff_mutation
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  updateVariables,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: RoleStaffInviteMutationResponse) => void,
  onError?: (result: Object) => void,
  updateVariables?: Function,
  variables: RoleStaffInviteMutationVariables,
}) => {
  const updater = () => {
    if (updateVariables) {
      // This is a workaround code, has to force query once form is submitted
      updateVariables({ random: Math.random() })
    }
  }

  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    updater,
  })
}
