// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjectFieldTemplate__formContainer___lz3xe{margin-bottom:2rem}.ObjectFieldTemplate__questionContainer___Aczxx{margin-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "ObjectFieldTemplate__formContainer___lz3xe",
	"questionContainer": "ObjectFieldTemplate__questionContainer___Aczxx"
};
export default ___CSS_LOADER_EXPORT___;
