// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { addMonths, subMonths } from 'date-fns'
import { useRouter } from 'found'

import { getParams } from 'components/UserTrackable/services'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { Heading } from 'care-ui'

import { formatDataSeries, formatDataSet } from './helpers'
import OffsetButton from './OffsetButton'
import SummaryGraphComponent from './SummaryGraphComponent'
import TrackableDropdown from './TrackableDropdown'

import type { SummaryGraph_user } from './__generated__/SummaryGraph_user.graphql'

type PropsType = {
  offset: number,
  setOffset: number => void,
  user: ?SummaryGraph_user,
}

const containerStyle = ({ theme }) => ({
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '1rem 2rem',
  backgroundColor: 'white',
  flex: 1,
  width: '100%',
  [theme.care.breakpoints.queries.lg]: {
    width: '50%',
  },
})

const headPanelStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '1rem 0',
})

const headingStyle = ({ theme }) => ({
  color: theme.palette.summaryGraphs.textColor,
})

const graphContainerStyle = () => ({
  position: 'relative',
})

const offsetButtonStyle = direction => ({ theme }) => {
  const horizontalPosition = {}

  switch (direction) {
    case 'left':
      horizontalPosition.left = '10px'
      break
    case 'right':
      horizontalPosition.right = '10px'
      break
    default:
      break
  }

  return {
    position: 'absolute',
    zIndex: 10,
    top: '153px',
    ...horizontalPosition,
    [theme.breakpoints.queries.sm]: {
      top: '175px',
    },
    [theme.breakpoints.queries.lg]: {
      top: '282px',
    },
  }
}
export const SummaryGraphBase = ({ user, offset, setOffset }: PropsType) => {
  const [invisible, setInvisible] = useState([])
  const [updatedUser, setUpdatedUser] = useState({})
  const policies = usePolicies()

  useEffect(
    () => {
      if (user) setUpdatedUser(user)
    },
    [user],
  )

  const { summary_data_set } = updatedUser || {}

  const { css } = useFela()

  const { dateRange } = getParams('Biannual', offset)

  const scoresRange = [
    { label: '1', value: 1 },
    { label: '0.5', value: 0.5 },
    { label: '0', value: 0 },
  ]
  const dataSets = summary_data_set ? [...summary_data_set] : []
  const formattedDataSet = formatDataSet(dataSets, invisible)
  const formattedDataSeries = formatDataSeries(formattedDataSet)

  const isClinician = policies.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT

  return (
    <div className={css(containerStyle)}>
      <div className={css(headPanelStyle)}>
        <Heading level={2} extend={headingStyle}>
          Health Snapshot
        </Heading>
        <TrackableDropdown user={user} />
      </div>
      <div
        className={css(graphContainerStyle)}
        data-testid="summaryGraphComponent"
      >
        <div className={css(offsetButtonStyle('left'))}>
          <OffsetButton
            offset={offset}
            setOffset={setOffset}
            direction="left"
          />
        </div>
        <SummaryGraphComponent
          dateRange={dateRange}
          scoresRange={scoresRange}
          series={formattedDataSeries}
          visibilityState={{ invisible, setInvisible }}
          isClinician={isClinician}
        />
        <div className={css(offsetButtonStyle('right'))}>
          <OffsetButton
            offset={offset}
            setOffset={setOffset}
            direction="right"
          />
        </div>
      </div>
    </div>
  )
}

const SummaryGraph = createFragmentContainer(SummaryGraphBase, {
  user: graphql`
    fragment SummaryGraph_user on User {
      ...TrackableDropdown_user
      id
      summary_data_set(start_time: $start_time, end_time: $end_time) {
        trackable {
          id
          label
        }
        answered_by {
          ... on RoleInterface {
            role_type
          }
        }
        points {
          date
          scaled_value
          raw_score
          formula_range {
            boundLower
            boundUpper
            label
          }
        }
      }
    }
  `,
})

const SummaryGraphQuery = graphql`
  query SummaryGraph_Query($start_time: String!, $end_time: String!) {
    viewer {
      currentUser {
        ...SummaryGraph_user
      }
    }
  }
`

const SummaryGraphSpecificQuery = graphql`
  query SummaryGraphSpecific_Query(
    $id: ID!
    $start_time: String!
    $end_time: String!
  ) {
    user(id: $id) {
      ...SummaryGraph_user
    }
  }
`

export const SummaryGraphLoader = () => {
  const environment = useRelayEnvironment()
  const { match: { params } } = useRouter()
  const [offset, setOffset] = useState(0)

  const { dateRange } = getParams('Biannual', offset)
  const { localStartDate, localEndDate } = dateRange

  const query = params?.id ? SummaryGraphSpecificQuery : SummaryGraphQuery

  const startTime = subMonths(localStartDate, 6)
  const endTime = addMonths(localEndDate, 6)

  const initialVariables = {
    id: params?.id,
    start_time: startTime,
    end_time: endTime,
  }

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={initialVariables}
      render={response => {
        const { props } = response || {}
        const dashboardUser = props?.viewer?.currentUser || props?.user || null
        return (
          <SummaryGraph
            user={dashboardUser}
            offset={offset}
            setOffset={setOffset}
          />
        )
      }}
    />
  )
}
