// @flow

import React from 'react'
import { connect } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { map } from 'lodash/fp'

import Heading from 'react-ui/components/Heading'

import { type FelaStylesType } from 'react-ui/typing'
import { type AboutMeTab_user } from './__generated__/AboutMeTab_user.graphql'

type PropsType = {
  styles: FelaStylesType,
  +user: AboutMeTab_user,
}

type DetailType = {
  name: string,
  styles: FelaStylesType,
  value: string,
}

type AvatarType = {
  styles: FelaStylesType,
  +user: AboutMeTab_user,
}

const AvatarSide = ({ user, styles }: AvatarType) => (
  <div className={styles.avatar}>
    <Heading level={3} extend={() => ({ marginTop: 0, paddingLeft: '10px' })}>
      {user.legal_name}
    </Heading>
  </div>
)

const IndividualDetail = ({ name, value, styles }: DetailType) => (
  <div className={styles.row}>
    <dt className={styles.name}>{name}: </dt>
    <dd className={styles.value}>{value}</dd>
  </div>
)

const DetailsSide = ({ user: { profileDisplayFields }, styles }: PropsType) => (
  <div className={styles.details}>
    <dl>
      {map(
        ({ id, label, displayValue }) => (
          <IndividualDetail
            key={id}
            name={label}
            value={displayValue}
            styles={styles}
          />
        ),
        profileDisplayFields,
      )}
    </dl>
  </div>
)

const AboutMeTab = ({ user, styles }: PropsType) => (
  <div className={styles.container}>
    <AvatarSide user={user} styles={styles} />
    <div className={styles.wrap}>
      <DetailsSide user={user} styles={styles} />
    </div>
  </div>
)

const AboutMeTabLoader = createFragmentContainer(AboutMeTab, {
  user: graphql`
    fragment AboutMeTab_user on User {
      ...AvatarLoader_user
      legal_name
      profileDisplayFields {
        id
        label
        displayValue
      }
    }
  `,
})

const styledRules = ({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '80%',
    [theme.breakpoints.queries.xsOnly]: {
      maxWidth: '100%',
    },
  },
  avatar: {
    display: 'flex',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.queries.xsOnly]: {
      flexDirection: 'column',
    },
  },
  row: {
    display: 'flex',
    marginBottom: '10px',
    [theme.breakpoints.queries.xsOnly]: {
      flexDirection: 'column',
    },
  },
  details: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  name: {
    margin: 0,
    textAlign: 'right',
    display: 'inline',
    fontWeight: 'bold',
    width: '20%',
    [theme.breakpoints.queries.xsOnly]: {
      textAlign: 'left',
      width: '100%',
    },
  },
  value: {
    margin: 0,
    display: 'inline',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '80%',
    [theme.breakpoints.queries.xsOnly]: {
      paddingLeft: '0',
    },
  },
})

export default connect(styledRules)(AboutMeTabLoader)
