// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

import NavLink from 'components/Settings/NavLink'
import WithAuth from 'components/WithAuth'
import { Column, Row } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import { Container, Heading } from 'care-ui'

type PropsType = {
  +children: Node,
}

const listStyle = () => ({
  padding: 0,
  listStyle: 'none',
  position: 'relative',
})

const mainContentStyle = ({ theme: { breakpoints } }) => ({
  padding: 0,
  maxWidth: '100%',
  [breakpoints.queries.md]: {
    paddingLeft: '4rem',
  },
  [breakpoints.queries.xs]: {
    padding: 0,
  },
})

const sectionOuterStyle = () => ({
  flex: 1,
})

const sectionStyle = ({ theme: { palette, breakpoints } }) => ({
  height: '100%',
  [breakpoints.queries.md]: {
    // Mimic the white left column + grey right column on wider screens
    backgroundImage: `linear-gradient(to right, ${
      palette.input.default.base
    } 30%, transparent 0)`,
    borderBottom: `1px solid ${palette.dividerColor}`,
  },
})

const wrapperStyle = () => ({
  minHeight: '100vh',
})

const rowStyle = () => ({
  minHeight: '100vh',
  marginLeft: 0,
  marginRight: 0,
})

const navStyle = ({ theme: { palette, breakpoints } }) => ({
  backgroundColor: palette.input.default.base,
  boxShadow: `0 0.05rem 0.1rem ${palette.input.label.accent}`,
  paddingTop: '2rem',
  height: 'auto',
  paddingLeft: 0,

  [breakpoints.queries.md]: {
    boxShadow: `0.1rem -0.05rem 0.1rem ${palette.input.label.accent}`,
  },
})

const mainStyle = ({ theme: { breakpoints } }) => ({
  [breakpoints.queries.xs]: {
    padding: '0 8px',
  },
})

const UserSettingsPage = (props: PropsType) => {
  const { children } = props

  const { css } = useFela()

  const sideNav = (
    <div>
      <Heading level={4}>Manage your settings</Heading>
      <ul className={css(listStyle)}>
        <li>
          <NavLink
            to={{ name: 'settings_personal_details' }}
            locationCheck={['/settings/personal_details', '/settings/user']}
          >
            Profile
          </NavLink>
        </li>
      </ul>
      <ul className={css(listStyle)}>
        <li>
          <NavLink
            to="update-password"
            locationCheck={['/settings/update-password']}
          >
            Password
          </NavLink>
        </li>
      </ul>
      <WithAuth requiredPolicies={['CAN_VIEW_OWN_HEALTH_HISTORY']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="health-history"
              locationCheck={['/settings/health-history']}
            >
              Health History
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_VIEW_CONNECTED_ORGANIZATIONS_LINK']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to={{ name: 'connected_organisations' }}
              locationCheck={['/settings/connected_organisations']}
            >
              Clinicians and healthcare providers
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth
        requiredPolicies={[
          'CAN_VIEW_INDIVIDUAL_LINKS',
          'CAN_SHOW_RESEARCH_DATA_SHARING_OPTIONS',
        ]}
      >
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="research_data_sharing_settings"
              locationCheck={['/settings/research_data_sharing']}
            >
              Research and data sharing
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_READ_LEGAL_INFO_FOR_INDIVIDUALS']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="notifications_settings"
              locationCheck={['/settings/notifications']}
            >
              SMS notifications
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_CREATE_FEEDBACKS']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink to="contact_us" locationCheck={['/settings/contact-us']}>
              Contact us
            </NavLink>
          </li>
        </ul>
      </WithAuth>
    </div>
  )

  const mainContent = <div className={css(mainContentStyle)}>{children}</div>

  return (
    <Section
      noPadding
      className={css(sectionOuterStyle)}
      extend={{ Section: sectionStyle }}
    >
      <Container extend={wrapperStyle}>
        <Row extend={rowStyle}>
          <Column col={12} md={4} extend={navStyle}>
            {sideNav}
          </Column>
          <Column col={12} md={8} extend={mainStyle}>
            {mainContent}
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default UserSettingsPage
