// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import Markdown from 'react-ui/components/Markdown'
import { BasicTable } from 'shared/ui/Tables'

import type { AnswerTable_answers } from './__generated__/AnswerTable_answers.graphql'

type PropsType = {
  answers: AnswerTable_answers,
}

const displayValueFormatter = value => {
  try {
    const arrayValue = JSON.parse(value)
    return arrayValue.join(', ')
  } catch {
    return value
  }
}

const tableStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const headingStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLgBold,
})

const rowStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const AnswerTableBase = (props: PropsType) => {
  const { answers } = props
  const { css } = useFela()

  return (
    <BasicTable
      className={css(tableStyle)}
      data={answers}
      renderHeaders={() => (
        <tr className={css(headingStyle)}>
          <th colSpan={2}>Question</th>
          <th>Answer</th>
        </tr>
      )}
    >
      {(answer, k) => (
        <tr className={css(rowStyle)} key={answer.id}>
          <td>{k + 1}</td>
          <td data-testid="Question">
            <Markdown source={answer.question.label} />
          </td>
          <td data-testid="Answer">
            <Markdown source={displayValueFormatter(answer.display_value)} />
          </td>
        </tr>
      )}
    </BasicTable>
  )
}

export const AnswerTableLoader = createFragmentContainer(AnswerTableBase, {
  answers: graphql`
    fragment AnswerTable_answers on AnswerInterface @relay(plural: true) {
      id
      display_value
      question {
        label
      }
    }
  `,
})
