// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Icon from 'react-ui/components/Icon/Icon'
import Can from 'react-ui/utils/Can'
import { ViewerEnumsContext } from 'containers/ViewerEnums'
import { Box, Button, FlexContainer, Modal, ScrollableBox, Text } from 'care-ui'
import Support from 'care-ui/atoms/icons/add-person.svg'
import useModal from 'care-ui/molecules/hooks/useModal'

import AddSupportPersonForm from '../SupportPersonModal/AddSupportPersonForm'
import ViewSupportPersonInfo from '../SupportPersonModal/ViewSupportPersonInfo'

import type { SupportTaskButton_individual } from './__generated__/SupportTaskButton_individual.graphql'

type PropsType = {
  individual: SupportTaskButton_individual,
}

const SupportTaskButton = ({ individual }: PropsType) => {
  const { isOpen, closeModal, openModal } = useModal()

  const {
    individual_detail,
    support_person_assignments: supportPersonAssignments,
  } =
    individual || {}

  return (
    <Can>
      {({
        CAN_CREATE_SUPPORT_PERSON_ASSIGNMENTS: canCreate,
        CAN_USE_SUPPORT_PERSON: featureToggle,
      }) => {
        const isReadOnlyIndividual = Boolean(
          individual_detail && individual_detail.read_only,
        )

        if (!featureToggle || isReadOnlyIndividual || !canCreate) return null

        return (
          <>
            <Box marginX="xs">
              <Button
                onClick={openModal}
                variant="secondary"
                dataTestId="supportTaskButton"
                ariaLabel="add support person"
                tooltipText="Add Support Person"
              >
                <Icon as={Support} scale={0.8} />
              </Button>
            </Box>

            <Modal
              heading="Add Support Person"
              closeModal={closeModal}
              isOpen={isOpen}
              dataTestId="add_support_person_modal"
            >
              <FlexContainer direction="column" gap="sm">
                <Text as="p">
                  Different perspectives can help add more information to your
                  health profile. They will receive an email invite to answer a
                  questionnaire about your health and well being.
                </Text>

                <Text as="p" bold>
                  They will not be able to view any of your health information.
                  If you remove a support person they won’t be notified.
                </Text>

                <ViewerEnumsContext.Consumer>
                  {({ relationships }) => (
                    <Box marginY="xs">
                      <AddSupportPersonForm relationships={relationships} />
                    </Box>
                  )}
                </ViewerEnumsContext.Consumer>
              </FlexContainer>

              {supportPersonAssignments && (
                <ScrollableBox maxHeight="30vh" maxWidth="100%">
                  {supportPersonAssignments?.map(assignment => (
                    <ViewSupportPersonInfo
                      key={assignment.id}
                      supportPersonAssignment={assignment}
                      extend={{
                        hr: {
                          borderColor: 'black',
                          margin: '18px 18px 36px 18px',
                        },
                      }}
                    />
                  ))}
                </ScrollableBox>
              )}
            </Modal>
          </>
        )
      }}
    </Can>
  )
}

export default createFragmentContainer(SupportTaskButton, {
  individual: graphql`
    fragment SupportTaskButton_individual on IndividualRole {
      has_support_person_attached_to_individual
      individual_detail {
        read_only
      }
      support_person_assignments {
        id
        nickname
        relationship
        support_person {
          email
        }
        last_requested_at
        accepted_invitation
        last_completed
      }
    }
  `,
})
