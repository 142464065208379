// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button } from 'care-ui'

type PropsType = {
  answereeUserId: string,
  assessmentRequestId?: string,
  label: string,
  questionnaireId: string,
  ready?: boolean,
  submitted?: boolean,
  supporteeName: ?string,
}

const containerStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '45rem',
  margin: '0 auto 1.5rem',
  '& > span:first-child': {
    width: '20%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  ...theme.care.typography.desktop.bodyLg,
})

const SupportingSupporteeQuestionnaire = ({
  supporteeName,
  label,
  answereeUserId,
  questionnaireId,
  assessmentRequestId,
  ready,
  submitted,
}: PropsType) => {
  const { css } = useFela()
  const { start } = useQuestionnaireSession({
    answererRoleType: 'SUPPORT_PERSON',
    answereeUserId,
    questionnaireId,
    assessmentRequestId,
  })

  let buttonLabel = 'Start questionnaire'

  if (submitted) {
    buttonLabel = 'Submitted'
  } else if (ready) {
    buttonLabel = 'Ready to submit'
  }

  return (
    <div className={css(containerStyle)}>
      <span>{supporteeName}</span>
      <span>{label}</span>
      <Button
        dataTestId="start-questionnaire-button"
        onClick={start}
        disabled={ready || submitted}
        variant="primary"
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default SupportingSupporteeQuestionnaire
