// @flow

import React from 'react'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import { last } from 'lodash/fp'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs/StaffNavigationTabs'
import { Container } from 'care-ui'

import { StaffInviteLoader } from '../StaffInvite'

import StaffEmployeesTableLoader from './StaffEmployeesTableLoader'

import type { FelaStylesType } from 'react-ui/typing'
import { type StaffEmployeesPage_staff_admin } from './__generated__/StaffEmployeesPage_staff_admin.graphql'

type PropsType = {
  queryType: 'employee' | 'manager',
  staff_admin: StaffEmployeesPage_staff_admin,
  styles: FelaStylesType,
}

const StaffEmployeesPage = ({ queryType, staff_admin }: PropsType) => {
  const inviteButton = (
    <StaffInviteLoader staff_admin={staff_admin} queryType="employee" />
  )

  return (
    <Container>
      <nav>
        <StaffNavigationTabs inviteButton={inviteButton} />
      </nav>

      <main>
        <StaffEmployeesTableLoader
          staff_admin={staff_admin}
          queryType={queryType}
        />
      </main>
    </Container>
  )
}

const StaffEmployeesPageQuery = graphql`
  query StaffEmployeesPage_Query($query_type: String!) {
    viewer {
      currentUser {
        staff_admin {
          ...StaffEmployeesPage_staff_admin
        }
      }
    }
  }
`

const PageLoader = createFragmentContainer(StaffEmployeesPage, {
  staff_admin: graphql`
    fragment StaffEmployeesPage_staff_admin on StaffAdmin {
      ...StaffInvite_staff_admin
    }
  `,
})

const StaffEmployeesPageLoader = () => {
  const currentUser = useCurrentUser()
  const environment = useRelayEnvironment()
  const { match: { routes } } = useRouter()

  const routeName = last(routes).name

  const query_type = routeName === 'staff_managers' ? 'manager' : 'employee'

  if (currentUser.completed_onboarding) {
    return (
      <QueryRenderer
        environment={environment}
        query={StaffEmployeesPageQuery}
        variables={{ query_type }}
        render={response => {
          return (
            <QueryRendererLoadingIndicator response={response}>
              {props => {
                const staffAdmin = props.viewer.currentUser.staff_admin
                return (
                  <PageLoader
                    {...(props: any)}
                    staff_admin={staffAdmin}
                    queryType={query_type}
                  />
                )
              }}
            </QueryRendererLoadingIndicator>
          )
        }}
      />
    )
  }

  return null
}

export const StaffEmployeesPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy([
    'CAN_LIST_TENANT_EMPLOYEES',
    'CAN_LIST_TENANT_MANAGERS',
  ])

  if (isLoading) return null

  return isAuthorized ? <StaffEmployeesPageLoader /> : <UnauthorizedPage />
}
