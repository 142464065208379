// @flow

import { type ThemeType } from 'care-ui/atoms'

type ScrollableBoxContainerProps = {
  direction?: 'horizontal' | 'vertical',
  hasScrollbar?: boolean,
  maxHeight?: string,
  maxWidth?: string,
  theme: ThemeType,
}

export const scrollableBoxContainer = (props: ScrollableBoxContainerProps) => {
  const {
    theme,
    maxHeight,
    maxWidth,
    direction = 'vertical',
    hasScrollbar,
  } = props

  if (direction === 'vertical') {
    return {
      maxHeight,
      paddingRight: hasScrollbar ? theme.care.spacing.xs : '',
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'auto',
      '&::-webkit-scrollbar': {
        width: theme.care.spacing.xxxs,
        height: theme.care.spacing.xxxs,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.care.palette.surface.accentLighter,
        borderRadius: theme.care.radius.pill,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.care.palette.surface.default,
        borderRadius: theme.care.radius.pill,
        border: `1px solid ${theme.care.palette.border.subtle}`,
      },
    }
  }

  return {
    maxWidth,
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: theme.care.spacing.xxxs,
      height: theme.care.spacing.xxxs,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.care.palette.surface.accentLighter,
      borderRadius: theme.care.radius.pill,
    },
  }
}
