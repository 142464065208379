// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import useRouter from 'found/useRouter'

import type { UserQuestionnaireQuestionSetFooter_answer_set } from 'components/UserQuestionnairesQuestionSet/__generated__/UserQuestionnaireQuestionSetFooter_answer_set.graphql'
import { UserQuestionnairesQuestionSetLoader } from 'components/UserQuestionnairesQuestionSet/UserQuestionnairesQuestionSet'
import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'

import type { UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set } from './__generated__/UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set.graphql'

type PropsType = {
  answer_set: UserQuestionnaireQuestionSetFooter_answer_set,
  homepage_path: string,
  +relay: Object,
  +user_questionnaires_question_set: UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set,
  viewer: Object,
}

export const UserQuestionnairesQuestionSetAnswerPage = (props: PropsType) => {
  const {
    user_questionnaires_question_set,
    viewer,
    viewer: { currentUser: { homepage_path } },
    answer_set,
  } = props
  const { router } = useRouter()

  const { hasClinicianRole } = useCurrentUserRoles()
  const roleType = hasClinicianRole
    ? 'CLINICIAN'
    : viewer.currentUser.current_role.roleType

  if (!user_questionnaires_question_set) {
    router.replace(homepage_path)
    return null
  }

  return (
    <>
      <UserQuestionnairesQuestionSetLoader
        user_questionnaires_question_set={user_questionnaires_question_set}
        role={viewer.currentUser.current_role}
        roleType={roleType}
        homepage_path={homepage_path}
        answer_set={answer_set}
      />
    </>
  )
}

export const UserQuestionnairesQuestionSetAnswerPageQuery = graphql`
  query UserQuestionnairesQuestionSetAnswerPage_Query(
    $questionnaires_question_set_id: ID!
    $assessment_request_id: ID
    $answeree_id: ID
    $questionnaire_id: ID
  ) {
    viewer {
      currentUser {
        user_questionnaires_question_set_for_clinician(
          questionnaires_question_set_id: $questionnaires_question_set_id
          answeree_id: $answeree_id
        ) {
          ...UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set
        }
        homepage_path
        current_role {
          ... on IndividualRole {
            id
            role_type
            user_questionnaires_question_set(
              questionnaires_question_set_id: $questionnaires_question_set_id
              assessment_request_id: $assessment_request_id
              answeree_id: $answeree_id
              questionnaire_id: $questionnaire_id
            ) {
              ...UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set
            }
          }
          ... on SupportPersonRole {
            id
            role_type
            user_questionnaires_question_set(
              questionnaires_question_set_id: $questionnaires_question_set_id
              answeree_id: $answeree_id
            ) {
              ...UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set
            }
          }
          ... on ClinicianRole {
            id
            role_type
            user_questionnaires_question_set(
              questionnaires_question_set_id: $questionnaires_question_set_id
              answeree_id: $answeree_id
            ) {
              ...UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set
            }
          }
        }
      }
      enums {
        answer_set {
          ...UserQuestionnairesQuestionSet_answer_set
        }
      }
    }
  }
`

export const UserQuestionnairesQuestionSetAnswerPageLoader = createFragmentContainer(
  UserQuestionnairesQuestionSetAnswerPage,
  {
    user_questionnaires_question_set: graphql`
      fragment UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set on UserQuestionnairesQuestionSet {
        ...UserQuestionnairesQuestionSet_user_questionnaires_question_set
        id
      }
    `,
  },
)
