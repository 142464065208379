// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import { usePolicies } from 'react-ui/hooks/usePolicies'
import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button } from 'care-ui'

import {
  buttonWrapperStyle,
  currentResultCardStyle,
} from './CurrentResultCard.style'
import { CurrentResultCardFormula } from './CurrentResultCardFormula'

import { type CurrentResultCard_user_trackable } from './__generated__/CurrentResultCard_user_trackable.graphql'

type PropsType = {
  user_trackable: CurrentResultCard_user_trackable,
}

const CurrentResultCardComponent = ({
  user_trackable,
  user_trackable: { primary_user_questionnaire, suggested_user_resources },
}: PropsType) => {
  const policies = usePolicies()
  const { css } = useFela()

  const { ensure_questionnaire: { id: questionnaireId } } =
    primary_user_questionnaire || {}

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  if (!primary_user_questionnaire) {
    return false
  }
  const careOptionSupported = suggested_user_resources?.some(
    ({ status }) => status === 'supported',
  )
  const showNotifications =
    user_trackable.escalated &&
    user_trackable.assigned_to_me &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  return (
    <div
      className={css(currentResultCardStyle)}
      data-testid="CurrentResultCard"
    >
      <CurrentResultCardFormula
        careOptionSupported={careOptionSupported}
        user_trackable={user_trackable}
      />

      {!showNotifications && (
        <Can>
          {({ CAN_CREATE_USER_QUESTIONNAIRE: canAnswerQuestions }) => (
            <div className={css(buttonWrapperStyle)}>
              <Button
                variant="primary"
                dataTestId="trackable-questions"
                disabled={
                  !canAnswerQuestions || !user_trackable.matches_user_age
                }
                onClick={start}
                ariaLabel="Retake questionnaire"
              >
                Retake questionnaire
              </Button>
            </div>
          )}
        </Can>
      )}
    </div>
  )
}

export const CurrentResultCard = createFragmentContainer(
  CurrentResultCardComponent,
  {
    user_trackable: graphql`
      fragment CurrentResultCard_user_trackable on UserTrackable {
        escalated
        matches_user_age
        assigned_to_me
        suggested_user_resources {
          ...UserResource_user_resource
          status
          id
          resource {
            clinical
          }
        }
        ...CurrentResultCardFormula_user_trackable
        primary_user_questionnaire {
          id
          user {
            id
          }
          questionnaire {
            label
            id
          }
          ensure_questionnaire {
            label
            id
          }
          user_question_sets {
            for_role_type
            ...UserQuestionSetTable_user_question_sets
          }
        }
      }
    `,
  },
)
