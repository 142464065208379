// @flow

import React from 'react'
import cx from 'classnames'

import { LegalLinks } from 'react-ui/components/Legals'

import s from './PublicFooter.scss'

type PropsType = {
  align?: 'left' | 'right',
  relative?: boolean,
  white?: boolean,
}

const PublicFooter = ({
  align = 'left',
  relative = false,
  white,
}: PropsType) => (
  <footer
    className={cx(s.footer, {
      [s[align]]: align,
      [s.white]: white,
      [s.relative]: relative,
    })}
  >
    <LegalLinks />
  </footer>
)
export default PublicFooter
