// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { useSelector } from 'react-redux'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import ExtensiveCardsSection from 'components/Dashboard/ExtensiveCardsSection'
import { SummaryGraphLoader } from 'components/SummaryGraph'
import { UserTrackableListLoader } from 'components/UserTrackableList'
import FitbitConnectionCard from 'react-ui/components/Fitbit'
import Section from 'react-ui/components/Section'
import useChartToggle from 'react-ui/hooks/useChartToggle'
import { useFeatureToggle } from 'react-ui/hooks/useFeatureToggle'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { ActivityPanel } from 'platform_web/features/ActivityPanel'
import { HealthCardContainer } from 'platform_web/features/HealthCard'
import { Container } from 'care-ui'

import { type HealthCardsTab_user } from './__generated__/HealthCardsTab_user.graphql'

export type PropsType = {
  +user: HealthCardsTab_user,
}

const graphContainer = () => ({
  paddingBottom: '2rem',
})

const trackablesContainer = () => ({
  paddingTop: '2rem',
})

const sectionStyle = ({ theme }) => ({
  Section: {
    backgroundColor: theme.palette.summaryGraphs.highlightColor,
  },
})

const summaryContainer = ({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  [theme.care.breakpoints.queries.lg]: {
    flexDirection: 'row',
  },
})

const notifStyle = ({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.care.breakpoints.queries.lg]: {
    width: '25%',
  },
})

const HealthCardsTab = ({
  user,
  user: { fitbit, id, summaryUserTrackables, linked_individual },
}: PropsType) => {
  const environment = useRelayEnvironment()
  const isReadOnlyIndividual = linked_individual?.individual_detail?.read_only
  const policies = usePolicies()
  const simpleCards = <ExtensiveCardsSection userId={id} />
  const isClinician = policies.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT
  const viewOnlyMode =
    isClinician || (isReadOnlyIndividual && !fitbit.connected)
  const { isSummaryGauge, isSummaryGraph } = useChartToggle()
  const [isIndividual, currentUserId] = useSelector(
    ({ currentUser: { roleType, userId } }) => [
      roleType === 'INDIVIDUAL',
      userId,
    ],
  )
  const { css } = useFela()
  const { ACTIVITY_LOG: showActivityLog } = useFeatureToggle()

  const assigned = linked_individual?.duty_of_care_clinicians?.some(
    clinician => clinician.user.id === currentUserId,
  )

  const showActivityPanel = showActivityLog && isClinician
  const summaryGraphsRole = isIndividual || isClinician

  const renderGraph = () => {
    if (!isSummaryGauge && !isSummaryGraph) return null

    if (isSummaryGraph && summaryGraphsRole) {
      return (
        <div className={css(summaryContainer)}>
          <SummaryGraphLoader />
          {showActivityPanel && (
            <div className={css(notifStyle)}>
              <ActivityPanel userId={id} assigned={assigned} />
            </div>
          )}
        </div>
      )
    }

    return (
      <UserTrackableListLoader
        viewOnly={!policies.CAN_UPDATE_USER_TRACKABLES}
        componentId="UserTrackableList-gauge"
        showUtility
      />
    )
  }

  if (summaryUserTrackables.totalCount > 0) {
    return (
      <>
        <Section
          extend={isSummaryGraph && sectionStyle}
          color="white"
          noPadding
        >
          <Container extend={graphContainer}>{renderGraph()}</Container>
        </Section>
        <Section color="white" noPadding>
          <Container
            extend={() => ({ ...graphContainer(), ...trackablesContainer() })}
          >
            <HealthCardContainer user={user} />
            {!isClinician &&
            linked_individual &&
            (fitbit.connected || policies.CAN_USE_FITBIT) ? (
              <FitbitConnectionCard
                isConnected={fitbit.connected}
                authLink={fitbit.connectionUrl}
                environment={environment}
                viewOnlyMode={viewOnlyMode}
              />
            ) : null}
          </Container>
        </Section>
      </>
    )
  }

  return simpleCards
}

const HealthCardsTabLoader = createFragmentContainer(HealthCardsTab, {
  user: graphql`
    fragment HealthCardsTab_user on User {
      id
      ...HealthCardContainer_user
      summaryUserTrackables: user_trackables(kinds: [gauge]) {
        totalCount
      }
      fitbit {
        connected
        connectionUrl
      }
      linked_individual {
        duty_of_care_clinicians {
          user {
            id
          }
        }
        individual_detail {
          read_only
        }
      }
    }
  `,
})

export default HealthCardsTabLoader
