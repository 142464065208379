// @flow

import React, { type Node, useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { addMonths, subMonths } from 'date-fns'
import useRouter from 'found/useRouter'

import OffsetButton from 'components/SummaryGraph/OffsetButton'
import type { DateTimeAxisType } from 'react-ui/components/Charts/HighChartOptions/defaultOptions'
import { ScoreCardChartContextProvider } from 'react-ui/contexts/ScoreCardChartContext'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { Box } from 'care-ui'

import { getParams } from './services'
import UserTrackableChart from './UserTrackableChart'

import type { GraphWithFilters_user } from './__generated__/GraphWithFilters_user.graphql'

type BasePropsType = {|
  broadcastPointData?: Function,
  children: Node,
  displayActivity?: boolean,
  displaySleep?: boolean,
  formulaRanges: any,
|}

type PropsType = BasePropsType & {
  dateRange: DateTimeAxisType,
  offset: number,
  setOffset: number => void,
  user: ?GraphWithFilters_user,
}

const graphContainerStyle = () => ({
  position: 'relative',
})

const offsetButtonStyle = direction => ({ theme }) => {
  const horizontalPosition = {}

  switch (direction) {
    case 'left':
      horizontalPosition.right = '97%'
      break
    case 'right':
      horizontalPosition.right = '0px'
      break
    default:
      break
  }

  return {
    position: 'absolute',
    zIndex: 10,
    top: '153px',
    ...horizontalPosition,
    [theme.breakpoints.queries.sm]: {
      top: '175px',
    },
    [theme.breakpoints.queries.lg]: {
      top: '321px',
    },
  }
}

const GraphWithFiltersBase = ({
  user,
  offset,
  setOffset,
  children,
  ...props
}: PropsType) => {
  const [updatedUser, setUpdatedUser] = useState()

  useEffect(
    () => {
      if (user) setUpdatedUser(user)
    },
    [user],
  )

  const { css } = useFela()

  return (
    <>
      <Box width="100%">
        <div className={css(graphContainerStyle)}>
          <div className={css(offsetButtonStyle('left'))}>
            <OffsetButton
              offset={offset}
              setOffset={setOffset}
              direction="left"
            />
          </div>
          {updatedUser && (
            <ScoreCardChartContextProvider user={updatedUser} {...(props: any)}>
              <UserTrackableChart />
            </ScoreCardChartContextProvider>
          )}

          <div className={css(offsetButtonStyle('right'))}>
            <OffsetButton
              offset={offset}
              setOffset={setOffset}
              direction="right"
            />
          </div>
        </div>
      </Box>
      <div>{children}</div>
    </>
  )
}

const query = graphql`
  query GraphWithFiltersQuery(
    $id: ID!
    $trackable_id: ID!
    $trackable_start_time: String!
    $trackable_end_time: String!
    $fitbit_start_date: DateTime!
    $fitbit_end_date: DateTime!
    $is_clinician: Boolean!
  ) {
    user(id: $id) {
      ...GraphWithFilters_user
    }
  }
`

const GraphWithFilters = createFragmentContainer(GraphWithFiltersBase, {
  user: graphql`
    fragment GraphWithFilters_user on User {
      ...ScoreCardChartContext_user
    }
  `,
})

const GraphWithFiltersLoader = (baseProps: BasePropsType) => {
  const environment = useRelayEnvironment()
  const { match: { params } } = useRouter()
  const [offset, setOffset] = useState(1)
  const policies = usePolicies()

  const clinicianView = policies?.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT

  const { dateRange } = getParams('Biannual', offset)
  const { localStartDate, localEndDate } = dateRange

  const trackableStartDate = subMonths(localStartDate, 6)
  const trackableEndDate = addMonths(localEndDate, 6)

  const initialVariables = {
    ...params,
    trackable_start_time: trackableStartDate,
    trackable_end_time: trackableEndDate,
    fitbit_start_date: localStartDate,
    fitbit_end_date: localEndDate,
    is_clinician: clinicianView,
  }

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={initialVariables}
      render={response => {
        const { props: queryProps } = response || {}
        const scoreCardUser = queryProps?.user || null

        return (
          <GraphWithFilters
            user={scoreCardUser}
            offset={offset}
            setOffset={setOffset}
            dateRange={dateRange}
            {...(baseProps: BasePropsType)}
          />
        )
      }}
    />
  )
}

export default GraphWithFiltersLoader
