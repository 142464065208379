// @flow

import * as React from 'react'
import Route from 'found/Route'

import {
  QuestionnaireShowPageLoader,
  SpecificQuestionnaireShowPageQuery,
} from 'pages/Questionnaire/QuestionnaireShowPage'
import { RootUsersPageLoader, RootUsersPageQuery } from 'pages/RootUsersPage'
import { ScoreCardsPageComponentLoader } from 'pages/ScoreCards/ScoreCardsPageComponent'
import { UserDashboardPageQueryLoader } from 'pages/UserDashboardPage'
import {
  UserQuestionnaireDetailsPageLoader,
  UserQuestionnaireDetailsPageQuery,
} from 'pages/UserQuestionnaire/UserQuestionnaireDetailsPage'
import {
  UserQuestionnairesQuestionSetAnswerPageLoader,
  UserQuestionnairesQuestionSetAnswerPageQuery,
} from 'pages/UserQuestionnairesQuestionSet/UserQuestionnairesQuestionSetAnswerPage'
import {
  UserQuestionnairesQuestionSetDetailsPageLoader,
  UserQuestionnairesQuestionSetDetailsPageQuery,
} from 'pages/UserQuestionnairesQuestionSet/UserQuestionnairesQuestionSetDetailsPage'
import {
  UserQuestionSetDetailsPageLoader,
  UserQuestionSetDetailsPageQuery,
} from 'pages/UserQuestionSet/UserQuestionSetDetailsPage'
import { loadingRender } from 'services/routeConfig/services'
import { UserAssessmentRequestsPage } from 'platform_web/pages/AssessmentRequests'

const routes = (
  <Route>
    <Route
      Component={RootUsersPageLoader}
      name="clinician_view_user"
      path="/:id"
      query={RootUsersPageQuery}
    >
      <Route
        Component={props => (
          <UserDashboardPageQueryLoader {...props} selectedTab="trackables" />
        )}
        path="/dashboard"
        name="user_dashboard"
      />

      <Route
        Component={props => (
          <UserDashboardPageQueryLoader
            {...props}
            selectedTab="healthHistory"
          />
        )}
        path="/health_history"
        name="user_health_history"
      />

      <Route
        Component={props => (
          <UserDashboardPageQueryLoader {...props} selectedTab="aboutMe" />
        )}
        path="/profile"
        name="user_profile"
      />

      <Route
        Component={UserAssessmentRequestsPage}
        name="user_assessment_requests"
        path="/assessment_requests"
      />

      <Route path="/questionnaires">
        <Route path="/:questionnaire_id">
          <Route
            Component={QuestionnaireShowPageLoader}
            name="specificQuestionnaireShow"
            path="/"
            query={SpecificQuestionnaireShowPageQuery}
            render={loadingRender(
              { message: 'Questionnaire specific show' },
              ({ Component, props }) => (
                <Component
                  {...(props: any)}
                  user={props.user}
                  user_questionnaire={props.user.user_questionnaire}
                  role={props.viewer.currentUser.current_role}
                />
              ),
            )}
          />
          <Route
            name="user_questionnaire_details"
            path="/answers"
            Component={UserQuestionnaireDetailsPageLoader}
            query={UserQuestionnaireDetailsPageQuery}
            render={loadingRender(
              { message: "User's Questionnaire" },
              ({ Component, props }) => (
                <Component
                  {...(props: any)}
                  user_questionnaire={props.user.user_questionnaire}
                />
              ),
            )}
          />
        </Route>
      </Route>
      <Route path="/questionnaires_question_sets">
        <Route
          name="user_questionnaires_question_set_details"
          path="/:questionnaires_question_set_id"
          Component={UserQuestionnairesQuestionSetDetailsPageLoader}
          query={UserQuestionnairesQuestionSetDetailsPageQuery}
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user_questionnaires_question_set={
                  props.user.user_questionnaires_question_set
                }
              />
            ),
          )}
        />

        <Route
          name="user_questionnaires_question_set_answer_by_clinician"
          path="/:questionnaires_question_set_id/answeree/:answeree_id?"
          Component={UserQuestionnairesQuestionSetAnswerPageLoader}
          query={UserQuestionnairesQuestionSetAnswerPageQuery}
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => {
              return (
                <Component
                  {...(props: any)}
                  user_questionnaires_question_set={
                    props.viewer.currentUser
                      .user_questionnaires_question_set_for_clinician
                  }
                  answer_set={props.viewer.enums.answer_set}
                />
              )
            },
          )}
        />
      </Route>
      <Route
        name="user_question_set_details"
        path="/question_sets/:question_set_id"
        Component={UserQuestionSetDetailsPageLoader}
        query={UserQuestionSetDetailsPageQuery}
        render={loadingRender(
          { message: "User's Questionnaires Question Set" },
          ({ Component, props }) => (
            <Component
              {...(props: any)}
              user_question_set={props.user.user_question_set}
              user={props.user}
            />
          ),
        )}
      />
      <Route path="/score_cards">
        <Route
          name="user_score_card"
          path="/:trackable_id"
          Component={ScoreCardsPageComponentLoader}
        />
      </Route>
    </Route>
  </Route>
)

export default routes
