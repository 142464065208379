// @flow

import React, { Fragment } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AnswerTableLoader } from 'components/AnswerTable'
import { shortDate } from 'services/dateTime'

import type { AnswerSet_answer_set } from './__generated__/AnswerSet_answer_set.graphql'

export type PropsType = {
  answer_set: AnswerSet_answer_set,
}

export const AnswerSet = (props: PropsType) => {
  const { answer_set: { answers, submitted_at } } = props

  const submittedAtDate = submitted_at
    ? format(shortDate)(new Date(submitted_at))
    : 'Not Submitted'

  return (
    <Fragment>
      <div>Submitted at: {submittedAtDate}</div>
      <AnswerTableLoader answers={answers} />
    </Fragment>
  )
}

export const AnswerSetLoader = createFragmentContainer(AnswerSet, {
  answer_set: graphql`
    fragment AnswerSet_answer_set on AnswerSet {
      answers {
        ...AnswerTable_answers
      }
      id
      submitted_at
    }
  `,
})
