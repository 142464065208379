// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import omit from 'lodash/fp/omit'

import ArrowDown from 'react-ui/assets/icons/minor-arrow-down.svg'
import Icon from 'react-ui/components/Icon'
import { felaProps } from 'shared/services/fela'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  +alwaysShowEmpty?: boolean,
  +items: $ReadOnlyArray<{ [name: ?string]: string } | string>,
  +itemsType?: string,
  +keyName?: string,
  +nullLabel?: string,
  +styles: FelaStylesType,
  +value: string,
  +valueName?: string,
}

const styleRules = ({ theme: { Form, care } }) => ({
  Select: {
    ...Form,
    fontWeight: 500,
    borderRadius: '5px',
    appearance: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0 32px 0 10px',
    ...care.typography.desktop.bodyLg,
  },
  selectContainer: {
    position: 'relative',
  },
})

const arrowStyle = () => ({
  position: 'absolute',
  top: '16px',
  right: '10px',
  pointerEvents: 'none',
})

const Select = (props: PropsType) => {
  const {
    alwaysShowEmpty = false,
    items = [],
    itemsType = 'items',
    keyName,
    nullLabel = '-- Please Select --',
    value,
    valueName,
    styles,
    ...other
  } = props
  if (items.length === 0) {
    return <span>No {itemsType} available</span>
  }

  const objectOption = item => (
    <option key={item[keyName]} value={item[keyName]}>
      {item[valueName]}
    </option>
  )

  const stringOption = item => (
    <option key={item} value={item}>
      {item}
    </option>
  )

  const renderedOption = item =>
    typeof item === 'object' ? objectOption(item) : stringOption(item)

  const renderedOptions = items.map(item => renderedOption(item))

  const renderedPleaseSelect = <option value="">{nullLabel}</option>

  return (
    <div className={styles.selectContainer}>
      <select
        {...omit(['block', 'error', ...felaProps], other)}
        value={value}
        className={styles.Select}
      >
        {!value || alwaysShowEmpty ? renderedPleaseSelect : null}
        {renderedOptions}
      </select>
      <span>
        <Icon as={ArrowDown} scale={0.4} extend={arrowStyle} />
      </span>
    </div>
  )
}

export default connect(styleRules)(Select)
