// @flow

import React from 'react'
import { connect } from 'react-fela'
import compose from 'lodash/fp/compose'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import {
  trackFitbitConnected,
  trackFitbitNotInterested,
} from 'services/analytics/events'
import {
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from 'services/commonExternalUrls'
import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink: string,
  closeModal?: boolean,
}

const styleRules = () => ({
  container: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '10rem',
    margin: 'auto',
    height: 'auto',
  },
  list: {
    margin: '20px 30px',
    '>li': {
      padding: '5px 0 ',
    },
  },
  note: {
    fontSize: '13px',
    textAlign: 'center',
    margin: '20px 0',
    padding: '0 15%',
    lineHeight: '20px',
    '>a': {
      fontSize: 'inherit',
    },
  },
  buttonGroup: {
    marginTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '>a': {
      margin: '10px',
    },
    '>button': {
      margin: '10px',
    },
  },
})

const FitbitConnectActivity = ({
  closeModal,
  styles,
  rules,
  authLink,
}: PropTypes) => (
  <Container extend={rules.container}>
    <FitbitLogo className={styles.logo} />
    <Heading level={2} extend={() => ({ textAlign: 'center' })}>
      Benefits of connecting Fitbit to the Innowell Platform
    </Heading>

    <ul className={styles.list}>
      <li>Track your activity and sleep progress.</li>
      <li>View your Fitbit data in the context of your health and wellbeing</li>
      <li>Get real-time updates via your dashboard</li>
    </ul>

    <span className={styles.note}>
      You can always disconnect / re-connect at any time. Here is more
      information on Innowell’s{' '}
      <Link to={privacyPolicyUrl()}>privacy policy</Link> and{' '}
      <Link to={termsAndConditionsUrl()}>terms and conditions </Link>.
    </span>

    <div className={styles.buttonGroup}>
      <Button
        dataTestId="fitbit-no"
        variant="secondary"
        onClick={compose(closeModal, trackFitbitNotInterested)}
      >
        No, not interested
      </Button>

      <Button
        dataTestId="fitbit-yes"
        variant="primary"
        onClick={trackFitbitConnected}
        href={authLink}
        target="_blank"
      >
        Yes, I want to connect
      </Button>
    </div>
  </Container>
)

export default connect(styleRules)(FitbitConnectActivity)
