// @flow

import React, { type Node, useState } from 'react'
import { connect } from 'react-fela'

import ArrowDown from 'react-ui/assets/icons/triangular-arrow-down.svg'
import ArrowRight from 'react-ui/assets/icons/triangular-arrow-right.svg'
import Icon from 'react-ui/components/Icon'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  content?: Node,
}

const styleRules = ({ theme }) => ({
  extendedRowArrow: {
    position: 'absolute',
    top: '10px',
    left: '0',
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    width: '60px',
    height: '60px',
    border: '0px',
  },
  content: {
    backgroundColor: theme.DataGrid.footer.accent,
    paddingBottom: theme.spacing(0.5),
    minHeight: '80px',
  },
  hr: {
    borderTop: '0',
    backgroundColor: theme.DataGrid.cell.accent,
    height: '2px',
    margin: '0',
  },
})

const DataGridRowFooterRenderer = (props: PropsType) => {
  const { styles, content } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleExtendedRow = () => setIsOpen(!isOpen)

  const renderedButton = (
    <button
      type="button"
      data-component-id="data-grid-extend-row-btn"
      className={styles.extendedRowArrow}
      onClick={toggleExtendedRow}
    >
      <span>
        <Icon
          as={isOpen ? ArrowDown : ArrowRight}
          scale={0.4}
          alt={isOpen ? 'Arrow Down' : 'Arrow Right'}
        />
      </span>
    </button>
  )

  return content ? (
    <>
      {isOpen && (
        <>
          <hr className={styles.hr} />
          <div className={styles.content}>{content}</div>
        </>
      )}
      {renderedButton}
    </>
  ) : null
}

export default connect(styleRules)(DataGridRowFooterRenderer)
