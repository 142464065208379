// @flow

import React from 'react'
import { connect } from 'react-fela'
import { size } from 'lodash'

import User from 'react-ui/assets/icons/user-unwrapped.svg'
import Icon from 'react-ui/components/Icon'

import {
  type adjustCoordTypes,
  adjustCoordinates,
  getLabelAngle,
} from './chartHelper'

import type { FelaRulesType, FelaStylesType } from 'react-ui/typing'

/**
 * Configuration variables to generate
 * a speeder graph
 */
const START_ANGLE = 0
const END_ANGLE = 180
const RADIUS = 54 // circle radius
const OFFSET = 15
// adjust text position (using svg coordinates)
const POSITION_OFFSET = {
  x: 10,
  y: 10,
}
// base max width of the container for text labels
const WIDTH_BASE = 65

const TOTAL_ARC_ANGLE = END_ANGLE - START_ANGLE
const MAX_DASH_SIZE = Math.PI * 2 * RADIUS // === circumference
const BASE_DASH_SIZE = MAX_DASH_SIZE / (360 / TOTAL_ARC_ANGLE)

export type ClinicalCareChartType = {
  colors: {
    innerColor: ?string,
    outerColor: ?string,
  },
  innerIndex: number,
  labels: Array<string>,
  outerIndex: number,
  rules: FelaRulesType,
  styles: FelaStylesType,
}

const styleRules = ({
  theme: { palette },
  outerIndex,
  innerIndex,
  colors: { outerColor, innerColor },
  labels,
}) => {
  const coloredDashSize =
    outerIndex < labels.length
      ? BASE_DASH_SIZE / (labels.length - 1) * outerIndex
      : BASE_DASH_SIZE
  const coloredClinicialExpect =
    innerIndex < labels.length
      ? BASE_DASH_SIZE / (labels.length - 1) * innerIndex
      : BASE_DASH_SIZE

  return {
    graphWrapper: { margin: '0 auto', textAlign: 'center' },
    ClinicalCareChart: {
      className: 'ClinicalCareChart',
      display: 'block',
      margin: '0 auto',
      overflow: 'visible',
    },
    userIconWrap: {
      display: 'flex',
      overflow: 'visible',
      justifyContent: 'center',
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
    userIcon: {
      className: 'userIcon_SVG',
      strokeWidth: 0,
      fill: palette.dividerColor,
      position: 'absolute',
      top: '-15px',
      right: '-35px',
      height: '85px',
      width: '75px',
    },

    axisLabelsContainer: {
      className: 'ClinicalCareChart__axisLabelsContainer',
      fontSize: '9px',
    },

    axisLabel: {
      className: 'ClinicalCareChart__axisLabel',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '9px',
      lineHeight: '10px',
    },

    labelLine: {
      width: '23px',
      height: '3px',
      backgroundColor: palette.dividerColor,
      opacity: '.65',
      borderRadius: '5px',
    },

    circlesContainer: {
      className: 'ClinicalCareChart__circlesContainer',
      strokeWidth: '3',
      strokeLinecap: 'round',
    },

    baseCircle: {
      className: 'ClinicalCareChart__baseCircle',
      stroke: palette.dividerColor,
      strokeDasharray: `${BASE_DASH_SIZE} ${MAX_DASH_SIZE}`,
    },

    colorCircle: {
      className: 'ClinicalCareChart__colorCircle',
      stroke: outerColor || palette.component.primary.active,
      strokeDasharray: `${coloredDashSize} ${MAX_DASH_SIZE}`,
    },

    innerColorCircle: {
      className: 'ClinicalCareChart__colorInnerCircle',
      stroke: innerColor || palette.component.secondary.active,
      strokeDasharray: `${coloredClinicialExpect} ${MAX_DASH_SIZE}`,
    },

    baseInnerCircle: {
      className: 'ClinicalCareChart__baseInnerCircle',
      stroke: palette.dividerColor,
      strokeDasharray: `${BASE_DASH_SIZE} ${MAX_DASH_SIZE}`,
    },
  }
}

const ClinicalCareChart = (props: ClinicalCareChartType) => {
  const baseCircePath = `M-60,40 A51,51 0 1,1 54,50`
  const { labels, outerIndex, innerIndex, styles, rules } = props

  return (
    <div className={styles.graphWrapper}>
      <svg
        width="100%"
        height="250"
        className={styles.ClinicalCareChart}
        viewBox="0 -15 300 50"
      >
        <g transform="translate(150, 0)">
          {/* Axis Labels */}
          <g
            id="labels"
            transform="translate(0, 24)"
            className={styles.axisLabelsContainer}
          >
            {labels.reverse().map((title, i) => {
              const angle = getLabelAngle(
                size(labels),
                i,
                START_ANGLE,
                TOTAL_ARC_ANGLE,
              )
              const coordinates = adjustCoordinates(
                ({
                  angle,
                  WIDTH_BASE,
                  POSITION_OFFSET,
                  RADIUS,
                  OFFSET,
                }: adjustCoordTypes),
              )
              const labelKey = `${title}-${i}`
              return (
                <foreignObject
                  key={labelKey}
                  angle={coordinates.angle}
                  x={coordinates.x}
                  y={coordinates.y}
                  width={coordinates.containerWidth}
                  height="20"
                  style={{ overflow: 'visible' }}
                >
                  <div
                    className={styles.axisLabel}
                    style={{
                      textAlign:
                        (angle === 90 && 'center') ||
                        (angle > 90 && 'right') ||
                        'left',
                    }}
                  >
                    {title}
                  </div>
                </foreignObject>
              )
            })}
          </g>
          {/* Circles */}
          <g
            id="circles"
            transform="translate(0 1) scale(1.3)"
            fill="none"
            className={styles.circlesContainer}
          >
            {/* Base circle */}
            <path d={baseCircePath} className={styles.baseCircle} />

            {/* Color circle */}
            <path
              id="colored-outer"
              d={baseCircePath}
              className={outerIndex >= 0 ? styles.colorCircle : undefined}
            />
          </g>
          {/* nested Graph.. */}
          {/* Circles */}
          <g
            transform="translate(0 10) scale(1.05)"
            id="inner-circles"
            fill="none"
            className={styles.circlesContainer}
          >
            {/* Base circle */}
            <path
              id="colored-inner"
              d={baseCircePath}
              className={styles.baseInnerCircle}
            />
            {/* Color circle */}
            {/* 
              I assume here that we show clinical expectations 
              if and only if the goal would be higher than 0 : [innerIndex > 0]
            */}
            <path
              d={baseCircePath}
              className={innerIndex > 0 ? styles.innerColorCircle : undefined}
            />
          </g>
          <foreignObject style={{ overflow: 'visible' }}>
            <div className={styles.userIconWrap}>
              <Icon id="user-icon-gi" as={User} extend={rules.userIcon} />
            </div>
          </foreignObject>
        </g>
      </svg>
    </div>
  )
}

export default connect(styleRules)(ClinicalCareChart)
