// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

import Button from './Button'

type PropsType = {
  addOn: Node,
  as?: string,
  buttonAriaLabel?: string,
  children?: Node,
  label?: Node,
  onClick?: (event: SyntheticEvent<*>) => any,
}

const containerStyle = ({ theme, block, color = 'primary' }) => {
  const palette = theme.Button[color]

  return {
    pointerEvents: 'none',
    paddingRight: 0,
    display: block ? 'flex' : 'inline-flex',
    alignItems: 'center',
    maxWidth: '190px',
    margin: 0,
    overflow: 'hidden',
    ':hover': {
      backgroundColor: palette.base,
      borderColor: palette.base,
      color: palette.text,
    },
  }
}

const mainLabelStyle = ({ theme }) => ({
  paddingRight: theme.Grid.gutter,
  borderRight: '1px solid rgba(255,255,255,0.3)',
  height: theme.spacing(1),
  lineHeight: theme.spacing(1),
})

const addOnStyle = ({ theme, shape, color }) => {
  const palette = theme.Button[color]

  return {
    pointerEvents: 'initial',
    cursor: 'pointer',
    paddingLeft: theme.Grid.gutter,
    paddingRight:
      shape === 'pill'
        ? `calc(${theme.Grid.gutter} * 1.25)`
        : theme.Grid.gutter,
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    display: 'block',
    height: '100%',
    backgroundColor: 'transparent',
    color: 'inherit',
    ':hover': {
      borderColor: palette?.accent,
      backgroundColor: palette?.accent,
    },
    ':active': {
      backgroundColor: palette?.active,
    },
  }
}

const SegmentedButton = ({
  label,
  children,
  addOn,
  as = 'div',
  onClick,
  buttonAriaLabel,
  ...rest
}: PropsType) => {
  const accessibleLabel = buttonAriaLabel || label
  const { css } = useFela()

  return (
    <Button as={as} extend={containerStyle} {...(rest: any)}>
      <span className={css(mainLabelStyle)}>{label || children}</span>
      <button
        type="button"
        onClick={onClick}
        className={css(addOnStyle)}
        aria-label={accessibleLabel}
        title={accessibleLabel}
      >
        {addOn}
      </button>
    </Button>
  )
}

export default SegmentedButton
