// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { includes, map, reject, uniqueId } from 'lodash/fp'

import { AccordionItem } from 'react-ui/components/Accordion'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { shortDateFormat } from 'platform_web/services/dateTime'
import { Button, Heading, Text } from 'care-ui'

import { AssessmentRequestsQuestionnaireLoader } from './AssessmentRequestsQuestionnaire'

import type { AssessmentRequestsComponent_assessment_request } from './__generated__/AssessmentRequestsComponent_assessment_request.graphql'

type PropsType = {
  assessment_request: AssessmentRequestsComponent_assessment_request,
}

const accordionItemStyle = (theme, isOpen) => ({
  AccordionItem: {
    marginTop: '1.6rem',
    marginBottom: '0',
    backgroundColor: 'white',
    width: 'fit-content',
    [theme.breakpoints.queries.lg]: {
      width: '100%',
    },
  },
  header: {
    backgroundColor: theme.palette.dividerColor,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    fontSize: '1rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.dividerColor,
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    borderRadius: '0 0 4px 4px',
  },
})

const headerStyle = () => ({
  display: 'flex',
  padding: '0 7rem 0 2rem',
  position: 'relative',
  // top: '-0.4rem',
  '& h4': {
    textAlign: 'center',
    ':first-child': {
      width: '20rem',
      textAlign: 'left',
    },
    ':nth-child(2)': {
      width: '10rem',
    },
    ':nth-child(3)': {
      width: '10rem',
    },
    ':nth-child(4)': {
      width: '20rem',
    },
    ':nth-child(5)': {
      whiteSpace: 'no-wrap',
    },
  },
})

const wrapperStyle = () => ({
  overflowX: 'auto',
})

const requestHeadingStyle = () => ({
  display: 'flex',
})

const buttonStyle = () => ({
  display: 'flex',
  height: 'auto',
  marginLeft: '1rem',
})

const AssessmentRequestsComponentBase = ({ assessment_request }: PropsType) => {
  const {
    id: assessmentRequestId,
    sent_at,
    requestee,
    questionnaires,
    questionnaire_answer_sets,
  } = assessment_request
  const [isOpen, setOpen] = useState(true)
  const { css } = useFela()
  const { current_role: { role_type } } = useCurrentUser()

  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const isIndividual = role_type === 'INDIVIDUAL'

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
  })

  const answeredQuestionnairesIds = map('questionnaire.id')(
    questionnaire_answer_sets,
  )
  const unansweredQuestionnaires = reject(({ id }) =>
    includes(id)(answeredQuestionnairesIds),
  )(questionnaires)

  const renderAnsweredQuestionnaires = () => {
    if (!questionnaire_answer_sets.length) return null

    return questionnaire_answer_sets.map(qas => {
      const { label, id: questionnaireId } = qas.questionnaire
      const childAnswerSets = qas.answer_sets
      const requestIds = {
        assessmentRequestId,
        questionnaireId,
      }
      return (
        <AssessmentRequestsQuestionnaireLoader
          label={label}
          answers={qas.answers}
          rating={qas.rating}
          requestIds={requestIds}
          completedAt={qas.completed_at}
          answeredBy={requestee?.legal_name}
          key={uniqueId(label)}
        >
          {childAnswerSets &&
            childAnswerSets.length > 1 &&
            childAnswerSets.map(({ question_set, answers }) => (
              <AssessmentRequestsQuestionnaireLoader
                label={question_set.label}
                answers={answers}
                key={uniqueId(question_set.label)}
                completedAt={qas.completed_at}
                answeredBy={requestee?.legal_name}
                rating={null}
                isChild
              />
            ))}
        </AssessmentRequestsQuestionnaireLoader>
      )
    })
  }

  const renderUnansweredQuestionnaires = () => {
    return unansweredQuestionnaires.map(questionnaire => {
      const { label, id: questionnaireId } = questionnaire
      const requestIds = {
        assessmentRequestId,
        questionnaireId,
      }
      return (
        <AssessmentRequestsQuestionnaireLoader
          label={label}
          key={uniqueId(label)}
          requestIds={requestIds}
          rating={null}
          answers={null}
          completedAt={null}
          answeredBy={requestee?.legal_name}
        />
      )
    })
  }

  const requestHeading = (
    <Text as="div" size="lg" color="title" extend={requestHeadingStyle}>
      <div>Requested on {shortDateFormat(sent_at)}</div>
      {isIndividual &&
        !questionnaire_answer_sets.length && (
          <div className={css(buttonStyle)}>
            <Button onClick={start}>(Complete all)</Button>
          </div>
        )}
    </Text>
  )

  return (
    <div className={css(wrapperStyle)}>
      <AccordionItem
        withArrow
        extend={({ theme }) => accordionItemStyle(theme, isOpen)}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={requestHeading}
      >
        <div className={css(headerStyle)} role="rowgroup">
          <Heading level={4}>Question Set</Heading>
          <Heading level={4}>Rating</Heading>
          <Heading level={4}>Answered on</Heading>
          <Heading level={4}>Status</Heading>
          <Heading level={4}>Answered by</Heading>
        </div>
        {renderAnsweredQuestionnaires()}
        {renderUnansweredQuestionnaires()}
      </AccordionItem>
    </div>
  )
}

export const AssessmentRequestsComponentLoader = createFragmentContainer(
  AssessmentRequestsComponentBase,
  {
    assessment_request: graphql`
      fragment AssessmentRequestsComponent_assessment_request on AssessmentRequest {
        id
        sent_at
        requestee {
          legal_name
        }
        questionnaire_answer_sets {
          id
          status
          completed_at
          rating
          questionnaire {
            id
            label
          }
          answers {
            ...AssessmentRequestsQuestionnaire_answers
          }
          answer_sets {
            id
            question_set {
              id
              label
              name
            }
            answers {
              ...AssessmentRequestsQuestionnaire_answers
            }
          }
        }
        questionnaires {
          id
          label
        }
      }
    `,
  },
)
