// @flow

import React, { Fragment } from 'react'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'
import { map } from 'lodash/fp'

import Accordion from 'react-ui/components/Accordion'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import UserResearchProgramsResponse from 'pages/Role/UserResearchProgramsResponse'
import { SuspenseLoader } from 'care-ui'

const linkStyle = () => ({ LinkClass: { lineHeight: 'unset' } })

const fragment = graphql`
  fragment ResearchDataSharingPage_user_research_programs_responses on UserResearchProgramsResponse
    @relay(plural: true) {
    id
    user_id
    tenant_research_program_id
    accepted
    tenant_research_program {
      research_program {
        title
        description
      }
    }
  }
`

const query = graphql`
  query ResearchDataSharingPage_Query {
    viewer {
      currentUser {
        user_research_programs_responses {
          ...ResearchDataSharingPage_user_research_programs_responses
        }
      }
    }
  }
`

const ResearchDataSharingPage = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const { user_research_programs_responses: fragmentRef } = currentUser || {}
  const user_research_programs_responses = useFragment(fragment, fragmentRef)

  return (
    <Fragment>
      <Heading level={2}>Research and data sharing</Heading>
      <p>
        Your service participates in the following research program(s) to
        develop a greater depth of understanding of people&apos;s needs. This
        information is used to improve the delivery of person centred care.
        Contributing your de-identified data is completely voluntary and will
        not affect your clinical care. Read our{' '}
        <Link extend={linkStyle} to={{ name: 'research_data_sharing' }}>
          data policy
        </Link>{' '}
        for more information on how your data will be used.
      </p>
      <Accordion>
        {map(urpr => (
          <UserResearchProgramsResponse
            key={urpr.id}
            userResponse={urpr}
            researchProgram={urpr.tenant_research_program.research_program}
          />
        ))(user_research_programs_responses)}
      </Accordion>
    </Fragment>
  )
}

export const ResearchDataSharingPageLoader = () => (
  <SuspenseLoader message="ResearchDataSharingPage">
    <ResearchDataSharingPage />
  </SuspenseLoader>
)
