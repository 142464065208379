// @flow

import React from 'react'

import { Box, Button, Modal, SuspenseLoader } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import ActivityLogCardContainer from './ActivityLogCardContainer'

type PropsType = {
  triggerLabel: string,
  userId: string,
}

const ActivityLogModal = ({ triggerLabel, userId }: PropsType) => {
  const { isOpen, closeModal, openModal } = useModal()

  return (
    <>
      <Button
        onClick={openModal}
        dataTestId="activity-log-modal-trigger"
        ariaLabel={triggerLabel}
        variant="link"
      >
        {triggerLabel}
      </Button>

      <Modal isOpen={isOpen} closeModal={closeModal} heading="Recent Activity">
        <Box height="70vh">
          <SuspenseLoader>
            <ActivityLogCardContainer userId={userId} />
          </SuspenseLoader>
        </Box>
      </Modal>
    </>
  )
}

export default ActivityLogModal
