// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AnswerTableLoader } from 'components/AnswerTable'
import { AccordionItem } from 'react-ui/components/Accordion'
import { shortDate } from 'services/dateTime'
import createComponentId from 'shared/services/id'

import type { ScoreCardsPreviousAnswerSet_answer_set } from './__generated__/ScoreCardsPreviousAnswerSet_answer_set.graphql'
import type { ScoreCardsPreviousAnswerSet_user_trackable } from './__generated__/ScoreCardsPreviousAnswerSet_user_trackable.graphql'

export type PropsType = {
  answer_set: ScoreCardsPreviousAnswerSet_answer_set,
  broadcastPointData: Function,
  componentId?: string,
  label: string,
  openedFromGraph: boolean,
  result: any,
  selected_answer?: string,
  user_trackable: ScoreCardsPreviousAnswerSet_user_trackable,
}

const defaultId = createComponentId(__filename)

const accordionItemStyle = ({ theme }) => ({
  AccordionItem: {
    marginBottom: '0',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.dividerColor}`,
    '& table': {
      overflow: 'hidden',
      marginTop: '0',
      marginBottom: '0',
      '& th': {
        '&:last-child': {
          paddingLeft: '0',
          width: '27.6rem',
          maxWidth: '100%',
        },
      },
      '& tbody': {
        backgroundColor: 'unset',
        '& tr': {
          minHeight: '2rem',
          '&:last-child': {
            '& td': {
              borderBottom: 'none',
            },
          },
        },
        '& td': {
          borderBottom: `1px solid ${theme.palette.dividerColor}`,
          padding: '0.5rem 0',
          '&:first-child': {
            paddingLeft: '0',
            borderLeft: '2.8rem solid transparent',
            paddingRight: '1rem',
            height: '3.3rem',
          },
          '&:nth-child(2)': {
            paddingRight: '1rem',
          },
          '&:last-child': {
            paddingRight: '0',
            borderRight: '2.8rem solid transparent',
          },
        },
      },
    },
  },
  header: {
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    height: '3.7rem',
    lineHeight: '2rem',
    paddingLeft: '0.3rem',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    ...theme.care.typography.desktop.bodyLg,
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.7rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.lightBackground,
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
  },
})

const headerStyle = () => ({
  fontWeight: 'normal',
  display: 'flex',
  '& div': {
    ':first-child': {
      width: '23rem',
    },
    ':nth-child(2)': {
      width: '13rem',
    },
    ':nth-child(3)': {
      width: '12.3rem',
    },
    ':nth-child(4)': {
      width: '14.5rem',
    },
  },
})

const headingData = ({ css, answer_set, label, resultLabel }) => {
  const rawSubmitDate = answer_set.submitted_at ? answer_set.submitted_at : ''
  const submittedAtDate = answer_set.submitted_at
    ? format(shortDate)(new Date(rawSubmitDate))
    : 'Not Submitted'

  return (
    <div className={css(headerStyle)} data-testid="QuestionAnswerRow">
      <div>{label}</div>
      <div data-testid="Rating">{resultLabel}</div>
      <div data-testid="AnsweredOn">{submittedAtDate}</div>
      <div data-testid="Status">
        {answer_set.skipped ? 'Skipped' : 'Completed'}
      </div>
      <div data-testid="AnsweredBy">{answer_set.answered_by?.user?.name}</div>
    </div>
  )
}

const getResultLabel = (result, formula_ranges) => {
  if (!formula_ranges) {
    return ''
  }

  const formulaRange = formula_ranges.find(range => range.value === result)
  if (!formulaRange) {
    return ''
  }

  return formulaRange.label
}

const ScoreCardsPreviousAnswerSetComponent = ({
  broadcastPointData,
  openedFromGraph,
  result,
  componentId = defaultId,
  label,
  answer_set,
  answer_set: { answers },
  user_trackable: { trackable: { formula: { formula_ranges } } },
}: PropsType) => {
  const { css } = useFela()
  const [isOpen, setOpen] = useState(openedFromGraph)
  const onToggleOpen = () => {
    if (openedFromGraph) {
      broadcastPointData(null)
    }
    setOpen(!isOpen)
  }
  const resultLabel = getResultLabel(result?.value, formula_ranges)
  const divKey = result ? `${result?.date}-${openedFromGraph.toString()}` : ''

  return (
    <div className={componentId} key={divKey}>
      <AccordionItem
        withArrow
        extend={accordionItemStyle}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={headingData({ css, answer_set, label, resultLabel })}
      >
        <AnswerTableLoader answers={answers} />
      </AccordionItem>
    </div>
  )
}

export const ScoreCardsPreviousAnswerSet = createFragmentContainer(
  ScoreCardsPreviousAnswerSetComponent,
  {
    user_trackable: graphql`
      fragment ScoreCardsPreviousAnswerSet_user_trackable on UserTrackable {
        trackable {
          formula {
            formula_ranges {
              label
              value
            }
          }
        }
      }
    `,
    answer_set: graphql`
      fragment ScoreCardsPreviousAnswerSet_answer_set on AnswerSet {
        skipped
        answered_by {
          ... on RoleInterface {
            id
            user {
              name
            }
          }
        }
        answers {
          ...AnswerTable_answers
        }
        submitted_at
      }
    `,
  },
)
