// @flow

import React from 'react'
import Link from 'found/Link'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { Layout as l } from 'shared/ui/Styles'

const Page = () => (
  <Section color="white">
    <Container>
      <Heading level={2}>Research Data Sharing</Heading>
      <p>Last modified: June 2022</p>
      <div className={l.bulkTextBlock}>
        <p>Thank you for sharing your de-identified data with Innowell.</p>
        <p>
          Your personal information is de-identified and no one can link your
          name, email address or date of birth to your health information.
        </p>
        <p>
          Your personal information will be always stored in The Innowell
          Platform and only your de-identified data will be used for research
          purposes. The de-identified data will be extracted, used for
          statistical analysis, and to improve the overall effectiveness of The
          Innowell Platform.
        </p>
        <p>
          Contributing your de-identified data is completely voluntary and you
          can change your mind at any time. Furthermore, your decision whether
          or not to have your de-identified data used for research purposes will
          not affect your standard clinical care, or your current or future
          relationship with your service provider, or health professional, or
          Innowell.
        </p>
        <p>
          For more information about how Innowell uses data please see, <br />
          <Link to={privacyPolicyUrl()}>
            https://www.innowell.org/privacy-policy/
          </Link>
        </p>
      </div>
    </Container>
  </Section>
)

export default Page
