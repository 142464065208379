// @flow

import React from 'react'
import { type FormikProps } from 'formik'
import useRouter from 'found/useRouter'

import Link from 'react-ui/components/Link/Link'
import { signInRequest } from 'shared/services/api/session'
import Sentry from 'shared/services/sentry'
import { Box, FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import SignInForm from './components/SignInForm'

const genericError =
  "Sorry, an error occurred with our server. We've notified our team so that they can address it. Please refresh the page before trying to login again."

const SignInPage = () => {
  const { router } = useRouter()

  function onSubmit(values, { setSubmitting, setStatus }: FormikProps) {
    setStatus()
    setSubmitting(true)
    signInRequest(values)
      .then(res => {
        setSubmitting(false)
        const nextLocation = res.headers.get('Location') || '/'
        setTimeout(() => {
          router.replace({
            name: 'signingIn',
            params: {
              nextLocation,
            },
          })
        }, 0)
        return res
      })
      .catch(error => {
        setSubmitting(false)
        if (error?.response?.name === 'AbortError') {
          setStatus({ error: error?.message })
        } else {
          switch (error.response.status) {
            case 401: {
              // todo: this error is awkward - refactor the res error
              // so that the json is already converted.
              // eg. { status, message } = res.error
              error.response
                .json()
                .then(body => {
                  setStatus({ error: body.error })
                  return body
                })
                .catch(err => {
                  setStatus({ error: genericError })
                  Sentry.captureException(err)
                })
              break
            }
            default: {
              setStatus({ error: genericError })
              Sentry.captureException(error)
            }
          }
        }
      })
  }

  return (
    <Box height="500px" marginX="auto" marginY="auto">
      <FlexContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <TextNew as="h1" typography="h1">
          Welcome to the Innowell Platform
        </TextNew>

        <Box width="360px">
          <SignInForm onSubmit={onSubmit} />
        </Box>

        <Box marginY="xs">
          <Link to="/users/password/new">Get help signing in.</Link>
        </Box>
      </FlexContainer>
    </Box>
  )
}

export default SignInPage
