// @flow

import React, { type Node, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AccordionItem } from 'react-ui/components/Accordion'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { shortDate } from 'services/dateTime'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button, Text } from 'care-ui'

import { AssessmentRequestsAnswerTableLoader } from './AssessmentRequestsAnswerTable'

import type { AssessmentRequestsQuestionnaire_answers } from './__generated__/AssessmentRequestsQuestionnaire_answers.graphql'

type RequestIdsType = {
  assessmentRequestId: string,
  questionnaireId: string,
}
type PropsType = {
  answeredBy: ?string,
  answers: ?AssessmentRequestsQuestionnaire_answers,
  children?: Node,
  completedAt: ?string,
  isChild?: boolean,
  label: string,
  rating: ?string,
  requestIds?: RequestIdsType,
}

const accordionItemStyle = ({ theme, isChild }) => {
  const headerLeftPadding = isChild ? '1.7rem' : '0.3rem'
  return {
    AccordionItem: {
      marginBottom: '0',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.dividerColor}`,
      '& table': {
        overflow: 'hidden',
        marginTop: '0',
        marginBottom: '0',
        '& th': {
          '&:last-child': {
            paddingLeft: '0',
            width: '27.6rem',
            maxWidth: '100%',
          },
        },
        '& tbody': {
          backgroundColor: 'unset',
          '& tr': {
            minHeight: '2rem',
            '&:last-child': {
              '& td': {
                borderBottom: 'none',
              },
            },
          },
          '& td': {
            borderBottom: `1px solid ${theme.palette.dividerColor}`,
            padding: '0.5rem 0',
            '&:first-child': {
              paddingLeft: '0',
              borderLeft: '2.8rem solid transparent',
              paddingRight: '1rem',
              height: '3.3rem',
            },
            '&:nth-child(2)': {
              paddingRight: '1rem',
            },
            '&:last-child': {
              paddingRight: '0',
              borderRight: '2.8rem solid transparent',
            },
          },
        },
      },
    },
    header: {
      backgroundColor: 'white',
      flexDirection: 'row-reverse',
      height: '3.7rem',
      lineHeight: '2rem',
      paddingLeft: headerLeftPadding,
    },
    headerContainer: {
      backgroundColor: 'white',
    },
    heading: {
      fontSize: '1rem',
    },
    icon: {
      marginLeft: '0.3rem',
      margin: '0.7rem 0.9rem 0 0.3rem',
      width: '0.5rem',
      height: '0.5rem',
    },
    children: {
      backgroundColor: theme.palette.lightBackground,
      paddingTop: '0',
      paddingBottom: '0',
      paddingRight: '0',
      paddingLeft: '0',
    },
  }
}

const headerStyle = () => {
  return {
    display: 'flex',
    '& div': {
      textAlign: 'center',
      ':first-child': {
        width: '20rem',
        textAlign: 'left',
      },
      ':nth-child(2)': {
        width: '10rem',
      },
      ':nth-child(3)': {
        width: '10rem',
      },
      ':nth-child(4)': {
        width: '20rem',
      },
      ':nth-child(5)': {
        whiteSpace: 'no-wrap',
      },
    },
  }
}

const noResultsStyle = () => ({
  paddingTop: '10',
  paddingBottom: '10',
  paddingRight: '10',
  paddingLeft: '20',
})

const AssessmentRequestsQuestionnaireBase = ({
  label,
  completedAt,
  answeredBy,
  answers,
  rating,
  children,
  isChild,
  requestIds,
}: PropsType) => {
  const { current_role: { role_type } } = useCurrentUser()

  const isIndividual = role_type === 'INDIVIDUAL'

  const [isOpen, setOpen] = useState(false)
  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const { assessmentRequestId, questionnaireId } = requestIds || {}

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
    questionnaireId,
  })

  const takeAssessmentButton = <Button onClick={start}>Take assessment</Button>

  const getStatus = submittedAtDate => {
    if (submittedAtDate) {
      return 'Completed'
    } else if (isIndividual && requestIds) {
      return takeAssessmentButton
    } else if (isIndividual && answers?.length) {
      return 'Answered'
    }

    return answers?.length ? 'Incomplete' : 'Unanswered'
  }

  const getAnsweredByName = () => {
    if (!completedAt) return ''

    return answeredBy
  }

  const submittedAtDate = completedAt
    ? format(shortDate)(new Date(completedAt || ''))
    : ''

  const headingData = () => {
    return (
      <Text
        size="lg"
        color="title"
        extend={headerStyle}
        data-testid="QuestionAnswerRow"
      >
        <div>{label}</div>
        <div data-testid="Rating">{rating}</div>
        <div data-testid="AnsweredOn">{submittedAtDate}</div>
        <div data-testid="Status">{getStatus(submittedAtDate)}</div>
        <div data-testid="AnsweredBy">{getAnsweredByName()}</div>
      </Text>
    )
  }

  const renderAnswers = () => {
    if (children) {
      return children
    }

    return answers?.length ? (
      <AssessmentRequestsAnswerTableLoader answers={answers} />
    ) : (
      <Text size="lg" color="title" extend={noResultsStyle}>
        No results found
      </Text>
    )
  }

  return (
    <AccordionItem
      withArrow
      extend={({ theme }) => accordionItemStyle({ theme, isChild })}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      heading={headingData()}
      data-testid="QuestionSetRow"
    >
      {renderAnswers()}
    </AccordionItem>
  )
}

export const AssessmentRequestsQuestionnaireLoader = createFragmentContainer(
  AssessmentRequestsQuestionnaireBase,
  {
    answers: graphql`
      fragment AssessmentRequestsQuestionnaire_answers on AnswerInterface
        @relay(plural: true) {
        ...AssessmentRequestsAnswerTableBase_answers
      }
    `,
  },
)
