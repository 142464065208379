// @flow

import React, { useState } from 'react'

import { normalizeClinicianValues } from 'shared/services/normalization'
import { type UiStyleProps } from 'shared/ui/Forms/Fields/RadioSet/RadioSet'
import { PublicHeader } from 'shared/ui/Layout/Public'
import type { QuestionnaireType } from 'shared/utils/SimpleQuestionnaireTypes'

import OnboardingFinished from './OnboardingFinished'
import OnboardingIntro from './OnboardingIntro'
import OnboardingWizard from './OnboardingWizard'

type PropsType = UiStyleProps & {
  handleSubmit: (values: Object) => void,
  hasHeader?: boolean,
  pageContent?: string | Node,
  pageTitle?: string | Node,
  questionnaire: QuestionnaireType,
  userName: string,
}

const OnboardingPages = ({
  questionnaire,
  userName,
  hasHeader = true,
  pageTitle,
  pageContent,
  uiStyle,
  handleSubmit,
}: PropsType) => {
  const [page, setPage] = useState('intro')
  const [values, setValues] = useState({})

  switch (page) {
    case 'intro':
      return (
        <>
          {hasHeader && <PublicHeader white />}
          <OnboardingIntro
            pageTitle={pageTitle}
            pageContent={pageContent}
            handleWizardStart={() => setPage('wizard')}
          />
        </>
      )
    case 'wizard':
      return (
        <>
          {hasHeader && <PublicHeader white />}
          <OnboardingWizard
            questionnaire={questionnaire}
            handleWizardComplete={v => {
              setPage('finished')
              setValues(v)
            }}
            uiStyle={uiStyle}
          />
        </>
      )
    case 'finished':
      return (
        <>
          {hasHeader && <PublicHeader white />}
          <OnboardingFinished
            userName={userName}
            handleQuestionsSubmit={() =>
              handleSubmit(normalizeClinicianValues(values))
            }
          />
        </>
      )
    default:
      return <div>Unknown page</div>
  }
}

export default OnboardingPages
