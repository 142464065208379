// @flow

import React from 'react'
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import { FlexContainer, ScrollableBox, Text } from 'care-ui'

import ClinicianNoteCard from './ClinicianNoteCard'

type PropsType = {
  userId: string,
}

const query = graphql`
  query ClinicianNoteCardContainerQuery(
    $id: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        ...ClinicianNoteCardContainer_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

const paginationFragment = graphql`
  fragment ClinicianNoteCardContainer_user on User
    @relay(mask: false)
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "ClinicianNoteCardContainerPaginationQuery") {
    id
    clinician_notes(first: $count, after: $cursor)
      @connection(key: "ClinicianNoteCardContainer_clinician_notes") {
      edges {
        node {
          ...ClinicianNoteCard_clinician_note
        }
      }
    }
  }
`

const ClinicianNoteCardContainer = ({ userId }: PropsType) => {
  const { node } = useLazyLoadQuery(query, { id: userId, count: 50 })
  const { data, loadNext, hasNext } =
    usePaginationFragment(paginationFragment, node) || {}
  const { edges } = data?.clinician_notes || {}

  const clinicianNotes = edges?.map(({ node: clinicianNote }) => (
    <ClinicianNoteCard
      key={uniqueId('clinician-note-')}
      clinicianNote={clinicianNote}
    />
  ))
  const clinicianNotesEmpty = clinicianNotes?.length === 0

  const handleScroll = e => {
    const bottom =
      Number(e?.target.scrollHeight) - Number(e?.target.scrollTop) ===
      e?.target.clientHeight
    if (bottom && hasNext) {
      loadNext(5)
    }
  }

  if (clinicianNotesEmpty) {
    return (
      <FlexContainer justifyContent="center">
        <Text>No Clinician Notes</Text>
      </FlexContainer>
    )
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      onScroll={handleScroll}
      dataTestId="notesContainer"
    >
      <FlexContainer direction="column" gap="sm">
        {clinicianNotes}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default ClinicianNoteCardContainer
