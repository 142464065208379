// @flow

import React from 'react'
import { connect } from 'react-fela'

import Dashboard from 'react-ui/assets/icons/dashboard.svg'
import Questionnaire from 'react-ui/assets/icons/questionnaire.svg'
import { H2 } from 'react-ui/components/Heading'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import {
  resumeInitialQuestionnaire,
  updateQuestionnaire,
  welcomeToDashboard,
} from 'platform_web/services/analytics/events'
import { Button } from 'care-ui'

import WelcomeCard from './WelcomeCard'
import { type WelcomePagePropsType } from './WelcomePage'
import {
  questionnaireCardDesc,
  questionnarieCardRoute,
} from './welcomePageHelper'

const styleRules = ({ theme }) => ({
  title: {
    margin: '20px 0 ',
    textAlign: 'center',
    color: theme.palette.input.default.base,
    [theme.breakpoints.queries.md]: {
      textAlign: 'left',
    },
  },
  Cards: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    flexDirection: 'column',
    maxWidth: '500px',
    alignSelf: 'center',
    marginTop: '10px',
    justifyContent: 'space-evenly',
    [theme.breakpoints.queries.md]: {
      marginTop: '40px',
      maxWidth: 'unset',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  goBackButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.queries.xxsOnly]: {
      minHeight: '200px',
      alignItems: 'flex-end',
    },
  },
})

const WelcomeCardsWrapper = (props: WelcomePagePropsType) => {
  const {
    user,
    roleType,
    initialQuestionnaireId,
    initialQuestionnaireStatus,
    summaryQuestionnaireId,
    styles,
    rules,
  } = props
  const isInitialQuestionnaireCompleted =
    initialQuestionnaireStatus === 'Completed'
  const policies = usePolicies()
  const isIndividual = roleType === 'INDIVIDUAL'

  const canCreateQuestionnaire = policies.CAN_CREATE_USER_QUESTIONNAIRE
  const linkToGen = questionnarieCardRoute({
    isInitialQuestionnaireCompleted,
    summaryQuestionnaireId,
    initialQuestionnaireId,
  })

  return (
    <>
      <H2 extend={rules.title}>Welcome back {user.casual_name}</H2>
      {isIndividual ? (
        <div className={styles.Cards}>
          <WelcomeCard
            id="questionnaire"
            showButton={!!linkToGen}
            isButtonDisabled={!canCreateQuestionnaire}
            title={
              isInitialQuestionnaireCompleted
                ? 'Maintain'
                : 'Initial Questionnaire'
            }
            icon={Questionnaire}
            description={questionnaireCardDesc({
              isInitialQuestionnaireCompleted,
              summaryQuestionnaireId,
            })}
            buttonLabel={isInitialQuestionnaireCompleted ? 'Update' : 'Resume'}
            linkTo={canCreateQuestionnaire ? linkToGen : ''}
            analytics={
              isInitialQuestionnaireCompleted
                ? updateQuestionnaire
                : resumeInitialQuestionnaire
            }
          />
          <WelcomeCard
            id="dashboard"
            title="Your Story"
            icon={Dashboard}
            description="Explore your dashboard, track your progress"
            buttonLabel="Browse"
            linkTo="dashboard"
            analytics={welcomeToDashboard}
          />
        </div>
      ) : (
        <div className={styles.goBackButton}>
          <Button variant="secondary" onClick={() => window.history.go(-1)}>
            Go Back
          </Button>
        </div>
      )}
    </>
  )
}

export default connect(styleRules)(WelcomeCardsWrapper)
