// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Section from 'react-ui/components/Section'
import { Button, FlexContainer, Modal, Text } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import type { StaffDownloadReportButton_individual } from './__generated__/StaffDownloadReportButton_individual.graphql'

type PropsType = {
  individual: StaffDownloadReportButton_individual,
}

const buttonContainerRules = () => ({
  Section: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const StaffDownloadReportButton = ({ individual }: PropsType) => {
  const { user } = individual
  const { isOpen, closeModal, openModal } = useModal()

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  return (
    <>
      <Section extend={buttonContainerRules} noPadding color="white">
        <Button
          onClick={openModal}
          variant="primary"
          ariaLabel="Report"
          dataTestId="report"
        >
          Report
        </Button>
      </Section>

      <Modal
        closeModal={closeModal}
        heading="Results download - security warning"
        isOpen={isOpen}
      >
        <FlexContainer
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="sm"
        >
          <Text as="p">
            Please ensure you take appropriate steps to protect the privacy of
            this document in accordance with the clinical governance
            requirements of your service.
          </Text>

          <Button
            dataTestId="emrDownloadButton"
            onClick={handleClick}
            variant="primary"
            ariaLabel="Continue"
          >
            Continue
          </Button>
        </FlexContainer>
      </Modal>
    </>
  )
}

export default createFragmentContainer(StaffDownloadReportButton, {
  individual: graphql`
    fragment StaffDownloadReportButton_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
