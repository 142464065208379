/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StaffCliniciansTable_clinicians$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffCliniciansPage_tenant$ref: FragmentReference;
declare export opaque type StaffCliniciansPage_tenant$fragmentType: StaffCliniciansPage_tenant$ref;
export type StaffCliniciansPage_tenant = {|
  +roles: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id?: string,
        +legal_name?: ?string,
        +preferred_name?: ?string,
        +$fragmentRefs: StaffCliniciansTable_clinicians$ref,
      |}
    |}>
  |},
  +$refType: StaffCliniciansPage_tenant$ref,
|};
export type StaffCliniciansPage_tenant$data = StaffCliniciansPage_tenant;
export type StaffCliniciansPage_tenant$key = {
  +$data?: StaffCliniciansPage_tenant$data,
  +$fragmentRefs: StaffCliniciansPage_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "roles"
        ]
      }
    ]
  },
  "name": "StaffCliniciansPage_tenant",
  "selections": [
    {
      "alias": "roles",
      "args": [
        {
          "kind": "Literal",
          "name": "roleTypes",
          "value": [
            "CLINICIAN"
          ]
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "__StaffCliniciansPage_roles_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "legal_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "preferred_name",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StaffCliniciansTable_clinicians"
                    }
                  ],
                  "type": "ClinicianRole",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b2acc056a1ee3f47a1fe6badd49b4354';

module.exports = node;
