// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import classNames from 'classnames'
import { compact, omit, size } from 'lodash'

import DataGridRowFooterContent, {
  DataGridRowFooterRenderer,
} from 'components/DataGridRowFooter'
import { StaffIndividualsListLoader } from 'components/Staff/StaffIndividualsList'
import DataGrid, { TextCell } from 'react-ui/components/DataGrid'
import UserCard from 'react-ui/components/UserCard'

import { StaffClinicianActionsLoader } from './StaffClinicianActions'

import type { StaffCliniciansTable_clinicians } from './__generated__/StaffCliniciansTable_clinicians.graphql'

type PropsType = {
  clinicians: StaffCliniciansTable_clinicians,
}

const customStyle = () => ({
  DataGrid: {
    maxHeight: 'initial',
  },
  headerVisible: {
    '& div': {
      ':last-child': {
        borderColor: 'transparent',
      },
    },
  },
  body: {
    overflow: 'hidden',
    '& .TextCell': {
      paddingTop: 0,
      '&.actionCell': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& button': {
          paddingLeft: 0,
        },
      },
    },
    '& .BasicCellRenderer': {
      display: 'flex',
      alignItems: 'center',
      padding: '18px 16px',
      height: '80px',
      '&:first-child': {
        paddingLeft: '60px',
      },
      '&:last-child': {
        padding: 0,
        '& > div': {
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            margin: 0,
            padding: '1rem',
          },
        },
      },
      '& div': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  rowParentBorder: {
    position: 'relative',
  },
})

const renderRow = (data, index, props, children, footer) => {
  const { componentId } = props
  const { id } = data

  // rowId is the className that is used for backend tests
  const rowId = componentId && id ? `${componentId}-${id}` : ''

  const newProps = omit(
    {
      ...props,
      className: classNames(props.className, rowId),
    },
    ['componentId', 'border'],
  )

  return (
    <div key={props.key} className={props.border}>
      <div {...newProps}>{children}</div>
      {footer && <DataGridRowFooterRenderer content={footer(data)} />}
    </div>
  )
}

const footer = ({ name: user }) => {
  return <DataGridRowFooterContent user={user} clinician />
}

const StaffCliniciansTable = ({ clinicians }: PropsType) => {
  return (
    <DataGrid
      data={compact(clinicians)}
      componentId="ClinicianTable"
      columns={['name', 'assigned', 'actions']}
      extend={customStyle}
      renderRow={renderRow}
      footer={footer}
      columnConfig={{
        name: {
          sortable: false,
          width: 'calc(calc(100% - 3.15rem) * 0.65)',
          label: 'Clinician name',
          format: user => <UserCard user={user} link={false} staff />,
        },
        assigned: {
          label: 'Assigned',
          width: 'calc(calc(100% - 3.15rem) * 0.35)',
          sortable: false,
          format: data => (
            <TextCell className="actionCell">
              <StaffIndividualsListLoader
                clinician={data}
                triggerLabel={size(
                  data.clinician_assignments_as_clinician,
                ).toString()}
              />
            </TextCell>
          ),
        },
        actions: {
          width: '3.15rem',
          label: ' ',
          sortable: false,
          format: data => (
            <StaffClinicianActionsLoader
              clinician={data}
              userId={data.user.id}
            />
          ),
        },
      }}
      mapData={data => ({
        name: data.user,
        assigned: data,
        actions: data,
      })}
    />
  )
}

const StaffCliniciansTableLoader = createFragmentContainer(
  StaffCliniciansTable,
  {
    clinicians: graphql`
      fragment StaffCliniciansTable_clinicians on ClinicianRole
        @relay(plural: true) {
        ...StaffClinicianActions_clinician
        ...StaffIndividualsList_clinician
        clinician_assignments_as_clinician {
          id
        }
        user {
          id
          name
          ...AvatarLoader_user
          ...UserCardLoader_user
          ...DataGridRowFooterContentLoader_user
        }
      }
    `,
  },
)

export default StaffCliniciansTableLoader
