// @flow

import React, { useMemo } from 'react'
import { useFela } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Field } from 'react-ui/components/Form'
import { Column, Row } from 'react-ui/components/Grid'
import type { RelationshipEnumType } from 'containers/ViewerEnums'
import { createSupportPersonAssignment } from 'mutations/SupportPersonAssignment'
import titleize from 'services/titleize'
import { requiredTextField } from 'shared/services/formik'
import { Button, Input, Select } from 'care-ui'

import { formStyle } from './AddSupportPersonForm.style'

type PropsType = {
  afterSubmit?: () => void,
  relationships: RelationshipEnumType,
}

const AddSupportPersonForm = ({ relationships, afterSubmit }: PropsType) => {
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const initialValues = useMemo(
    () => {
      return {
        support_persons_email: '',
        nickname: '',
        relationship: relationships[0],
      }
    },
    [relationships],
  )

  const validationSchema = useMemo(
    () => {
      return yup.object().shape({
        support_persons_email: requiredTextField(
          'Support Person’s Email',
        ).email('Support Person’s Email must be a valid email'),
        nickname: requiredTextField('Support Person’s Name'),
        relationship: yup
          .string()
          .required()
          .oneOf(relationships, 'Invalid relationship type'),
      })
    },
    [relationships],
  )

  const onSubmit = (support_person_assignment, { resetForm }) => {
    createSupportPersonAssignment({
      environment,
      onCompleted: afterSubmit,
      variables: {
        input: {
          support_person_assignment,
        },
      },
    })

    resetForm()
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const extractErrors = (field: string): string | boolean =>
    !!touched[field] && !!errors[field] && errors[field]

  const relationshipMap: { label: string, value: string }[] = relationships.map(
    relationship => ({
      value: relationship,
      label: titleize(relationship),
    }),
  )

  return (
    <>
      <form
        className={css(formStyle)}
        data-component-id="AddSupportPersonForm"
        onSubmit={handleSubmit}
        id="addSupportPersonForm"
      >
        <Row>
          <Column col={6}>
            <Field
              id="support_persons_name_input"
              label="Name"
              name="nickname"
              error={extractErrors('nickname')}
              input={
                <Input
                  id="support_persons_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="nickname"
                  value={values.nickname}
                  placeholder="Add your support person’s name"
                />
              }
            />
          </Column>

          <Column col={6}>
            <Field
              id="support_persons_email_input"
              label="Email"
              name="support_persons_email"
              error={extractErrors('support_persons_email')}
              input={
                <Input
                  id="support_persons_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="support_persons_email"
                  value={values.support_persons_email}
                  placeholder="Add your support person’s email"
                />
              }
            />
          </Column>
        </Row>

        <Row>
          <Column col={6}>
            <Field
              id="support_persons_relationship_input_id"
              label="Relationship"
              name="relationship"
              error={extractErrors('relationship')}
              input={
                <Select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="relationship"
                  value={values.relationship}
                  options={relationshipMap}
                />
              }
            />
          </Column>
        </Row>

        <center>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            ariaLabel="Invite support person"
            dataTestId="invite-support-person"
          >
            Invite support person
          </Button>
        </center>
      </form>
    </>
  )
}

export default AddSupportPersonForm
