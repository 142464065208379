// @flow

import React from 'react'
import { useFela } from 'react-fela'
import useRouter from 'found/useRouter'

import Error from 'react-ui/assets/icons/error-bang.svg'
import Heading from 'react-ui/components/Heading'
import { Welcome2Col } from 'react-ui/components/Layout'
import Link from 'react-ui/components/Link'
import { requestReinvitation } from 'shared/services/api/registration'
import { Button } from 'care-ui'

const errorContainerStyle = ({ theme }) => ({
  display: 'flex',
  color: theme.palette.component.danger.base,
})

const errorIconStyle = ({ theme }) => ({
  fill: theme.palette.component.danger.base,
  width: '1rem',
  marginRight: '0.5rem',
})

const RequestInvitation = () => {
  const { css } = useFela()
  const { match: { location }, router: { replace } } = useRouter()

  const { invitation_token: invitationToken } = location.query

  const onClick = async () => {
    const response = await requestReinvitation(invitationToken)
    if (response.status === 204) replace('/')
  }

  const errorMessage = () => (
    <div className={css(errorContainerStyle)}>
      <Error className={css(errorIconStyle)} />
      <span>This invite is invalid.</span>
    </div>
  )

  return (
    <Welcome2Col>
      <Heading level={2}>Welcome to the Innowell Platform</Heading>
      <div>
        {errorMessage()}
        <p>Use the button below to request a new invite from your service.</p>
        <Button variant="primary" onClick={onClick}>
          Request new invite
        </Button>
        <p>
          Already have an account? <Link to="/">Sign in.</Link>
        </p>
      </div>
    </Welcome2Col>
  )
}

export default RequestInvitation
