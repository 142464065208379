// @flow

import * as React from 'react'
import { first, last, omit } from 'lodash/fp'

import createForm, {
  type FieldDefinition,
  type PropsType as CreateFormPropsType,
} from './createForm'

export type FormWizardRenderProps = {
  +CurrentScreen: React.ComponentType<CreateFormPropsType>,
  +currentStep: number,
  +onNext: () => void,
  +onPrevious: () => void,
  +onSkip: () => void,
  +optional: boolean,
  +selectedIndex: number,
  +totalSteps: number,
  +values: Object,
}

export type WizardStepType = {
  +fields: $ReadOnlyArray<FieldDefinition>,
}

type FieldType = FieldDefinition & {
  +showIf?: ({ [key: string]: any }) => boolean,
}

export type StepsType = $ReadOnlyArray<{
  +fields: $ReadOnlyArray<FieldType>,
  +showIf?: ({ [key: string]: any }) => boolean,
}>

export type FormWizardProps = {
  children?: (
    renderProps: FormWizardRenderProps,
  ) => React.Element<React.ComponentType<CreateFormPropsType>>,
  initialIndex?: number,
  +initialValues?: Object,
  onChangeScreen?: (payload: { +index: number, +values: Object }) => void,
  onSubmit: (payload: { +index: number, +values: Object }) => void,
  stepCountMode?: 'static' | 'dynamic',
  +steps: StepsType,
}

const fetchStepAttrValueByName = ({ fields }: WizardStepType, name: string) =>
  first(fields)[name]

const showIfAll = (step, values) =>
  step.fields
    .concat([step])
    .every(
      ({ showIf }) =>
        typeof showIf === 'undefined' ||
        (typeof showIf === 'function' ? showIf(values) : showIf),
    )

const FormWizard = ({
  onChangeScreen = () => {},
  initialIndex,
  initialValues,
  steps,
  onSubmit,
  children = null,
}: FormWizardProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex || 0)
  const [values, setValues] = React.useState(initialValues || {})

  const updateSelectedIndex = nextIndex => {
    onChangeScreen({ index: nextIndex, values })
    setSelectedIndex(nextIndex)
  }

  const handleBackwards = evt => {
    if (evt) {
      evt.preventDefault()
    }

    let nextIndex = selectedIndex - 1
    for (let i = nextIndex; i >= 0; i -= 1) {
      const step = steps[i]
      const showScreen = showIfAll(step, values)
      nextIndex = i
      if (showScreen) {
        break
      }
    }
    updateSelectedIndex(nextIndex)
  }

  const handleForwards = valuesGiven => {
    let currentValues = values

    if (valuesGiven && !valuesGiven.skip) {
      setValues(valuesGiven)
      currentValues = valuesGiven
    }

    let nextIndex = selectedIndex + 1
    let complete = nextIndex === steps.length

    if (!complete) {
      for (; nextIndex < steps.length; nextIndex += 1) {
        const step = steps[nextIndex]
        const showScreen = showIfAll(step, currentValues)

        if (showScreen) {
          break
        } else if (nextIndex === steps.length - 1) {
          complete = true
        }
      }
    }

    if (complete) {
      onSubmit({ index: nextIndex, values: currentValues })
    } else if (nextIndex < steps.length) {
      updateSelectedIndex(nextIndex)
    }
  }

  const handleFormSubmit = nextValues => {
    const defaultValues = {}

    steps.forEach(step => {
      step.fields.forEach(({ id, initialValue }) => {
        defaultValues[id] = initialValue
      })
    })

    handleForwards({ ...defaultValues, ...values, ...nextValues })
  }

  const selectedStep = steps[selectedIndex]
  const mergedConfig = selectedStep.fields.map(config => ({
    ...omit(['showIf'], config),
    initialValue: values[config.id] || config.initialValue,
  }))

  const nextScreen = createForm(mergedConfig, values)

  const totalSteps = fetchStepAttrValueByName(last(steps), 'stepNumber')
  const currentStep = fetchStepAttrValueByName(selectedStep, 'stepNumber')
  const optional = fetchStepAttrValueByName(selectedStep, 'optional')

  return (
    children &&
    children({
      CurrentScreen: screenProps =>
        React.createElement(nextScreen, {
          ...screenProps,
          onSubmit: handleFormSubmit,
        }),
      currentStep,
      onNext: handleForwards,
      onPrevious: handleBackwards,
      onSkip: () => handleForwards({ skip: true, ...values }),
      optional,
      selectedIndex,
      totalSteps,
      values,
    })
  )
}

export default FormWizard
