// @flow

import React from 'react'
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import { FlexContainer, ScrollableBox, Text } from 'care-ui'

import ActivityLogCard from './ActivityLogCard'

type PropsType = {
  userId: string,
}

const query = graphql`
  query ActivityLogCardContainerQuery($id: ID!, $count: Int!, $cursor: String) {
    node(id: $id) {
      ... on User {
        ...ActivityLogCardContainer_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

const paginationFragment = graphql`
  fragment ActivityLogCardContainer_user on User
    @relay(mask: false)
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "ActivityLogCardContainerPaginationQuery") {
    id
    linked_individual {
      activity_logs(first: $count, after: $cursor)
        @connection(key: "ActivityLogCardContainer_activity_logs") {
        edges {
          node {
            ...ActivityLogCard_activityLog
          }
        }
      }
    }
  }
`

const ActivityLogCardContainer = ({ userId }: PropsType) => {
  const { node } = useLazyLoadQuery(query, { id: userId, count: 50 })
  const { data, loadNext, hasNext } =
    usePaginationFragment(paginationFragment, node) || {}
  const { edges } = data?.linked_individual?.activity_logs || {}

  const activityLogs = edges?.map(({ node: activityLogNode }) => (
    <ActivityLogCard
      key={uniqueId('recent-activity-')}
      activityLog={activityLogNode}
    />
  ))

  const activityLogEmpty = activityLogs?.length === 0
  const handleScroll = e => {
    const bottom =
      Number(e?.target.scrollHeight) - Number(e?.target.scrollTop) ===
      e?.target.clientHeight
    if (bottom && hasNext) {
      loadNext(5)
    }
  }

  if (activityLogEmpty) {
    return (
      <FlexContainer justifyContent="center">
        <Text>No Recent Activity</Text>
      </FlexContainer>
    )
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      onScroll={handleScroll}
      dataTestId="recentActivityContainer"
    >
      <FlexContainer direction="column" gap="sm">
        {activityLogs}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default ActivityLogCardContainer
