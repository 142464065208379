// @flow

import 'platform_web/polyfills'
import 'shared/services/version'

import React from 'react'
import * as ReactDOMClient from 'react-dom/client'

import type { FeatureToggleContextValueType } from 'containers/FeatureToggle'
import { jsonSessionStorage } from 'services/browserStorage'
import App from 'platform_web/App'

import { rootElement } from './shared/services/elements'

// TODO: remove the global styles
import 'shared/ui/Styles/Global.scss'

const root = ReactDOMClient.createRoot(rootElement())

declare var GLOBALS: {
  appEnvironment: string,
  csrfParam: string,
  csrfToken: string,
  features: FeatureToggleContextValueType,
}

jsonSessionStorage.setItem('csrfParam', window.GLOBALS.csrfParam)
jsonSessionStorage.setItem('csrfToken', window.GLOBALS.csrfToken)

root.render(<App />)
