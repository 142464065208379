// @flow

import { type ThemeType } from 'care-ui/atoms'

type TextStyle = {
  color?: string,
  theme: ThemeType,
  typography?:
    | 'display'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'bodyLg'
    | 'bodyLgBold'
    | 'bodyLgLink'
    | 'bodyMd'
    | 'bodyMdBold'
    | 'bodyMdLink'
    | 'bodySm'
    | 'bodySmBold'
    | 'bodySmLink',
}

export const textStyle = (props: TextStyle) => {
  const { typography, theme, color = 'positive' } = props

  return {
    color: theme.care.palette.text[color],
    ...theme.care.typography.desktop[typography],
  }
}
