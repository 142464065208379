// @flow

import React, { useContext } from 'react'
import { connect } from 'react-fela'

import Link from 'react-ui/components/Link'
import NavigationContext from 'react-ui/components/Page/Navigation/NavigationContext'
import {
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from 'services/commonExternalUrls'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {}

const styleRules = () => ({
  linkWrap: {
    display: 'flex',
    maxHeight: '20px',
  },
  link: {
    fontSize: '13px',
    lineHeight: '1rem',
  },
})

const LegalLinks = ({ styles, rules }: PropsType) => {
  const { menu } = useContext(NavigationContext)
  const faqLink = menu ? '/resources/faq' : '/faq'

  return (
    <>
      <div className={styles.linkWrap}>
        <Link
          extend={() => ({ LinkClass: rules.link })}
          to={privacyPolicyUrl()}
        >
          Privacy
        </Link>
      </div>
      <div className={styles.linkWrap}>
        <Link
          extend={() => ({ LinkClass: rules.link })}
          to={termsAndConditionsUrl()}
        >
          Terms and Conditions
        </Link>
      </div>
      {window.GLOBALS.geoLocationAlpha2 !== 'CA' && (
        <div className={styles.linkWrap}>
          <Link
            extend={() => ({ LinkClass: rules.link })}
            to="/acknowledgements"
          >
            Acknowledgements
          </Link>
        </div>
      )}
      <div className={styles.linkWrap}>
        <Link extend={() => ({ LinkClass: rules.link })} to={faqLink}>
          FAQ
        </Link>
      </div>
    </>
  )
}

export default connect(styleRules)(LegalLinks)
