// @flow

import React from 'react'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'

import useGraphPagination from '../hooks/useGraphPagination'
import { type QueryVariableType } from '../hooks/useInsights'
import { query } from '../queries/ClinicianLoginsInsightsQuery'

import { type ClinicianLoginsInsightsQuery } from '../queries/__generated__/ClinicianLoginsInsightsQuery.graphql'

type IndividualLoginsInsightsGraphProps = {
  loadClinicianLoginsQuery: (queryVariables: QueryVariableType) => void,
  queryReference: PreloadedQuery<ClinicianLoginsInsightsQuery>,
  queryVariables: QueryVariableType,
}

const ClinicianLoginsInsightsGraph = (
  props: IndividualLoginsInsightsGraphProps,
) => {
  const { queryVariables, queryReference, loadClinicianLoginsQuery } = props

  const queryResult = usePreloadedQuery(query, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.clinician_logins.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.clinician_logins.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    queryVariables,
    loadQuery: loadClinicianLoginsQuery,
  })

  return (
    <GraphContainer
      heading="Clinician Sign Ins"
      graphInfoText="This graph visualises the number of clinician sign ins over the specified period, helping to track employee engagement and activity on the platform. The graph will break down the sign ins based on the number of times each clinician signed in."
    >
      {series && (
        <Graph
          graphType="stack"
          series={series}
          seriesCategories={seriesCategories}
          onPaginateLeft={() => onPaginate('left')}
          onPaginateRight={() => onPaginate('right')}
          isRightPaginationDisabled={isRightPaginationDisabled}
          xAxisTitle="Number of clinician sign ins"
        />
      )}
    </GraphContainer>
  )
}

export default ClinicianLoginsInsightsGraph
