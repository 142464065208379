// @flow

import { map } from 'lodash/fp'

import { ensureArray } from 'shared/utils/ArrayUtils'

import { fieldLinkGenerator } from './services'

import type { UserSettingsPersonalDetailsPage_profileQuestionnaire } from '../__generated__/UserSettingsPersonalDetailsPage_profileQuestionnaire.graphql'

export const PersonalDetailFields = (
  questionnaire: UserSettingsPersonalDetailsPage_profileQuestionnaire,
  requiredPolicy: string,
  routeName: string,
) => {
  if (!questionnaire) return []

  const { questions, orders } = questionnaire

  const fields = map(fieldNames => {
    const firstFieldName = ensureArray(fieldNames)[0]
    const question = questions.find(q => q.name === firstFieldName)

    return {
      id: firstFieldName,
      label: question?.label,
      value: question?.displayValue,
    }
  })(orders)
  return fieldLinkGenerator(routeName, requiredPolicy, fields)
}
