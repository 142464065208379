// @flow

import React from 'react'
import { createComponent } from 'react-fela'

import { Column, Container, Row } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { Notice } from 'shared/ui/Typography/Icons'

const verticallyCentered = _ => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '1rem 0',
})

type PropsType = {
  componentId?: string,
}

const defaultId = createComponentId(__filename)

const VerticallyCentered = createComponent(verticallyCentered, 'div')

const ReadOnlyBanner = ({ componentId = defaultId }: PropsType) => (
  <Section className={componentId} color="grey" noPadding>
    <Container>
      <Row>
        <Column col={3} />
        <Column col={9}>
          <VerticallyCentered>
            <Notice color="white" size="ml" />
            <Spacer units={0.5} axis="horizontal" />
            <span>
              <b>You are in read only mode.</b> Please contact your service to
              change these settings.
            </span>
          </VerticallyCentered>
        </Column>
      </Row>
    </Container>
  </Section>
)

export default ReadOnlyBanner
