// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { flatten, orderBy, slice } from 'lodash'

import { AccordionItem } from 'react-ui/components/Accordion'
import createComponentId from 'shared/services/id'
import { Heading } from 'care-ui'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'

import ScoredCardsChangeLog from './ScoreCardsChangeLog'
import { ScoreCardsPreviousAnswerSet } from './ScoreCardsPreviousAnswerSet'

import type { ScoreCardsPreviousAnswers_user_question_sets } from './__generated__/ScoreCardsPreviousAnswers_user_question_sets.graphql'
import type { ScoreCardsPreviousAnswers_user_trackable } from './__generated__/ScoreCardsPreviousAnswers_user_trackable.graphql'

type PropsType = {
  broadcastPointData: Function,
  componentId?: string,
  relay: Object,
  selected_answer: ?string,
  user_question_sets: ScoreCardsPreviousAnswers_user_question_sets,
  user_trackable: ScoreCardsPreviousAnswers_user_trackable,
}

const COUNT = 5

const defaultId = createComponentId(__filename)

const accordionItemStyle = (theme, isOpen, lg) => ({
  AccordionItem: {
    marginTop: '1.6rem',
    marginBottom: '0',
    backgroundColor: 'white',
    width: lg ? '100%' : 'fit-content',
  },
  header: {
    backgroundColor: theme.palette.dividerColor,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    ...theme.care.typography.desktop.bodyLg,
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.dividerColor,
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    borderRadius: '0 0 4px 4px',
  },
})

const headerStyle = () => ({
  display: 'flex',
  padding: '0 4rem 0 2rem',
  position: 'relative',
  // top: '-0.4rem',

  '& h4': {
    ':first-child': {
      width: '23rem',
    },
    ':nth-child(2)': {
      width: '13rem',
    },
    ':nth-child(3)': {
      width: '12.3rem',
    },
    ':nth-child(4)': {
      width: '14.5rem',
    },
    ':last-child': {
      width: 'max-content',
    },
  },
})

const wrapperStyle = () => ({
  overflowX: 'auto',
  width: '100%',
})

const resultCountContainerStyle = ({ theme }) => ({
  backgroundColor: 'white',
  padding: '1rem 2rem',
  display: 'flex',
  color: theme.palette.subTextColor,
  '& > div': {
    marginRight: '0.5rem',
  },
  '& button': {
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    padding: 0,
    color: theme.palette.link.linkColor,
    marginLeft: '0.5rem',
  },
})

const getResult = (user_trackable, answer_set) => {
  const defaultResponse = { value: null, date: null }

  if (!answer_set || !user_trackable?.all_data_sets[0]?.points) {
    return defaultResponse
  }

  const points = []

  user_trackable.all_data_sets.forEach(data_set => {
    points.push(data_set.points)
  })

  const pointObj = flatten(points).find(point => {
    if (!point.questionnaire_answer_set_id && !point.date) {
      return null
    }

    return (
      point.questionnaire_answer_set_id ===
      answer_set.questionnaire_answer_set_id
    )
  })

  if (!pointObj) {
    return defaultResponse
  }

  return { value: pointObj.value, date: pointObj.date }
}

const ScoreCardsPreviousAnswersComponent = ({
  broadcastPointData,
  componentId = defaultId,
  user_question_sets,
  user_trackable,
  selected_answer,
}: PropsType) => {
  const [isOpen, setOpen] = useState(false)
  const [shouldShowAll, setShouldShowAll] = useState(false)

  const onToggleOpen = () => {
    setOpen(!isOpen)
  }
  const { css } = useFela()

  const { change_log_entries } = user_trackable || {}

  let logCounter = 1
  const uqsAnswerSets = user_question_sets.map(user_question_set => {
    const answer_sets = user_question_set.submitted_answer_sets
    const { label } = user_question_set.question_set
    const sortedAnswerSets = orderBy(answer_sets, 'submitted_at', 'desc')
    return sortedAnswerSets.map(answer_set => {
      const result = getResult(user_trackable, answer_set)
      const openedFromGraph =
        result.date && selected_answer
          ? new Date(result.date).getTime() === selected_answer
          : false
      const className = `${componentId}-${
        answer_set.id
      }-${openedFromGraph.toString()}`

      const renderChangeLog = () => {
        const changeLogLength = change_log_entries?.length
        const index = changeLogLength ? changeLogLength - logCounter : -1
        const renderedLogs = []
        if (!changeLogLength || index < 0 || !change_log_entries) return null

        for (let i = index; i >= 0; i -= 1) {
          const currentLog = change_log_entries[i]
          if (currentLog.created_at >= answer_set.submitted_at) {
            logCounter += 1
            renderedLogs.push(
              <ScoredCardsChangeLog
                changeLog={currentLog}
                label={label}
                key={className}
              />,
            )
          } else break
        }
        return renderedLogs
      }
      return (
        <div className={componentId} key={className} role="row">
          {renderChangeLog()}
          <ScoreCardsPreviousAnswerSet
            broadcastPointData={broadcastPointData}
            result={result}
            openedFromGraph={openedFromGraph}
            answer_set={answer_set}
            label={label}
            user_trackable={user_trackable}
          />
        </div>
      )
    })
  })

  const previousAnswerSets = flatten(uqsAnswerSets)
  useEffect(() => {
    if (previousAnswerSets.length <= 5) setShouldShowAll(true)
  })

  const displayCount = shouldShowAll ? previousAnswerSets.length : COUNT
  const displayedPreviousAnswerSets = slice(previousAnswerSets, 0, displayCount)

  const { lg } = useBreakpoints()

  return (
    <div className={css(wrapperStyle)}>
      <AccordionItem
        withArrow
        extend={({ theme }) => accordionItemStyle(theme, isOpen, lg)}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading="Previous answers"
      >
        <div className={css(headerStyle)} role="rowgroup">
          <Heading level={4}>Question Set</Heading>
          <Heading level={4}>Rating</Heading>
          <Heading level={4}>Answered on</Heading>
          <Heading level={4}>Status</Heading>
          <Heading level={4}>Answered by</Heading>
        </div>
        {displayedPreviousAnswerSets}
        <div className={css(resultCountContainerStyle)} role="status">
          <div>{`${previousAnswerSets.length} Results`}</div>
          {!shouldShowAll && (
            <div>
              |<button type="button" onClick={() => setShouldShowAll(true)}>
                See all
              </button>
            </div>
          )}
        </div>
      </AccordionItem>
    </div>
  )
}

export const ScoreCardsPreviousAnswers = createFragmentContainer(
  ScoreCardsPreviousAnswersComponent,
  {
    user_trackable: graphql`
      fragment ScoreCardsPreviousAnswers_user_trackable on UserTrackable {
        ...ScoreCardsPreviousAnswerSet_user_trackable
        all_data_sets {
          points {
            id
            date
            value
            scaled_value
            questionnaire_answer_set_id
          }
        }
        change_log_entries(
          start_time: $trackable_start_time
          end_time: $trackable_end_time
        ) {
          ...ScoreCardsChangeLog_changeLog
          id
          created_at
        }
      }
    `,
    user_question_sets: graphql`
      fragment ScoreCardsPreviousAnswers_user_question_sets on UserQuestionSet
        @relay(plural: true) {
        id
        ...UserQuestionSetDetailsView_user_question_set
        submitted_answer_sets {
          ...ScoreCardsPreviousAnswerSet_answer_set
          questionnaire_answer_set_id
          submitted_at
          id
        }
        question_set {
          id
          label
        }
      }
    `,
  },
)
