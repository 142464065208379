// @flow

import React from 'react'

import StaffIndividualsActions from 'components/Staff/StaffIndividualsActions'
import { usePolicies } from 'react-ui/hooks/usePolicies'

import { type IndividualsTable_individual } from './__generated__/IndividualsTable_individual.graphql'

type PropsType = {
  individual: IndividualsTable_individual,
}

const IndividualsTableScoresActions = ({ individual }: PropsType) => {
  const {
    id,
    individual_detail,
    role_status,
    canBecomeActive,
    escalated,
    user,
  } = individual
  const policies = usePolicies()
  const canDoAdminActions = policies.CAN_PERFORM_ADMIN_ACTIONS_FOR_INDIVIDUALS
  const removedStatus = role_status === 'Removed'

  if (!canDoAdminActions && removedStatus) return null

  const individualId = individual_detail?.id
  const { legal_name } = user

  return (
    <StaffIndividualsActions
      staff
      individual={individual}
      roleId={id}
      roleType="INDIVIDUAL"
      individualId={individualId}
      role_status={role_status}
      canBecomeActive={canBecomeActive}
      escalated={escalated}
      legal_name={legal_name}
    />
  )
}

export default IndividualsTableScoresActions
