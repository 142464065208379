// @flow

import React from 'react'
import { connect } from 'react-fela'

import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  componentId: string,
  handleClick: () => void,
}

const styleRules = () => ({
  loadMoreWrapper: {
    position: 'relative',
    marginTop: '-10px',
  },
})

const LoadMore = ({
  styles,
  componentId = 'LoadMore',
  handleClick,
}: PropsType) => (
  <center className={styles.loadMoreWrapper}>
    <Button dataTestId={componentId} variant="secondary" onClick={handleClick}>
      Load More
    </Button>
  </center>
)

export default connect(styleRules)(LoadMore)
