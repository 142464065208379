/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type DataGridRowFooterContentLoader_user$ref = any;
type StaffClinicianActions_clinician$ref = any;
type StaffIndividualsList_clinician$ref = any;
type UserCardLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffCliniciansTable_clinicians$ref: FragmentReference;
declare export opaque type StaffCliniciansTable_clinicians$fragmentType: StaffCliniciansTable_clinicians$ref;
export type StaffCliniciansTable_clinicians = $ReadOnlyArray<{|
  +clinician_assignments_as_clinician: $ReadOnlyArray<{|
    +id: string
  |}>,
  +user: {|
    +id: string,
    +name: string,
    +$fragmentRefs: AvatarLoader_user$ref & UserCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref,
  |},
  +$fragmentRefs: StaffClinicianActions_clinician$ref & StaffIndividualsList_clinician$ref,
  +$refType: StaffCliniciansTable_clinicians$ref,
|}>;
export type StaffCliniciansTable_clinicians$data = StaffCliniciansTable_clinicians;
export type StaffCliniciansTable_clinicians$key = $ReadOnlyArray<{
  +$data?: StaffCliniciansTable_clinicians$data,
  +$fragmentRefs: StaffCliniciansTable_clinicians$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StaffCliniciansTable_clinicians",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianAssignment",
      "kind": "LinkedField",
      "name": "clinician_assignments_as_clinician",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserCardLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DataGridRowFooterContentLoader_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffClinicianActions_clinician"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffIndividualsList_clinician"
    }
  ],
  "type": "ClinicianRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '634603b96fd6745960faac13c4047548';

module.exports = node;
