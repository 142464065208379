// @flow

export default ({ theme }: any) => ({
  // ----------- Intro/Finished -----------
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '368px',
    padding: '54px 0 18px',
    color: theme.palette.component.muted.active,
  },

  sectionName: {
    marginTop: theme.spacing(1),
    fontSize: '18px',
    color: theme.palette.headingTextColor,
  },

  circleFinished: {
    boxShadow: 'inset 0 -5px 0 0 #388E3C',
  },

  cardTitleIntro: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    color: theme.palette.component.primary.base,
  },

  cardTitleFinished: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    color: theme.palette.headingTextColor,
  },

  cardContentIntro: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    color: theme.palette.component.muted.mutedActive,
    fill: theme.palette.component.muted.mutedActive,
  },

  cardContentFinished: {
    color: theme.palette.component.success.base,
  },

  clockIcon: {
    width: '20px',
    height: '20px',
    marginRight: '14px',
  },

  startBtn: {
    width: '168px',
    marginBottom: theme.spacing(1),
  },

  cancelBtn: {
    marginBottom: theme.spacing(0.5),
  },
})
