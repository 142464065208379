// @flow

import Axios from 'axios'

import Sentry from 'shared/services/sentry'
import { jsonLocalStorage } from 'platform_web/services/browserStorage'
import { onlineTimeFrame } from 'platform_web/services/user'

const heartbeatInterval = onlineTimeFrame * 500 // half the online timeframe in milliseconds

export const HEARTBEAT_KEY = 'HEARTBEAT_KEY'
let heartbeatIntervalId: ?IntervalID // current page session

const isCurrentHeartbeat = () => {
  const lastHeartbeat = jsonLocalStorage.getItem(HEARTBEAT_KEY)
  return !lastHeartbeat?.id || lastHeartbeat.id === heartbeatIntervalId
}

const isRecordingNeeded = () => {
  const now = new Date().getTime()
  const lastHeartbeat = jsonLocalStorage.getItem(HEARTBEAT_KEY)
  const noLastHeartbeat = !lastHeartbeat?.timestamp
  const heartbeatExpiredForLongEnough =
    lastHeartbeat?.timestamp &&
    lastHeartbeat.timestamp < now - heartbeatInterval * 2
  return (
    noLastHeartbeat || isCurrentHeartbeat() || heartbeatExpiredForLongEnough
  )
}

const recordHeartbeat = () => {
  if (!isRecordingNeeded()) return

  // NOTE: we record the heartbeat using localStorage so that all tabs/windows know about it.
  const now = new Date().getTime()
  jsonLocalStorage.setItem(HEARTBEAT_KEY, {
    id: heartbeatIntervalId,
    timestamp: now,
  })
}

// NOTE: retry a few times before sending the error to Sentry
const retry = (promiseFn, remainingRetries = 3) => {
  return promiseFn().catch(error => {
    if (remainingRetries <= 0) return Sentry.captureException(error)
    return retry(promiseFn, remainingRetries - 1)
  })
}

const postHeartbeat = () => {
  if (!isCurrentHeartbeat()) return

  // NOTE: we only post one heartbeat across multiple tabs/windows
  retry(() => Axios.post('/api/v1/presences/heartbeat'))
}

const heartBeating = () => {
  recordHeartbeat()
  postHeartbeat()
}

export const startHeartbeat = () => {
  if (heartbeatIntervalId) return // already started the heartbeat process, one heartbeat per tab/window please
  heartbeatIntervalId = setInterval(heartBeating, heartbeatInterval)
  recordHeartbeat()
}

export const stopHeartbeat = () => {
  clearInterval(heartbeatIntervalId)
  heartbeatIntervalId = undefined
  jsonLocalStorage.setItem(HEARTBEAT_KEY, undefined)
}
