// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'
import { formatDistance } from 'date-fns/fp'

import Tick from 'react-ui/assets/icons/tick.svg'
import { Column, Row } from 'react-ui/components/Grid'
import Icon from 'react-ui/components/Icon'
import { commit as requestSupportPersonFeedback } from 'mutations/RequestSupportPersonFeedback'
import { commit as removeSupportPerson } from 'mutations/SupportPersonAssignment/Remove'
import FlexContainer from 'care-ui/layouts/Flex/FlexContainer'
import Button from 'care-ui/molecules/Button/Button'
import Divider from 'care-ui/molecules/Divider/Divider'
import Text from 'care-ui/molecules/Text/Text'

import { type modifiedSupportPersonAssignmentType } from './SupportPersonModal'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = {
  supportPersonAssignment: modifiedSupportPersonAssignmentType,
} & FelaPropsType

const styleRules = ({ theme: { palette: { component } } }) => ({
  Container: {
    textAlign: 'left',
  },
  buttonColumn: {
    display: 'block',
    marginBottom: '20px',
  },
  textColumn: {
    marginBottom: '20px',
    wordBreak: 'break-all',
  },
  invited: {
    color: component.success.active,
    textAlign: 'left',
  },
  invitedIcon: {
    height: '18px',
    width: '18px',
  },
  removed: {
    color: component.danger.active,
  },
})

const ViewSupportPersonInfo = ({
  rules,
  styles,
  supportPersonAssignment: {
    accepted_invitation,
    id,
    last_completed,
    last_requested_at,
    nickname,
    relationship,
    support_person: { email },
    justMade,
    justRemoved,
    justActioned,
  },
}: PropsType) => {
  const environment = useRelayEnvironment()

  function reinviteSupportPersonOrGetSupport() {
    requestSupportPersonFeedback({
      environment,
      variables: {
        input: {
          support_person_feedback: {
            id,
          },
        },
      },
    })
  }

  function removeSupportPersonForAnIndividual() {
    removeSupportPerson({
      environment,
      variables: {
        input: {
          id,
        },
      },
    })
  }

  const actionedMessage = (
    <p
      data-component-id="added_support_person_notice"
      className={styles.invited}
    >
      <strong>
        {accepted_invitation ? 'SUPPORT REQUEST' : 'EMAIL'} SENT TO:
      </strong>{' '}
      {nickname.toUpperCase()} <Icon extend={rules.invitedIcon} as={Tick} />
    </p>
  )

  const invitedMessage = (
    <p
      data-component-id="added_support_person_notice"
      className={styles.invited}
    >
      <strong>INVITE SENT TO:</strong> {nickname.toUpperCase()}{' '}
      <Icon extend={rules.invitedIcon} as={Tick} />
    </p>
  )

  const removedMessage = (
    <>
      <p className={styles.removed}>
        <strong>{nickname.toUpperCase()}: HAS BEEN REMOVED</strong>
      </p>
      <p>They will not be notified of being removed.</p>
    </>
  )

  return (
    <div>
      <Divider />

      {justRemoved ? (
        removedMessage
      ) : (
        <>
          {justMade && invitedMessage}
          {justActioned && actionedMessage}

          <FlexContainer direction="column" gap="md">
            <Row>
              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  Name
                </Text>
                <Text as="p">{nickname}</Text>
              </Column>

              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  Relationship
                </Text>
                <Text as="p">{relationship}</Text>
              </Column>

              <Column xs={12} md={4}>
                <Text as="h5" bold>
                  Email
                </Text>
                <Text as="p">{email}</Text>
              </Column>
            </Row>

            <Row>
              <Column xs={12} md={4} className={styles.textColumn}>
                <Text as="h5" bold>
                  History of requests
                </Text>
                <Text as="p" size="sm">
                  Last Requested:{' '}
                  {formatDistance(new Date())(new Date(last_requested_at))} ago
                </Text>
                {last_completed && (
                  <Text as="p" size="sm">
                    Last Completed:{' '}
                    {formatDistance(new Date())(new Date(last_completed))} ago
                  </Text>
                )}
              </Column>

              <Column xs={12} md={4} extend={rules.buttonColumn}>
                <Button
                  dataTestId="removeButton"
                  variant="danger"
                  color="danger"
                  onClick={removeSupportPersonForAnIndividual}
                >
                  Remove
                </Button>
              </Column>

              <Column xs={12} md={4} extend={rules.buttonColumn}>
                <Button
                  dataTestId="inputButton"
                  variant="secondary"
                  onClick={reinviteSupportPersonOrGetSupport}
                >
                  {accepted_invitation
                    ? 'Get support input'
                    : 'Re-invite support'}
                </Button>
              </Column>
            </Row>
          </FlexContainer>
        </>
      )}
    </div>
  )
}

export default connect(styleRules)(ViewSupportPersonInfo)
