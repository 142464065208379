// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error__errors___hrcdb{align-items:center;background-color:#ffebee;border:solid 2px;border-radius:4px;border-color:#c62828;display:flex;margin-top:1rem;padding:1rem;position:relative}.Error__content___kohzn{color:#3a4656}.Error__icon___yyfmM{display:flex;padding-right:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errors": "Error__errors___hrcdb",
	"content": "Error__content___kohzn",
	"icon": "Error__icon___yyfmM"
};
export default ___CSS_LOADER_EXPORT___;
