// @flow

import React from 'react'
import { ThemeProvider, useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'

import AboutMeTabLoader from 'components/Dashboard/AboutMeTab'
import HealthCardsTabLoader from 'components/Dashboard/HealthCardsTab'
import HealthHistoryTab from 'components/Dashboard/HealthHistoryTab'
import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { ChartHeader } from 'components/SummaryGraph'
import Section from 'react-ui/components/Section'
import DashboardTabs from 'react-ui/components/Tabs/DashboardTabs'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import useChartToggle from 'react-ui/hooks/useChartToggle'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import useRouteParams from 'react-ui/hooks/useRouteParams'
import individualTheme from 'react-ui/themes/synergy-individual-theme'
import createComponentId from 'shared/services/id'
import { Container } from 'care-ui'

import { type FelaPropsType } from 'react-ui/typing'
import { type UserDashboardPage_user } from './__generated__/UserDashboardPage_user.graphql'
import { type UserDashboardPage_viewer } from './__generated__/UserDashboardPage_viewer.graphql'

type PropsType = FelaPropsType & {
  selectedTab: string,
  +user: UserDashboardPage_user,
  +viewer: UserDashboardPage_viewer,
}

type QueryPropsType = {
  selectedTab: string,
}

const sectionStyle = ({ theme }) => ({
  Section: {
    backgroundColor: theme.palette.summaryGraphs.highlightColor,
  },
})

const componentId = createComponentId(__filename)

// NOTE: ensure that the ids in possibleTabs matches the keys in tabComponent

export const possibleTabs = () => {
  const defaultTabOptions = [
    { label: 'Dashboard', routeName: 'user_dashboard' },
    { label: 'Dashboard', routeName: 'dashboard' },
    { label: 'Health History', routeName: 'user_health_history' },
    { label: 'Profile', routeName: 'user_profile' },
  ]

  return defaultTabOptions
}

const UserDashboardPageBase = ({
  user,
  viewer: { currentUser },
  selectedTab,
}: PropsType) => {
  const isTrackableTab = selectedTab === 'trackables'
  const { linked_individual } = user || {}
  const { theme } = useFela()
  if (!linked_individual) {
    throw new Error('No linked_individual found for user')
  }

  const tabComponent = {
    healthHistory: HealthHistoryTab,
    aboutMe: AboutMeTabLoader,
    trackables: HealthCardsTabLoader,
  }
  const { isSummaryGauge, isSummaryGraph } = useChartToggle()

  const blueHighlight = isSummaryGraph && isTrackableTab
  const Component = tabComponent[selectedTab]

  return (
    <div className={componentId} data-testid={selectedTab}>
      <Section
        extend={blueHighlight && sectionStyle({ theme })}
        color="white"
        noPadding
      >
        <Container>
          <ChartHeader individual={linked_individual} user={currentUser} />
          {isSummaryGauge && <DashboardTabs />}
        </Container>
        <Container>{!isTrackableTab && <Component user={user} />}</Container>
      </Section>
      {isTrackableTab && <Component user={user} />}
    </div>
  )
}

const UserDashboardPageQuery = graphql`
  query UserDashboardPage_Query(
    $id: ID!
    $aboutMe: Boolean = false
    $healthHistory: Boolean = false
    $trackables: Boolean = false
  ) {
    user(id: $id) {
      ...UserDashboardPage_user
    }
    viewer {
      ...UserDashboardPage_viewer
    }
  }
`

export const UserDashboardPageLoader = createFragmentContainer(
  UserDashboardPageBase,
  {
    viewer: graphql`
      fragment UserDashboardPage_viewer on Viewer {
        currentUser {
          id
          ...ChartHeader_user
        }
      }
    `,
    user: graphql`
      fragment UserDashboardPage_user on User {
        id
        linked_individual {
          ...ChartHeader_individual
        }
        ...AboutMeTab_user @include(if: $aboutMe)
        ...HealthHistoryTab_user @include(if: $healthHistory)
        ...HealthCardsTab_user @include(if: $trackables)
      }
    `,
  },
)

export const UserDashboardPageQueryLoader = ({
  selectedTab,
}: QueryPropsType) => {
  const environment = useRelayEnvironment()
  const { id } = useRouteParams()
  const { homepage_path } = useCurrentUser()
  const { router } = useRouter()

  const {
    CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT: canView,
  } = usePolicies()

  if (canView !== undefined && !canView) {
    router.replace(homepage_path)
    return null
  }

  return (
    id && (
      <QueryRenderer
        environment={environment}
        query={UserDashboardPageQuery}
        variables={{
          id,
          trackables: selectedTab === 'trackables',
          healthHistory: selectedTab === 'healthHistory',
          aboutMe: selectedTab === 'aboutMe',
        }}
        render={response => {
          return (
            <QueryRendererLoadingIndicator response={response}>
              {props => (
                <ThemeProvider theme={individualTheme}>
                  <UserDashboardPageLoader
                    {...(props: any)}
                    selectedTab={selectedTab}
                  />
                </ThemeProvider>
              )}
            </QueryRendererLoadingIndicator>
          )
        }}
      />
    )
  )
}
