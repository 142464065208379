// @flow

import React from 'react'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import useRouter from 'found/useRouter'

import { commit as commitIndividualPersonalDetailCreate } from 'mutations/IndividualPersonalDetailCreate'
import { normalizeClinicianValues } from 'shared/services/normalization'
import Sentry from 'shared/services/sentry'

import OnboardingPages from './OnboardingPages'

const ClinicianOnboardingPages = ({ user }) => {
  const { router } = useRouter()
  const environment = useRelayEnvironment()

  const { preferred_name, onboardingQuestionnaire, homepage_path } = user

  const handleSubmit = values => {
    commitIndividualPersonalDetailCreate({
      environment,
      onCompleted: () => {
        router.push(homepage_path)
      },
      onError: error => {
        Sentry.captureMessage(
          'Onboarding could not be completed due to error sending mutation',
          {
            extra: {
              individual_personal_detail: normalizeClinicianValues(values),
            },
          },
        )
        Sentry.captureException(error)
      },
      variables: {
        input: {
          individual_personal_detail: normalizeClinicianValues(values),
        },
      },
    })
  }

  return (
    <OnboardingPages
      hasHeader={false}
      pageTitle="Tell us a bit about yourself"
      userName={preferred_name}
      questionnaire={onboardingQuestionnaire}
      uiStyle="secondary"
      handleSubmit={handleSubmit}
    />
  )
}

export default createFragmentContainer(ClinicianOnboardingPages, {
  user: graphql`
    fragment ClinicianOnboarding_user on User {
      preferred_name
      homepage_path
      id
      onboardingQuestionnaire {
        questions {
          name
          fieldType
          title
          required
          options {
            label
            value
          }
          otherEnabled
          dependOn
        }
        orders
      }
    }
  `,
})
