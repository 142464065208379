// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import Card from 'react-ui/components/Card/Card'
import Modal from 'react-ui/components/Modal'
import { commit as commitDisconnectOfIndividualFitbit } from 'mutations/Fitbit/DisconnectFitbit'
import {
  trackFitbitConnectModalViewed,
  trackFitbitDisconnected,
  trackFitbitDisconnectModalViewed,
} from 'services/analytics/events'
import { Button } from 'care-ui'

import FitbitConnectActivity from './FitbitConnectActivity'
import FitbitDisconnectActivity from './FitbitDisconnectActivity'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink?: string,
  environment: Object,
  isConnected: boolean,
  isOpen?: boolean,
  viewOnlyMode?: boolean,
}

const widgetStyle = ({ viewOnlyMode, theme }) => ({
  cardDefaults: {
    margin: '0',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #DDD',
    [theme.breakpoints.queries.xsOnly]: {
      flexWrap: 'wrap',
    },
  },
  logoContainer: {
    flex: '25%',
    lineHeight: '0',
    [theme.breakpoints.queries.xsOnly]: {
      marginBottom: '1rem',
      flex: '30%',
      textAlign: 'center',
    },
  },
  logo: {
    height: 'auto',
    width: '11rem',
    [theme.breakpoints.queries.xsOnly]: {
      width: '100%',
      maxWidth: '7rem',
    },
  },
  connectButton: {
    flex: '25%',
    [theme.breakpoints.queries.xsOnly]: {
      marginBottom: '1rem',
      flex: '50%',
      textAlign: 'center',
    },
  },
  clickable: {
    margin: 'auto',
    color: viewOnlyMode && 'black',
    pointerEvents: viewOnlyMode && 'none',
  },
  text: {
    flex: '50%',
    ...theme.care.typography.desktop.bodyMd,
    [theme.breakpoints.queries.xsOnly]: {
      textAlign: 'center',
    },
  },
  modal: {
    maxWidth: '800px',
  },
})

const FitbitConnectionCard = ({
  styles,
  rules,
  isConnected,
  authLink,
  environment,
  viewOnlyMode,
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleOpen = () => setIsOpen(prev => !prev)

  const handleFitbitModalOpen = () => {
    onToggleOpen()
    // eslint-disable-next-line no-unused-expressions
    isConnected
      ? trackFitbitDisconnectModalViewed()
      : trackFitbitConnectModalViewed()
  }

  const connectButton = () => (
    <Button
      variant="text"
      onClick={handleFitbitModalOpen}
      disabled={viewOnlyMode}
      ariaLabel={isConnected ? 'Disconnect your Fitbit' : 'Connect your Fitbit'}
      dataTestId="fitbit"
    >
      {isConnected ? 'Disconnect' : 'Connect your Fitbit'}
    </Button>
  )

  const handleDisconnectClick = () => {
    commitDisconnectOfIndividualFitbit({
      environment,
      variables: {
        input: {},
      },
      onCompleted: () => {
        onToggleOpen()
      },
    })

    trackFitbitDisconnected()
  }

  return (
    <Card extend={rules.cardDefaults}>
      <div className={styles.logoContainer}>
        <FitbitLogo className={styles.logo} />
      </div>
      <div className={styles.connectButton}>{connectButton()}</div>
      <div className={styles.text}>
        We know there is an important link between fitness, exercise, sleep and
        mental health. This is the reason why Innowell has linked with Fitbit.
      </div>

      <Modal
        contentStyle={rules.modal()}
        isOpen={isOpen}
        onRequestClose={onToggleOpen}
        showClose={false}
        shrinkwrap
      >
        {!isConnected ? (
          <FitbitConnectActivity
            authLink={authLink}
            closeModal={onToggleOpen}
          />
        ) : (
          <FitbitDisconnectActivity
            closeModal={onToggleOpen}
            disconnect={handleDisconnectClick}
          />
        )}
      </Modal>
    </Card>
  )
}

export default connect(widgetStyle)(FitbitConnectionCard)
