// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { map } from 'lodash/fp'

import { AnswerSetLoader } from 'components/AnswerSet'
import Accordion, { AccordionItem } from 'react-ui/components/Accordion'

import type { HealthHistory_user } from './__generated__/HealthHistory_user.graphql'

type UserQuestionnairesQuestionSetType = {
  id: string,
  latest_submitted_non_skipped_answer_set: any,
  question_set: {
    label: string,
  },
}

type PropsType = {
  user: HealthHistory_user,
}

const RenderedAnswerSet = ({ answer_set }) => {
  if (answer_set) {
    return <AnswerSetLoader answer_set={answer_set} />
  }
  return 'Unanswered'
}

const RenderedUserQuestionnairesQuestionSet = ({
  user_questionnaires_question_set,
}: {
  user_questionnaires_question_set: UserQuestionnairesQuestionSetType,
}) => (
  <Accordion>
    <AccordionItem
      heading={user_questionnaires_question_set.question_set.label}
    >
      <RenderedAnswerSet
        answer_set={
          user_questionnaires_question_set.latest_submitted_non_skipped_answer_set
        }
      />
    </AccordionItem>
  </Accordion>
)

const HealthHistory = ({
  user: { health_history_user_questionnaires_question_sets },
}: PropsType) => (
  <div data-component-id="HealthHistory">
    {map(
      uqqs => (
        <RenderedUserQuestionnairesQuestionSet
          key={uqqs.id}
          user_questionnaires_question_set={uqqs}
        />
      ),
      health_history_user_questionnaires_question_sets,
    )}
  </div>
)

export const HealthHistoryLoader = createFragmentContainer(HealthHistory, {
  user: graphql`
    fragment HealthHistory_user on User {
      health_history_user_questionnaires_question_sets {
        id
        latest_submitted_non_skipped_answer_set {
          ...AnswerSet_answer_set
        }
        question_set {
          label
        }
      }
    }
  `,
})
