// @flow

const degreesToRadians = degrees => degrees * Math.PI / 180
export type adjustCoordTypes = {
  OFFSET: number,
  POSITION_OFFSET: { x: number, y: number },
  RADIUS: number,
  WIDTH_BASE: number,
  angle: number,
}
type coordinatesType = {
  angle: ?number,
  containerWidth: ?number,
  x: number,
  y: number,
}

/*
  calculate the coordinates X,Y 
  having an angle and the radius of the circ
*/
const calcCoordinates = (angle: number, RADIUS: number) => ({
  x: Math.cos(degreesToRadians(angle)) * RADIUS,
  y: Math.sin(degreesToRadians(angle)) * RADIUS,
})

/*
  adjust label coordinates 
  and its absolute position 
*/
export const adjustCoordinates = ({
  angle,
  WIDTH_BASE,
  POSITION_OFFSET,
  RADIUS,
  OFFSET,
}: adjustCoordTypes): coordinatesType => {
  const coordinates = calcCoordinates(angle, RADIUS)

  // adjust containerWidth based on label position
  let containerWidth = WIDTH_BASE + (RADIUS - Math.abs(coordinates.x) - 2)

  // first X,Y adjust based on the offsed
  let x = POSITION_OFFSET.x * Math.sign(coordinates.x) + coordinates.x
  let y = POSITION_OFFSET.y - coordinates.y

  // Covering all the possible angle and ajusting coords.
  if (angle < 90 && angle > 0) {
    x += OFFSET - 3
    y -= 5
  }

  if (angle === 90) {
    x = -containerWidth / 2 - 2
    y -= 20
    containerWidth = 120
  }

  if (angle > 90 && angle < 180) {
    x -= containerWidth + OFFSET + 2
    y -= 5
  }

  if (angle === 0) {
    x += OFFSET
    containerWidth += 20
  }

  if (angle === 180) {
    x -= containerWidth + OFFSET + 10
  }
  return { x, y, angle, containerWidth }
}

// split circle into segments based on the number of labels
export const getLabelAngle = (
  labelsSize: number,
  labelIndex: number,
  START_ANGLE: number,
  TOTAL_ARC_ANGLE: number,
): number => {
  const segmentAngle = TOTAL_ARC_ANGLE / (labelsSize - 1)
  return START_ANGLE + segmentAngle * labelIndex
}
