// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { Formik, getIn } from 'formik'
import {
  find,
  findIndex,
  findLast,
  first,
  flattenDeep,
  reverse,
  slice,
} from 'lodash/fp'
import * as yup from 'yup'

import { H4 } from 'react-ui/components/Heading'
import { type UiStyleProps } from 'shared/ui/Forms/Fields/RadioSet/RadioSet'
import { ensureArray } from 'shared/utils/ArrayUtils'
import { isBlank } from 'shared/utils/ObjectUtils'
import {
  collectPropFrom,
  convertQuestions,
  getQuestionValidatorProp,
} from 'shared/utils/QuestionnaireUtils'
import type {
  OrdersType,
  QuestionnaireType,
} from 'shared/utils/SimpleQuestionnaireTypes'
import { Button, FlexContainer } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType &
  UiStyleProps & {
    handleWizardComplete: (values: any) => void,
    questionnaire: QuestionnaireType,
  }

const getQuestionNumber = (orders: OrdersType, current: string): string => {
  let levelOneIndex = 0
  let levelTwoIndex = -1
  levelOneIndex =
    findIndex(order => {
      levelTwoIndex = findIndex(item => item === current)(ensureArray(order))
      return levelTwoIndex > -1
    })(orders) || 0
  return `${levelOneIndex + 1}${
    levelTwoIndex > 0 ? String.fromCharCode(levelTwoIndex + 96) : ''
  }`
}

const getQuestionTotal = (orders: OrdersType): string =>
  orders.length.toString(10)

const OnboardingQuestionsForm = ({
  questionnaire: { questions, orders },
  handleWizardComplete,
  rules,
  styles,
}: PropsType) => {
  const flattenOrders = flattenDeep(orders)
  const formikQuestions = collectPropFrom(convertQuestions(questions))
  // NOTE: this method uses `flattenOrders`, therefore,
  // it needs to go after `flattenOrders` but before `current`
  const moveQuestion = ({
    current = undefined,
    isNext = true,
    values = {},
  }): string => {
    const currentIndex = flattenOrders.indexOf(current)
    const blockToMove = isNext
      ? slice(currentIndex + 1, flattenOrders.length)(flattenOrders)
      : reverse(slice(0, currentIndex)(flattenOrders))
    return (
      find(key => formikQuestions[key].showIf(values))(blockToMove) ||
      current ||
      first(flattenOrders)
    )
  }
  const [current, setCurrent] = useState(moveQuestion({}))
  const currentQuestion = formikQuestions[current]
  const questionNumber = getQuestionNumber(orders, current)
  const questionTotal = getQuestionTotal(orders)

  const initialValues = {
    ...collectPropFrom(formikQuestions, 'value', true),
    // NOTE: this is to ensure that we have values for those input controls that have `other` option
    other: collectPropFrom(
      formikQuestions,
      ({ otherEnabled }) => otherEnabled && '',
      true,
    ),
  }

  const validationSchema = yup.object().shape({
    ...collectPropFrom(formikQuestions, getQuestionValidatorProp),
  })
  const {
    InputComponent,
    id,
    options,
    title,
    otherEnabled,
    canSkip,
  } = currentQuestion

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleWizardComplete}
      validationSchema={validationSchema}
    >
      {({ values, validateForm, errors, isSubmitting, handleSubmit }) => {
        const hasPrev =
          find(questionKey => formikQuestions[questionKey].showIf(values))(
            flattenOrders,
          ) !== current
        const hasNext =
          findLast(questionKey => formikQuestions[questionKey].showIf(values))(
            flattenOrders,
          ) !== current
        const moveToPrev = () => {
          const prevOne = moveQuestion({ current, values, isNext: false })
          return validateForm(values).then(() => setCurrent(prevOne))
        }
        const moveToNext = () => {
          const nextOne = moveQuestion({ current, values })
          return validateForm(values).then(() => setCurrent(nextOne))
        }
        const otherSelectedError = () => {
          if (Array.isArray(values[id]) && values[id].indexOf('other') < 0)
            return false

          return getIn(errors, `other.${id}`)
        }
        return (
          <div className={styles.container}>
            <H4 extend={rules.questionTitle}>
              Question {questionNumber} of {questionTotal}
            </H4>
            <div className={styles.answersContainer}>
              <InputComponent
                id={id}
                key={id}
                options={options}
                rules={rules}
                title={title}
                data={{ otherEnabled }}
              />
            </div>

            <FlexContainer gap="xs">
              {hasPrev && (
                <Button
                  variant="secondary"
                  onClick={moveToPrev}
                  disabled={isSubmitting}
                >
                  Back
                </Button>
              )}

              <Button
                onClick={(hasNext && moveToNext) || handleSubmit}
                type="submit"
                variant="primary"
                disabled={
                  isBlank(values[id]) ||
                  errors[current] ||
                  otherSelectedError() ||
                  isSubmitting
                }
              >
                {hasNext ? 'Continue' : 'Save'}
              </Button>

              {hasNext &&
                canSkip && (
                  <Button
                    type="button"
                    onClick={moveToNext}
                    variant="text"
                    disabled={isSubmitting}
                  >
                    Skip
                  </Button>
                )}
            </FlexContainer>
          </div>
        )
      }}
    </Formik>
  )
}

const styleRules = () => ({
  container: {
    className: 'OnboardingQuestionsForm',
    marginBottom: '32px',
    width: '80%',
  },

  questionTitle: {
    className: 'OnboardingQuestionsForm__Title',
    marginBottom: '8px',
  },

  answersContainer: {
    className: 'OnboardingQuestionsForm__answersContainer',
    marginBottom: '32px',
  },

  buttonLabel: {
    marginRight: '0.6rem',
    marginBottom: '16px',
    fontSize: '14px',
  },
  buttonRight: {
    float: 'right',
    fontSize: '14px',
    marginBottom: '16px',
  },
})

export default connect(styleRules)(OnboardingQuestionsForm)
