// @flow

import { useState } from 'react'
import { useSetState } from 'react-use'

import { DEFAULT_TABLE_CONFIGURATIONS } from '../constants/EmployeesTableConstant'
import { COUNT } from '../constants/TableConstants'

export type UpdateQueryVariablesType = ({ [string]: string | boolean }) => void

export type QueryVariablesType = {
  [string]: string | boolean,
}

export type UseEmployeesTableReturnType = {
  count: number,
  queryVariables: QueryVariablesType,
  setTableType: (tableType: string) => void,
  tableType: string,
  updateQueryVariables: UpdateQueryVariablesType,
}

type UseEmployeesTableProps = {
  queryType: string,
}

const useEmployeesTable = (
  props: UseEmployeesTableProps,
): UseEmployeesTableReturnType => {
  const { queryType } = props

  const [tableType, setTableType] = useState('Active')

  const defaultQueryVariables = {
    ...DEFAULT_TABLE_CONFIGURATIONS[tableType],
    query_type: queryType,
    count: COUNT,
  }

  // Local state for Individual Table to handle filter, sort, and search query variables
  const [state, setState] = useSetState(defaultQueryVariables)

  const updateQueryVariables: UpdateQueryVariablesType = (
    updatedQueryVariables: QueryVariablesType,
  ) => {
    setState(updatedQueryVariables)
  }

  return {
    count: COUNT,
    updateQueryVariables,
    queryVariables: state,
    tableType,
    setTableType,
  }
}

export default useEmployeesTable
