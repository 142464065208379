// @flow

import React from 'react'
import { graphql } from 'react-relay'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import TableDataLoader from 'platform_web/features/TableDataLoader/TableDataLoader'

import useEmployeesTable from '../hooks/useEmployeesTable'

import EmployeesTableControls from './EmployeesTableControls'
import StaffEmployeesTable from './StaffEmployeesTable'

type PropsType = {
  queryType: string,
}

const staffEmployeesTableLoaderPaginatedQuery = graphql`
  query StaffEmployeesTableLoader_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $status: [String!]
    $sort: [Sortable!]
    $query_type: String!
  ) {
    viewer {
      currentUser {
        staff_admin {
          ...StaffEmployeesTableLoader_staff_admin
        }
      }
    }
  }
`

const employeesTableFragments = {
  staff_admin: graphql`
    fragment StaffEmployeesTableLoader_staff_admin on StaffAdmin {
      staff(
        query_type: $query_type
        search: $search
        status: $status
        sort: $sort
        first: $count
        after: $cursor
      ) @connection(key: "StaffEmployeesTableLoader_staff") {
        edges {
          node {
            ...StaffEmployeesTableLoader_staff @relay(mask: false)
          }
        }
      }
    }
  `,
}

graphql`
  fragment StaffEmployeesTableLoader_staff on Staff {
    user {
      ...AvatarLoader_user
      ...UserCardLoader_user
      ...DataGridRowFooterContentLoader_user
      ...StaffActions_user
      email
      invitation_created_at
      legal_name
      invitation_delta
    }
    roles {
      ...StaffEmployeesTableLoader_role @relay(mask: false)
    }
  }
`

graphql`
  fragment StaffEmployeesTableLoader_role on RoleInterface {
    id
    role_type
    role_status
    description
    tenant {
      name
      requires_mfa
    }
  }
`

graphql`
  fragment StaffEmployeesTableLoader_staff_mutation on Staff {
    user {
      email
    }
  }
`

const StaffEmployeesTableLoader = (props: PropsType) => {
  const { queryType } = props

  const useEmployeesTablePayload = useEmployeesTable({ queryType })

  const { queryVariables, count } = useEmployeesTablePayload

  return (
    <>
      {/* Table Controls */}
      <EmployeesTableControls
        useEmployeesTablePayload={useEmployeesTablePayload}
      />

      {/* Table Data */}
      <TableDataLoader
        query={staffEmployeesTableLoaderPaginatedQuery}
        fragments={employeesTableFragments}
        queryVariables={queryVariables}
        getFragments={data => ({
          tenant: data.viewer.currentUser.tenant,
          staff_admin: data.viewer.currentUser.staff_admin,
        })}
      >
        {({ relay, staff_admin, tenant }) => {
          return (
            <>
              <StaffEmployeesTable
                staff_admin={staff_admin}
                tenant={tenant}
                queryVariables={queryVariables}
              />

              {relay.hasMore() && (
                <LoadMore handleClick={() => relay.loadMore(count)} />
              )}
            </>
          )
        }}
      </TableDataLoader>
    </>
  )
}

export default StaffEmployeesTableLoader
