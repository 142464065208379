// @flow

import React from 'react'
import { uniqueId } from 'lodash/fp'

import { Divider, FlexContainer, ScrollableBox } from 'care-ui'

import TrackablesCheckBox from './TrackablesCheckBox'

type PropsType = {
  filtered: Array<{ [string]: string }>,
  questionnaireFieldsPayload: {
    disableFullQuestionnaire: () => boolean,
    disableSummaryQuestionnaire: () => boolean,
    disableTrackableQuestionnaire: (questionnaireId: string) => boolean,
    initialQuestionnaire: { [string]: string },
    summaryQuestionnaire: { [string]: string },
  },
  selectedQuestionnaires: Array<string>,
  setSelectedQuestionnaires: (Array<string>) => void,
}

const AssessmentTrackablesList = (props: PropsType) => {
  const {
    questionnaireFieldsPayload,
    selectedQuestionnaires,
    setSelectedQuestionnaires,
    filtered,
  } = props
  const {
    initialQuestionnaire,
    summaryQuestionnaire,
    disableFullQuestionnaire,
    disableSummaryQuestionnaire,
    disableTrackableQuestionnaire,
  } = questionnaireFieldsPayload

  return (
    <ScrollableBox maxHeight="36vh">
      <FlexContainer direction="column" gap="xs" paddingX="xxs">
        {initialQuestionnaire && (
          <TrackablesCheckBox
            key="fullQuestionnaire"
            label="Full Questionnaire"
            questionnaireId={initialQuestionnaire.id}
            disabled={disableFullQuestionnaire()}
            selectedQuestionnaires={selectedQuestionnaires}
            setSelectedQuestionnaires={setSelectedQuestionnaires}
          />
        )}

        {summaryQuestionnaire && (
          <TrackablesCheckBox
            key="summaryQuestionnaire"
            label="Summary Questionnaire"
            questionnaireId={summaryQuestionnaire.id}
            disabled={disableSummaryQuestionnaire()}
            selectedQuestionnaires={selectedQuestionnaires}
            setSelectedQuestionnaires={setSelectedQuestionnaires}
          />
        )}
      </FlexContainer>

      <Divider />

      <FlexContainer direction="column" gap="xs" paddingX="xs">
        {filtered.map(({ id, label }) => {
          return (
            <TrackablesCheckBox
              key={uniqueId(label)}
              label={label}
              questionnaireId={id}
              disabled={disableTrackableQuestionnaire(id)}
              selectedQuestionnaires={selectedQuestionnaires}
              setSelectedQuestionnaires={setSelectedQuestionnaires}
            />
          )
        })}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default AssessmentTrackablesList
