// @flow

import React, { type Node } from 'react'
import Link from 'found/Link'

import { useWindowSize } from 'react-ui/hooks/useWindowSize'

type LinkableContentProps = {
  analytics?: () => {},
  children: Node,
  linkTo: string | Object,
}

const Linkable = ({
  children,
  linkTo,
  analytics = () => {},
}: LinkableContentProps) => {
  const size = useWindowSize()

  return size.md ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Link to={linkTo} onClick={analytics} role="link">
      {children}
    </Link>
  )
}

export default Linkable
