// @flow

import React from 'react'
import { connect } from 'react-fela'

import { type SupportPersonTask_individual } from 'components/IndividualTasksCard/__generated__/SupportPersonTask_individual.graphql'
import Modal from 'react-ui/components/Modal'
import { ViewerEnumsContext } from 'containers/ViewerEnums'
import { Button, Heading } from 'care-ui'

import AddSupportPersonForm from './AddSupportPersonForm'
import ViewSupportPersonInfo from './ViewSupportPersonInfo'

import { type FelaPropsType } from 'react-ui/typing'
import { type ReadOnlyArrayElement } from 'services/flow'

const styleRules = ({ theme: { palette: { component }, care } }) => ({
  hr: {
    borderColor: component.muted.mutedBase,
    margin: '18px 18px 36px 18px',
  },
  Modal: {
    maxWidth: '1010px',
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
  },
  heading: {
    marginBottom: '2rem',
  },
  description: {
    ...care.typography.desktop.bodyLg,
  },
})

type RefNodePropsType = {
  current: null | HTMLDivElement,
}

type supportPersonAssignmentsType = $PropertyType<
  SupportPersonTask_individual,
  'support_person_assignments',
>
export type supportPersonAssignmentType = ReadOnlyArrayElement<
  supportPersonAssignmentsType,
>
export type modifiedSupportPersonAssignmentType = supportPersonAssignmentType & {
  justActioned?: boolean,
  justMade?: boolean,
  justRemoved?: boolean,
}

type PropsType = FelaPropsType & {
  hasSupportPerson?: boolean,
  isOpen: boolean,
  onToggleOpen: () => void,
  supportPersonAssignments: supportPersonAssignmentsType,
}

type StateType = {
  inviting: boolean,
  supportPersonAssignments: $ReadOnlyArray<modifiedSupportPersonAssignmentType>,
}

class SupportPersonModalClass extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)
    this.state = {
      inviting: !props.hasSupportPerson,
      // $DisableFlow the state has additional optional params that are handled in usages
      supportPersonAssignments: props.supportPersonAssignments,
    }
  }

  static getDerivedStateFromProps(props: PropsType, state: StateType) {
    let addToState = null

    if (!state.inviting && !props.hasSupportPerson) {
      addToState = {
        inviting: true,
        ...addToState,
      }
    }

    if (state.supportPersonAssignments !== props.supportPersonAssignments) {
      const oldStateAssignments = state.supportPersonAssignments
      const newPropAssignments = props.supportPersonAssignments

      const updatedAssignments = newPropAssignments
        .filter(el =>
          oldStateAssignments.some(
            e => el.id === e.id && el.last_requested_at !== e.last_requested_at,
          ),
        )
        .map(el => el.id)

      const newAssignments = newPropAssignments.filter(
        el => !oldStateAssignments.some(e => el.id === e.id),
      )
      const deletedAssignmentIds = oldStateAssignments
        .filter(el => !newPropAssignments.some(e => el.id === e.id))
        .map(el => el.id)

      newAssignments.forEach((assignment, index) => {
        // $DisableFlow the state has additional optional params that are handled in usages
        newAssignments[index] = { ...assignment, justMade: true }
      })

      const transformedAssignments = oldStateAssignments.map(el => {
        if (deletedAssignmentIds.includes(el.id)) {
          return { ...el, justRemoved: true }
        }
        if (updatedAssignments.includes(el.id)) {
          const updated = newPropAssignments.filter(e => e.id === el.id)[0]

          return {
            ...el,
            justActioned: true,
            last_requested_at: updated?.last_requested_at,
          }
        }
        return el
      })

      const concatedAssignments: Array<any> = transformedAssignments.concat(
        newAssignments,
      )

      addToState = {
        supportPersonAssignments: concatedAssignments,
        ...addToState,
      }
    }

    return addToState
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { current } = this.scrollNode
    if (this.state.inviting && !prevState.inviting && current) {
      current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  updateInviting(inviting: boolean) {
    this.setState({ inviting })
  }

  handleCloseRequest = () => {
    this.props.onToggleOpen()
    this.updateInviting(!this.props.hasSupportPerson)
    this.setState({
      // $DisableFlow the state has additional optional params that are handled in usages
      supportPersonAssignments: this.props.supportPersonAssignments,
    })
  }

  scrollNode: RefNodePropsType = React.createRef()

  render() {
    const {
      props: { isOpen, rules, styles },
      state: { inviting, supportPersonAssignments },
    } = this

    return (
      <Modal
        shrinkwrap
        id="add_support_person_modal"
        showClose={false}
        isOpen={isOpen}
        onRequestClose={this.handleCloseRequest}
        extend={() => ({ Modal: rules.Modal })}
      >
        <Heading level={2} extend={rules.heading}>
          Your Support Person
        </Heading>
        <p className={styles.description}>
          Different perspectives can help add more information to your health
          profile. They will receive an email invite to answer a questionnaire
          about your health and well being.
        </p>
        <p>
          <strong>
            They will not be able to view any of your health information.
          </strong>
          <br />
          <strong>
            If you remove a support person they won’t be notified.
          </strong>
        </p>
        {supportPersonAssignments.map(assignment => (
          <ViewSupportPersonInfo
            key={assignment.id}
            supportPersonAssignment={assignment}
            extend={{ hr: rules.hr }}
          />
        ))}
        <hr className={styles.hr} />
        <Heading level={3} extend={rules.heading}>
          Invite {supportPersonAssignments.length > 0 ? 'Another' : 'a'} Support
          Person
        </Heading>
        <div ref={this.scrollNode}>
          {inviting ? (
            // Usually the useEnums hook would be used where needed, however, until formik supports hooks ( formik version 2.0 ) a work around is needed.
            <ViewerEnumsContext.Consumer>
              {({ relationships }) => (
                <AddSupportPersonForm
                  afterSubmit={() => {
                    this.updateInviting(false)
                  }}
                  relationships={relationships}
                />
              )}
            </ViewerEnumsContext.Consumer>
          ) : (
            <Button
              dataTestId="showFormButton"
              onClick={() => this.updateInviting(true)}
              variant="text"
            >
              + Add another person
            </Button>
          )}
        </div>
      </Modal>
    )
  }
}

export default connect(styleRules)(SupportPersonModalClass)
