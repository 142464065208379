// @flow

import React, { Fragment } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { withRouter } from 'found'

import { AnswerSetsLoader } from 'components/AnswerSets'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import { Button } from 'care-ui'

import type { UserQuestionSetDetailsPage_user_question_set } from './__generated__/UserQuestionSetDetailsPage_user_question_set.graphql'
import type { UserQuestionSetDetailsPage_user } from './__generated__/UserQuestionSetDetailsPage_user.graphql'

type PropsType = {
  +relay: Object,
  router?: Object,
  user: UserQuestionSetDetailsPage_user,
  +user_question_set: UserQuestionSetDetailsPage_user_question_set,
}

const UserQuestionSetDetailsPage = withRouter((props: PropsType) => {
  const {
    user_question_set: { submitted_answer_sets, question_set },
    user: { id },
  } = props

  return (
    <Fragment>
      <Section color="white">
        <Container>
          <Button
            variant="text"
            to={{
              name: 'user_dashboard',
              params: {
                id,
              },
            }}
          >
            Back to dashboard
          </Button>
          <Heading level={3}>{question_set.label}</Heading>
        </Container>
      </Section>
      <Section color="default">
        <Container>
          <AnswerSetsLoader answer_sets={submitted_answer_sets} />
        </Container>
      </Section>
    </Fragment>
  )
})

export const UserQuestionSetDetailsPageQuery = graphql`
  query UserQuestionSetDetailsPage_Query($id: ID!, $question_set_id: ID!) {
    user(id: $id, source: "questionnaire") {
      ...UserQuestionSetDetailsPage_user
      user_question_set(question_set_id: $question_set_id) {
        ...UserQuestionSetDetailsPage_user_question_set
      }
    }
  }
`

export const UserQuestionSetDetailsPageLoader = createFragmentContainer(
  UserQuestionSetDetailsPage,
  {
    user_question_set: graphql`
      fragment UserQuestionSetDetailsPage_user_question_set on UserQuestionSet {
        id
        submitted_answer_sets {
          ...AnswerSets_answer_sets
        }
        question_set {
          label
        }
      }
    `,
    user: graphql`
      fragment UserQuestionSetDetailsPage_user on User {
        id
      }
    `,
  },
)
