// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card__card___CL5yr{background:#fff;border-radius:.277rem;box-shadow:0 3px 6px 2px rgba(7,7,8,.14);padding:1.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card__card___CL5yr"
};
export default ___CSS_LOADER_EXPORT___;
