// @flow

import React from 'react'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../hooks/useGraphPagination'
import { type QueryVariableType } from '../hooks/useInsights'
import { query } from '../queries/IndividualOnboardingInsightsQuery'

import { type IndividualOnboardingInsightsQuery } from '../queries/__generated__/IndividualOnboardingInsightsQuery.graphql'

type IndividualOnboardingInsightsGraphProps = {
  loadIndividualOnboardingQuery: (queryVariables: QueryVariableType) => void,
  queryReference: PreloadedQuery<IndividualOnboardingInsightsQuery>,
  queryVariables: QueryVariableType,
}

const IndividualOnboardingInsightsGraph = (
  props: IndividualOnboardingInsightsGraphProps,
) => {
  const {
    queryVariables,
    queryReference,
    loadIndividualOnboardingQuery,
  } = props

  const queryResult = usePreloadedQuery(query, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.individual_onboardings.series_categories',
  )

  const series = get(
    queryResult,
    'viewer.insights.individual_onboardings.series',
  )

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    queryVariables,
    loadQuery: loadIndividualOnboardingQuery,
  })

  return (
    <GraphContainer
      heading="Individual Onboarding"
      graphInfoText="This graph visualises the number of individuals who have successfully completed the onboarding process over the specified period."
    >
      {series && (
        <Graph
          colors={[navy[500], navy[400], navy[300]]}
          graphType="column"
          series={series}
          seriesCategories={seriesCategories}
          onPaginateLeft={() => onPaginate('left')}
          onPaginateRight={() => onPaginate('right')}
          isRightPaginationDisabled={isRightPaginationDisabled}
        />
      )}
    </GraphContainer>
  )
}

export default IndividualOnboardingInsightsGraph
