// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { IconNew } from 'care-ui'
import { CloseIcon } from 'care-ui/atoms/icons-new'

import { chipContainer, sizeStyle, variantStyle } from './Chip.style'

type PropsType = {
  label: string,
  onClick: () => void,
  size?: 'lg' | 'md',
  variant?: 'primary' | 'secondary',
}

const Chip = (props: PropsType) => {
  const { label, onClick, size = 'md' } = props
  const { css } = useFela({ ...props })

  return (
    <div className={css(chipContainer, sizeStyle, variantStyle)}>
      <div>{label}</div>

      <IconNew as={CloseIcon} size={size} onClick={onClick} />
    </div>
  )
}

export default Chip
