// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

type PropsType = {
  children: Node,
}

const contentStyle = () => ({
  margin: '0.5rem 2rem',
})

const BannerContent = ({ children }: PropsType) => {
  const { css } = useFela()
  return <div className={css(contentStyle)}>{children}</div>
}

export default BannerContent
