// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import { Box, FlexContainer, Heading, SimpleSelect, Text } from 'care-ui'

import { ScoreCardsCareOptionsList } from './ScoreCardsCareOptionsList'

import type { ScoreCardsCareOptions_user_trackable } from './__generated__/ScoreCardsCareOptions_user_trackable.graphql'
import type { ScoreCardsCareOptions_viewer } from './__generated__/ScoreCardsCareOptions_viewer.graphql'

type PropsType = {
  +user_trackable: ScoreCardsCareOptions_user_trackable,
  +viewer: ScoreCardsCareOptions_viewer,
}

const careOptionsTitle = () => ({
  marginBottom: '1rem',
})

const careOptionsIntro = () => ({
  marginBottom: '1rem',
})

const isClinical = (test: string) => userResource =>
  userResource.resource.clinical.toString() === test

const ScoreCardsCareOptionsComponent = ({
  user_trackable,
  viewer,
}: PropsType) => {
  const { suggested_user_resources } = user_trackable
  const careOptionFilters = [
    { label: 'All', value: 'all' },
    { label: 'Self Care', value: 'false' },
    { label: 'Work with Clinician', value: 'true' },
  ]

  const [selectedValue, setSelectedValue] = useState(careOptionFilters[0].value)

  const { css } = useFela()
  const changeCareOptionFilter = value => {
    setSelectedValue(value)
  }

  const selectedCareOptions =
    selectedValue === 'all'
      ? suggested_user_resources
      : suggested_user_resources.filter(isClinical(selectedValue))

  return (
    <Box marginY="sm">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <div>
          <Heading level={2} extend={careOptionsTitle}>
            Care Options
          </Heading>
          <Text className={css(careOptionsIntro)}>
            Choose from the self-care options or request an option with a
            clinician.
          </Text>
        </div>

        <Box width="200px">
          <SimpleSelect
            options={careOptionFilters}
            value={selectedValue}
            onChange={changeCareOptionFilter}
            dataTestIdForDropdown="careOptionsDropdown"
            dataTestIdForDropdownOptions="careOptions"
          />
        </Box>
      </FlexContainer>

      <div key={`filtered-care-options-${selectedCareOptions.length}`}>
        <ScoreCardsCareOptionsList
          user_resources={selectedCareOptions}
          viewer={viewer}
        />
      </div>
    </Box>
  )
}

export const ScoreCardsCareOptions = createFragmentContainer(
  ScoreCardsCareOptionsComponent,
  {
    user_trackable: graphql`
      fragment ScoreCardsCareOptions_user_trackable on UserTrackable {
        suggested_user_resources {
          ...ScoreCardsCareOptionsList_user_resources @relay(mask: false)
          ...ScoreCardsCareOptionsList_user_resources
        }
      }
    `,
    viewer: graphql`
      fragment ScoreCardsCareOptions_viewer on Viewer {
        ...ScoreCardsCareOptionsList_viewer
      }
    `,
  },
)
