// @flow

import React from 'react'
import { connect } from 'react-fela'
import { omit } from 'lodash/fp'

import { Column, Container, Row } from 'react-ui/components/Grid'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import { useFeatureToggle } from 'react-ui/hooks/useFeatureToggle'
import type { WelcomePageContainer_user } from 'containers/WelcomePage/__generated__/WelcomePageContainer_user.graphql'

import StaffWelcomePageWrapper from './StaffWelcomePageWrapper'
import UserOnboardingWizardWrapper from './UserOnboardingWizardWrapper'
import WelcomeCardsWrapper from './WelcomeCardsWrapper'

import type { FelaPropsType } from 'react-ui/typing'

export type WelcomePagePropsType = FelaPropsType & {
  initialQuestionnaireId: string,
  initialQuestionnaireStatus: string,
  roleType: string,
  summaryQuestionnaireId: string,
  user: WelcomePageContainer_user,
}

const styleRules = ({ theme }) => ({
  container: {
    padding: '0px',
    [theme.breakpoints.queries.md]: {
      padding: '0 10px',
    },
  },
  row: {
    marginLeft: '0',
    marginRight: '0',
  },
  column: {
    padding: '10px 18px',
    [theme.breakpoints.queries.md]: {
      padding: 'inherit',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    flexShrink: 1,
    flexGrow: 'inherit',
    backgroundColor: 'rgb(10,122,214)',
    background:
      'linear-gradient(rgba(10, 122, 214, 1.2) 0%, rgba(8, 205, 199,0.6) 96%)',
  },
})

const WelcomePage = (props: WelcomePagePropsType) => {
  const {
    user,
    roleType,
    initialQuestionnaireId,
    initialQuestionnaireStatus,
    rules,
  } = props

  const isInitialQuestionnaireCompleted =
    initialQuestionnaireStatus === 'Completed'
  const isIndividual = roleType === 'INDIVIDUAL'
  const { NURTURE_BEFORE_ACTIVATION } = useFeatureToggle()
  const showStepGrid =
    NURTURE_BEFORE_ACTIVATION &&
    isIndividual &&
    (user.show_individual_onboarding || !isInitialQuestionnaireCompleted)
  const showStepsInput = {
    user,
    initialQuestionnaireId,
    isInitialQuestionnaireCompleted,
  }

  const defaultContainer = (
    <ResponsiveLayout
      extend={{ ResponsiveLayout: rules.wrapper }}
      hasEmptyHeader
    >
      <Container extend={rules.container}>
        <Row extend={rules.row}>
          <Column extend={rules.column} lg={12} sm={12} xs={12}>
            {showStepGrid ? (
              <UserOnboardingWizardWrapper input={showStepsInput} />
            ) : (
              <WelcomeCardsWrapper {...omit(['rules', 'styles'])(props)} />
            )}
          </Column>
        </Row>
      </Container>
    </ResponsiveLayout>
  )

  const staffContainer = <StaffWelcomePageWrapper user={user} />

  return isIndividual ? defaultContainer : staffContainer
}

export default connect(styleRules)(WelcomePage)
