/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type IndividualsAdminInvite_current_role$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RootPlatformPage_user$ref: FragmentReference;
declare export opaque type RootPlatformPage_user$fragmentType: RootPlatformPage_user$ref;
export type RootPlatformPage_user = {|
  +id: string,
  +show_clinician_onboarding: boolean,
  +show_individual_onboarding: boolean,
  +mfa_code_required: boolean,
  +user_research_programs_responses: $ReadOnlyArray<{|
    +id: string
  |}>,
  +unanswered_tenant_research_programs: $ReadOnlyArray<{|
    +id: string,
    +research_program: {|
      +id: string,
      +title: string,
      +description: string,
    |},
  |}>,
  +current_role: {|
    +id?: string,
    +see_help_now_button?: boolean,
    +tenant?: ?{|
      +id: string,
      +label: string,
      +bannerText: ?string,
      +multi_roles_enabled: boolean,
    |},
    +role_type?: RoleTypes,
    +individual_detail?: ?{|
      +id: string,
      +read_only: boolean,
    |},
    +$fragmentRefs: IndividualsAdminInvite_current_role$ref,
  |},
  +completed_onboarding: boolean,
  +$refType: RootPlatformPage_user$ref,
|};
export type RootPlatformPage_user$data = RootPlatformPage_user;
export type RootPlatformPage_user$key = {
  +$data?: RootPlatformPage_user$data,
  +$fragmentRefs: RootPlatformPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RootPlatformPage_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "show_clinician_onboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "show_individual_onboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mfa_code_required",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResearchProgramsResponse",
      "kind": "LinkedField",
      "name": "user_research_programs_responses",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantResearchProgram",
      "kind": "LinkedField",
      "name": "unanswered_tenant_research_programs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ResearchProgram",
          "kind": "LinkedField",
          "name": "research_program",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualDetail",
              "kind": "LinkedField",
              "name": "individual_detail",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "read_only",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "IndividualRole",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "see_help_now_button",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bannerText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "multi_roles_enabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IndividualsAdminInvite_current_role"
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed_onboarding",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4bc287afb99e1a25b61cf919aac7c16';

module.exports = node;
