// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { Icon } from 'care-ui'
import StarFilled from 'care-ui/atoms/icons/star-filled.svg'
import StarOutline from 'care-ui/atoms/icons/star-outline.svg'

type PropsType = {
  lastUpdate: string,
  onClick: () => void,
  rating: string,
  selectable?: boolean,
  selected?: boolean,
  title: string,
  trend: string,
}

const bodyStyle = () => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
})

const titleStyle = ({ theme }) => {
  const { care } = theme

  return {
    ...care.typography.desktop.bodyLg,
  }
}

const containerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.care.spacing.xxs,
})

const ratingStyle = ({ theme }) => {
  const { care } = theme

  return {
    ...care.typography.desktop.bodyLgBold,
  }
}

const scoreContainerStyle = ({ theme }) => {
  const { care } = theme

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    ...care.typography.desktop.bodyLg,
    minHeight: care.spacing.lg,
  }
}

const HealthCardBody = ({
  rating,
  title,
  trend,
  lastUpdate,
  onClick,
  selectable,
  selected,
}: PropsType) => {
  const { css } = useFela()

  const icon = selected ? StarFilled : StarOutline

  const withTrend = (
    <div className={css(containerStyle)}>
      <div className={css(ratingStyle)}>{rating}</div>
      <div className={css(scoreContainerStyle)}>
        <div>{trend}</div>
        <div>{lastUpdate}</div>
      </div>
    </div>
  )

  const withoutTrend = (
    <div className={css(scoreContainerStyle)}>
      <div className={css(ratingStyle)}>{rating}</div>
      <Icon as={icon} />
    </div>
  )

  return (
    <div
      className={css(bodyStyle)}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={css(titleStyle)}>{title}</div>
      {selectable ? withoutTrend : withTrend}
    </div>
  )
}

export default HealthCardBody
