// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import { UserResourceLoader } from 'components/UserResource'

import type { ScoreCardsCareOptionsList_user_resources } from './__generated__/ScoreCardsCareOptionsList_user_resources.graphql'
import type { ScoreCardsCareOptionsList_viewer } from './__generated__/ScoreCardsCareOptionsList_viewer.graphql'

type PropsType = {
  componentId?: string,
  user_resources: ScoreCardsCareOptionsList_user_resources,
  viewer: ScoreCardsCareOptionsList_viewer,
}

const careOptionsListStyle = () => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const userResourceStyle = ({ theme }) => ({
  [theme.breakpoints.queries.md]: {
    width: '50%',
    '&:nth-child(odd)': {
      '> div:first-child': {
        '> div:first-child': {
          '> div:first-child': {
            margin: '0 1.5rem 0 0',
          },
        },
      },
    },
    '&:nth-child(even)': {
      '> div:first-child': {
        '> div:first-child': {
          '> div:first-child': {
            margin: '0 0 0 1.5rem',
          },
        },
      },
    },
  },
})

const ScoreCardsCareOptionsComponent = ({
  user_resources,
  viewer,
}: PropsType) => {
  const { css } = useFela()

  return (
    <div
      className={css(careOptionsListStyle)}
      data-testid="ScoreCardsCareOptionsList"
    >
      {user_resources.map(user_resource => (
        <div className={css(userResourceStyle)} key={user_resource.id}>
          <UserResourceLoader user_resource={user_resource} viewer={viewer} />
        </div>
      ))}
    </div>
  )
}

graphql`
  fragment ScoreCardsCareOptionsList_user_resource on UserResource {
    ...UserResource_user_resource
    status
    id
    resource {
      clinical
      id
      label
    }
  }
`

export const ScoreCardsCareOptionsList = createFragmentContainer(
  ScoreCardsCareOptionsComponent,
  {
    user_resources: graphql`
      fragment ScoreCardsCareOptionsList_user_resources on UserResource
        @relay(plural: true) {
        ...ScoreCardsCareOptionsList_user_resource @relay(mask: false)
      }
    `,
    viewer: graphql`
      fragment ScoreCardsCareOptionsList_viewer on Viewer {
        ...UserResource_viewer
      }
    `,
  },
)
