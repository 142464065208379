// @flow

import React from 'react'
import Link from 'found/Link'

import Heading from 'react-ui/components/Heading'

type PropsType = {
  onTryAgain: () => void,
}

const NewPasswordWaiting = ({ onTryAgain }: PropsType) => (
  <div>
    <Heading
      level={3}
      extend={({ theme }) => ({ color: theme.palette.component.primary.base })}
    >
      Please check your email
    </Heading>

    <p>
      If the email address or region provided is registered on our system, an
      email with the instructions to reset the password will be sent shortly.
      This password reset is only valid for the next 6 hours.
    </p>

    <p>
      If you do not receive an email in 5 minutes, please check your region
      setting and email address.
    </p>

    <div>
      <Link to="/users/password/new" onClick={onTryAgain}>
        Request the link again
      </Link>
    </div>
  </div>
)

export default NewPasswordWaiting
