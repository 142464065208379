// @flow

import React from 'react'

import ArrowLeft from 'react-ui/assets/icons/arrow-left.svg'
import ArrowRight from 'react-ui/assets/icons/arrow-right.svg'
import Icon from 'react-ui/components/Icon'

type PropsType = {
  direction: string,
  offset: number,
  setOffset: number => void,
}

const OffsetButton = ({ direction, offset, setOffset }: PropsType) => {
  let info = {}
  switch (direction) {
    case 'left':
      info = {
        change: -1,
        disabled: false,
        icon: ArrowLeft,
        ariaLabel: 'Move time range back',
      }
      break
    case 'right':
      info = {
        change: 1,
        disabled: offset === 1,
        icon: ArrowRight,
        ariaLabel: 'Move time range forward',
      }
      break
    default:
      throw new Error('no direction set')
  }

  const { ariaLabel, icon, change, disabled } = info

  return (
    <center
      aria-label={ariaLabel}
      data-testid={`offsetButton-${direction}`}
      style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <Icon
        as={icon}
        scale={0.4}
        onClick={() => {
          setOffset(offset + change)
        }}
      />
    </center>
  )
}

export default OffsetButton
