// @flow

import React from 'react'
import { connect } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { filter, orderBy } from 'lodash/fp'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { UserTrackableList } from 'components/UserTrackableList'
import { Column, Container, Row } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { hexToHue } from 'shared/services/colorConverter'

import type { FelaPropsType } from 'react-ui/typing'
import { type ExtensiveCardsSection_user } from './__generated__/ExtensiveCardsSection_user.graphql'

type PropsType = FelaPropsType & {
  user: ExtensiveCardsSection_user,
}

type ExtensiveCardsSectionProps = {
  userId: string,
}

const styleRules = () => ({
  container: {
    marginBottom: '10px',
  },
  titleWrapper: {
    textAlign: 'center',
    marginBottom: '1em',
  },
})

const query = graphql`
  query ExtensiveCardsSectionQuery($userId: ID!) {
    user(id: $userId) {
      ...ExtensiveCardsSection_user
    }
  }
`

const SimpleCardsBase = ({
  styles,
  user: { extensiveUserTrackables, linked_individual },
}: PropsType) => {
  const environment = useRelayEnvironment()
  const isReadOnlyIndividual = linked_individual?.individual_detail?.read_only
  const policies = usePolicies()

  const hueSorter = node => {
    // get hue from hex
    const hue = node.formula_range.color
      ? hexToHue(node.formula_range.color)
      : 0

    // hue higher than 330 is still red and considered priority color
    return hue >= 330 ? hue - 330 : hue
  }

  const trackableLabelSorter = 'trackable.label'

  const userTrackables = orderBy(
    [hueSorter, trackableLabelSorter],
    ['asc', 'asc'],
  )(extensiveUserTrackables.nodes)

  return (
    <>
      <Container className={styles.container}>
        <Row>
          <Column lg={8} sm={6} xs={12}>
            <Heading level={4} extend={{ marginTop: '14px' }}>
              Click on a health card to find fact sheets, browse a number of
              care options and review your results.
            </Heading>
          </Column>
        </Row>
      </Container>
      <div className={styles.titleWrapper}>
        <Heading level={3}>Your health priorities</Heading>
      </div>
      <UserTrackableList
        viewOnly={isReadOnlyIndividual || !policies.CAN_UPDATE_USER_TRACKABLES}
        isPlannedSelection
        isExtensive
        user_trackables={filter(['in_plan', true])(userTrackables)}
      />

      <UserTrackableList
        componentId="UserTrackableList"
        isExtensive
        user_trackables={filter(['in_plan', false])(userTrackables)}
        environment={environment}
      />
    </>
  )
}

const SimpleCardsLoader = createFragmentContainer(
  connect(styleRules)(SimpleCardsBase),
  {
    user: graphql`
      fragment ExtensiveCardsSection_user on User {
        extensiveUserTrackables: user_trackables(
          kinds: [text, additional_assessment]
        ) {
          nodes {
            ...UserTrackableList_user_trackables
            in_plan
            formula_range {
              ... on FormulaRangeInterface {
                color
              }
            }
            trackable {
              label
            }
          }
        }
        linked_individual {
          individual_detail {
            read_only
          }
        }
      }
    `,
  },
)

export default ({ userId }: ExtensiveCardsSectionProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{ userId }}
      render={response => (
        <QueryRendererLoadingIndicator response={response}>
          {({ user }) => <SimpleCardsLoader user={user} />}
        </QueryRendererLoadingIndicator>
      )}
    />
  )
}
