// @flow

import React from 'react'
import { withFormik } from 'formik'
import Link from 'found/Link'
import * as yup from 'yup'

import { Form } from 'shared/ui/Forms'
import { EnterPassword } from 'shared/ui/Forms/Fields'
import { Layout as l } from 'shared/ui/Styles'
import { Button } from 'care-ui'

import s from './PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type EditPasswordFormValues = {
  password: string,
  password_confirmation: string,
}

type EditPasswordFormPropsType = FormikProps & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  values: EditPasswordFormValues,
}

const EditPasswordForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  status = {},
  touched,
  values,
}: EditPasswordFormPropsType) => (
  <Form onSubmit={handleSubmit}>
    {status.error && <p className={s.mainError}>{status.error}</p>}
    <EnterPassword
      errors={errors}
      touched={touched}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.password}
      userPasswordConfirm={values.password_confirmation}
      lightGrayLabel
      fullWidth
      hasConfirm
    />

    <Button variant="primary" type="submit" disabled={isSubmitting}>
      Save
    </Button>
    <p>
      <Link to="/users/sign_in" className={l.smallLink}>
        Back to login
      </Link>
    </p>
  </Form>
)

export default withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({ password: '', password_confirmation: '' }),

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: yup.object().shape({
    password: yup
      .string()
      .label('Password')
      .min(6)
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .label('Password confirmation')
      .oneOf([yup.ref('password'), null], 'Must match password')
      .required('Must match password'),
  }),
})(EditPasswordForm)
