/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffEmployeesTableLoader_role$ref = any;
export type RoleReinviteInput = {|
  clientMutationId?: ?string,
  roleId: string,
|};
export type ReinviteStaffMutationVariables = {|
  input: RoleReinviteInput
|};
export type ReinviteStaffMutationResponse = {|
  +roleReinvite: ?{|
    +role: {|
      +id?: string,
      +$fragmentRefs: StaffEmployeesTableLoader_role$ref,
    |}
  |}
|};
export type ReinviteStaffMutation = {|
  variables: ReinviteStaffMutationVariables,
  response: ReinviteStaffMutationResponse,
|};
*/


/*
mutation ReinviteStaffMutation(
  $input: RoleReinviteInput!
) {
  roleReinvite(input: $input) {
    role {
      __typename
      ... on RoleInterface {
        __isRoleInterface: __typename
        id
        ...StaffEmployeesTableLoader_role
      }
      ... on AdminRole {
        id
      }
      ... on ClinicianRole {
        id
      }
      ... on IndividualRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
  }
}

fragment StaffEmployeesTableLoader_role on RoleInterface {
  __isRoleInterface: __typename
  id
  role_type
  role_status
  description
  tenant {
    name
    requires_mfa
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReinviteStaffMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoleReinvitePayload",
        "kind": "LinkedField",
        "name": "roleReinvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StaffEmployeesTableLoader_role"
                  }
                ],
                "type": "RoleInterface",
                "abstractKey": "__isRoleInterface"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReinviteStaffMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoleReinvitePayload",
        "kind": "LinkedField",
        "name": "roleReinvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role_type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role_status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requires_mfa",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "RoleInterface",
                "abstractKey": "__isRoleInterface"
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "AdminRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "IndividualRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "LeadClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ManagerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "OwnerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ResearcherRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "SuperRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "SupportPersonRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78689d4fcdc509f8e7c4ffda29b31076",
    "id": null,
    "metadata": {},
    "name": "ReinviteStaffMutation",
    "operationKind": "mutation",
    "text": "mutation ReinviteStaffMutation(\n  $input: RoleReinviteInput!\n) {\n  roleReinvite(input: $input) {\n    role {\n      __typename\n      ... on RoleInterface {\n        __isRoleInterface: __typename\n        id\n        ...StaffEmployeesTableLoader_role\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on IndividualRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n  }\n}\n\nfragment StaffEmployeesTableLoader_role on RoleInterface {\n  __isRoleInterface: __typename\n  id\n  role_type\n  role_status\n  description\n  tenant {\n    name\n    requires_mfa\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6dc9f21519a049aad7cee443596f2f0';

module.exports = node;
