// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import { Grid, GridItem, Icon } from 'care-ui'

import AssessmentRequestsItem from './AssessmentRequestsItem'
import {
  alertStyle,
  iconStyle,
  wrapperStyle,
} from './AssessmentRequestsLibrary.style'
import description from './description.json'

const query = graphql`
  query AssessmentRequestsLibraryQuery($id: ID!, $requesteeRoleId: ID) {
    user(id: $id) {
      linked_individual {
        id
        requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) {
          id
          label
          library_description
          category
        }
      }
    }
  }
`

const DEFAULT_LANG = 'en'

const AssessmentRequestsLibrary = () => {
  const {
    userId,
    assessmentRequesteeId,
    setPreSelected,
    individualId,
  } = useAssessmentRequesteeContext()
  const { css } = useFela()
  const { user } = useLazyLoadQuery(query, {
    id: userId,
    requesteeRoleId: individualId,
  })
  const { linked_individual } = user || {}
  const { requestableQuestionnaires } = linked_individual || {}

  const isIndividualRequestee = assessmentRequesteeId !== individualId

  const toggleRender = questionnaireId => {
    setPreSelected(questionnaireId)
  }

  return (
    <>
      {!isIndividualRequestee && (
        <p className={css(alertStyle)}>
          <span className={css(iconStyle)}>
            <Icon as={InfoCircle} scale={0.6} />
          </span>
          Assessments that don&apos;t apply to the patient have been removed
          from the list.
        </p>
      )}

      <Grid extend={wrapperStyle}>
        {requestableQuestionnaires.map(
          ({ label, id, library_description, category }) => {
            const trackableDescription = description[DEFAULT_LANG][category]
            return (
              <GridItem xl={3} lg={4} md={6}>
                <AssessmentRequestsItem
                  key={uniqueId('assessment-requests-item-')}
                  label={label}
                  toggleList={toggleRender}
                  questionnaireId={id}
                  description={library_description || trackableDescription}
                />
              </GridItem>
            )
          },
        )}
      </Grid>
    </>
  )
}

export default AssessmentRequestsLibrary
