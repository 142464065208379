// @flow

export const COUNT = 25

export const DEFAULT_TABLE_CONFIGURATIONS = {
  assigned: {
    assigned: true,
    prioritise: true,
    status: [],
    sort: { column: 'recentActivity', direction: 'ASC' },
    sortCode: 'recentActivity-recentActivity',
    scoresTable: true,
    standardTable: false,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
  clinician_active: {
    assigned: null,
    prioritise: null,
    status: 'Active',
    sort: { column: 'recentActivity', direction: 'ASC' },
    sortCode: 'recentActivity-recentActivity',
    scoresTable: true,
    standardTable: false,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
  invited: {
    assigned: null,
    prioritise: null,
    status: 'Invited',
    sort: { column: 'invitedStatus', direction: 'ASC' },
    sortCode: 'invitedStatus-status',
    scoresTable: false,
    standardTable: true,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
  removed: {
    assigned: null,
    prioritise: null,
    status: 'Removed',
    sort: { column: 'removedStatus', direction: 'DESC' },
    sortCode: 'removedStatus-status',
    scoresTable: false,
    standardTable: true,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
  discharged: {
    assigned: null,
    prioritise: null,
    status: 'Discharged',
    sort: { column: 'dischargedStatus', direction: 'DESC' },
    sortCode: 'dischargedStatus-status',
    scoresTable: false,
    standardTable: true,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
  active: {
    assigned: null,
    prioritise: null,
    status: 'Active',
    sort: { column: 'activeStatus', direction: 'ASC' },
    sortCode: 'activeStatus-status',
    scoresTable: false,
    standardTable: true,
    search: '',
    filterEscalation: false,
    helpRequested: false,
  },
}

export const TABLE_CATEGORIES = {
  assigned: 'scores',
  clinician_active: 'scores',
  invited: 'standard',
  removed: 'standard',
  discharged: 'standard',
  active: 'standard',
}
