// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { nodesOf } from 'shared/utils/paginationUtils'
import { Text } from 'care-ui'

import { AssessmentRequestsComponentLoader } from './AssessmentRequestsComponent'
import { mainContentStyle, wrapperStyle } from './AssessmentRequestsList.style'
import { useAssessmentRequesteeContext } from './useAssessmentRequesteeContext'

type PropsType = {
  per?: number,
}

const DEFAULT_COUNT = 2

const assessmentRequestsListPaginatedQuery = graphql`
  query AssessmentRequestsList_PaginatedQuery(
    $id: ID!
    $count: Int!
    $cursor: String
    $isIndividual: Boolean!
    $isClincian: Boolean!
  ) {
    node(id: $id) {
      ... on User {
        ...AssessmentRequestsList_user
          @include(if: $isClincian)
          @arguments(count: $count, cursor: $cursor)
        ...AssessmentRequestsList_individual_user
          @include(if: $isIndividual)
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

const assessmentRequestsListFragment = graphql`
  fragment AssessmentRequestsList_user on User
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 2 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "AssessmentRequestsListPaginationQuery") {
    id
    assessment_requested(first: $count, after: $cursor)
      @connection(key: "AssessmentRequestsList_assessment_requested") {
      edges {
        node {
          ... on AssessmentRequest {
            ...AssessmentRequestsComponent_assessment_request
          }
        }
      }
    }
  }
`

const individualAssessmentRequestsListFragment = graphql`
  fragment AssessmentRequestsList_individual_user on User
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 2 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "UserAssessmentRequestsListPaginationQuery") {
    id
    current_role {
      ... on IndividualRole {
        assessment_requested(first: $count, after: $cursor)
          @connection(
            key: "AssessmentRequestsList_individual_assessment_requested"
          ) {
          edges {
            node {
              ... on AssessmentRequest {
                ...AssessmentRequestsComponent_assessment_request
              }
            }
          }
        }
      }
    }
  }
`

const AssessmentRequestsList = ({ per = DEFAULT_COUNT }: PropsType) => {
  const { css } = useFela()
  const { userId, isIndividual } = useAssessmentRequesteeContext()

  const { node } = useLazyLoadQuery(
    assessmentRequestsListPaginatedQuery,
    { id: userId, count: per, isIndividual, isClincian: !isIndividual },
    { fetchPolicy: 'network-only' },
  )

  const fragment = isIndividual
    ? individualAssessmentRequestsListFragment
    : assessmentRequestsListFragment

  const { data, loadNext, hasNext } = usePaginationFragment(fragment, node)

  const source = isIndividual
    ? data?.current_role?.assessment_requested
    : data?.assessment_requested

  const assessment_requests = nodesOf(source)

  return (
    <>
      <div className={css(wrapperStyle)}>
        <div className={css(mainContentStyle)}>
          {assessment_requests.length ? (
            assessment_requests.map(assessment_request => (
              <AssessmentRequestsComponentLoader
                assessment_request={assessment_request}
                key={uniqueId('AssessmentRequests')}
              />
            ))
          ) : (
            <Text size="lg" color="title" center>
              No assessment requests found
            </Text>
          )}
        </div>
      </div>
      {hasNext && <LoadMore handleClick={() => loadNext(per)} />}
    </>
  )
}

export default AssessmentRequestsList
