// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'
import omit from 'lodash/fp/omit'

import { H4 } from 'react-ui/components/Heading'
import { felaProps, withoutFelaProps } from 'shared/services/fela'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = {
  children: Node,
  clickable: boolean,
  icon?: Node,
  onClick: () => void,
  title: string,
} & FelaPropsType

const styleRules = ({ theme, clickable }) => ({
  util: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '142px',
    backgroundColor: theme.palette.dividerColor,
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: 1.5,
    cursor: clickable ? 'pointer' : 'auto',
  },
  headingStylesOverride: {
    marginTop: 0,
  },
})

const UtilClickEnabledFragment = ({
  rules,
  styles = {},
  children,
  title,
  onClick,
  icon,
  ...other
}: PropsType) => (
  <div
    className={styles.util}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    {...withoutFelaProps(other)}
  >
    {icon}
    <H4 extend={rules.headingStylesOverride}>{title}</H4>
    {children}
  </div>
)

const UtilClickDisabledFragment = ({
  rules,
  styles = {},
  children,
  title,
  icon,
  ...other
}: PropsType) => (
  <div className={styles.util} {...omit(['onClick', ...felaProps])(other)}>
    {icon}
    <H4 extend={rules.headingStylesOverride}>{title}</H4>
    {children}
  </div>
)

const Util = (props: PropsType) => {
  const Fragment = props.clickable
    ? UtilClickEnabledFragment
    : UtilClickDisabledFragment
  return Fragment(omit(['clickable'], props))
}

export default connect(styleRules)(Util)
