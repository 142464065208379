/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type DataGridRowFooterContentLoader_user$ref = any;
type StaffActions_user$ref = any;
type UserCardLoader_user$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffEmployeesTableLoader_staff$ref: FragmentReference;
declare export opaque type StaffEmployeesTableLoader_staff$fragmentType: StaffEmployeesTableLoader_staff$ref;
export type StaffEmployeesTableLoader_staff = {|
  +user: {|
    +email: string,
    +invitation_created_at: ?any,
    +legal_name: ?string,
    +invitation_delta: ?string,
    +$fragmentRefs: AvatarLoader_user$ref & UserCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref & StaffActions_user$ref,
  |},
  +roles: $ReadOnlyArray<{|
    +id?: string,
    +role_type?: RoleTypes,
    +role_status?: string,
    +description?: string,
    +tenant?: ?{|
      +name: string,
      +requires_mfa: boolean,
    |},
  |}>,
  +$refType: StaffEmployeesTableLoader_staff$ref,
|};
export type StaffEmployeesTableLoader_staff$data = StaffEmployeesTableLoader_staff;
export type StaffEmployeesTableLoader_staff$key = {
  +$data?: StaffEmployeesTableLoader_staff$data,
  +$fragmentRefs: StaffEmployeesTableLoader_staff$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffEmployeesTableLoader_staff",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_delta",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserCardLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DataGridRowFooterContentLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StaffActions_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "requires_mfa",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Staff",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '095cb57fd91a01b76049d385c7a4e65a';

module.exports = node;
