// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import { useRouter } from 'found'
import { uniqueId } from 'lodash'

import { Button, Heading, Text } from 'care-ui'

import HealthCard from '../HealthCard'

import {
  cardContainerStyle,
  containerStyle,
  headerStyle,
  subHeaderStyle,
  subtitleStyle,
} from './HealthPriorityList.style'

import type { HealthPriorityList_user_trackables } from './__generated__/HealthPriorityList_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthPriorityList_user_trackables,
  viewOnly?: boolean,
}

const healthCardListFragment = graphql`
  fragment HealthPriorityList_user_trackables on UserTrackable
    @relay(plural: true) {
    ...HealthCard_user_trackable
  }
`

const HealthPriorityList = ({
  user_trackables: fragmentRef,
  viewOnly,
}: PropsType) => {
  const user_trackables = useFragment(healthCardListFragment, fragmentRef)
  const empty = user_trackables?.length === 0

  const { css } = useFela({ empty })
  const { router } = useRouter()

  const handleClick = () => {
    router.push('dashboard_preferences')
  }

  const healthCards = user_trackables?.map(user_trackable => {
    return (
      <HealthCard
        key={uniqueId('health-card-')}
        user_trackable={user_trackable}
      />
    )
  })

  const withTrackables = (
    <>
      <div className={css(headerStyle)}>
        <div className={css(subHeaderStyle)}>
          <Heading level={2}>Health Priorities</Heading>

          {!viewOnly && (
            <Button
              variant="text"
              onClick={handleClick}
              ariaLabel="Update Health Priorities"
              dataTestId="update-health-priorities"
            >
              Update Health Priorities
            </Button>
          )}
        </div>
        <Text className={css(subtitleStyle)}>
          These are the focus areas to improve your overall wellbeing
        </Text>
      </div>
      <div className={css(cardContainerStyle)}>{healthCards}</div>
    </>
  )

  const withoutTrackables = (
    <>
      <Text className={css(subtitleStyle)}>
        You currently have no Health Priorities selected.
      </Text>
      {!viewOnly && (
        <Button
          size="sm"
          shape="rounded"
          variant="primary"
          bordered={false}
          onClick={handleClick}
        >
          Select Health Priorities
        </Button>
      )}
    </>
  )

  return (
    <div className={css(containerStyle)}>
      {empty ? withoutTrackables : withTrackables}
    </div>
  )
}

export default HealthPriorityList
