// @flow

import React, { type Node, useEffect } from 'react'
import { useFela } from 'react-fela'

import { Button, Heading, Icon } from 'care-ui'
import Close from 'care-ui/atoms/icons/close.svg'

import { modalBody, modalHeader, modalStyle } from './Modal.style'

type PropsType = {
  children: Node,
  closeModal: () => void,
  dataTestId?: string,
  heading?: string,
  isOpen: boolean,
  width?: string,
}

const Modal = (props: PropsType) => {
  const { children, isOpen, heading, closeModal, dataTestId } = props

  const { css } = useFela({ ...props })
  const ref: any = React.useRef(null)

  // Workaround for existing test
  const isFunction =
    typeof ref?.current?.showModal === 'function' && typeof ref?.current?.close

  // Toggle Body Scroll On Modal Open/Close
  const disableBodyScroll = (disable?: boolean) => {
    if (typeof window !== 'undefined' && document.body) {
      document.body.style.overflow = disable ? 'hidden' : ''
    }
  }

  useEffect(
    () => {
      // Workaround for existing test
      if (!isFunction) {
        return undefined
      }

      if (isOpen) {
        disableBodyScroll(true)
        return ref?.current?.showModal()
      }

      disableBodyScroll(false)
      return ref?.current?.close()
    },
    [isOpen],
  )

  const handleOnCancel = () => {
    disableBodyScroll(false)
    closeModal()
  }

  return (
    <dialog
      ref={ref}
      className={css(modalStyle)}
      onCancel={handleOnCancel}
      data-testid={dataTestId}
    >
      {/* Modal Header */}
      <div className={css(modalHeader)}>
        <Heading level={3} color="positive" bold>
          {heading}
        </Heading>

        <Button
          variant="text"
          onClick={handleOnCancel}
          dataTestId="closeButton"
        >
          <Icon as={Close} />
        </Button>
      </div>

      {/* Modal Body */}
      <div className={css(modalBody)}>{children}</div>
    </dialog>
  )
}

export default Modal
