// @flow

import React from 'react'

import { Box, FlexContainer, FlexItem } from 'care-ui'

import TableSearchControl from '../components/TableSearchControl/TableSearchControl'
import TableSortControl from '../components/TableSortControl/TableSortControl'
import TableTypeSelectControl from '../components/TableTypeSelectControl/TableTypeSelectControl'
import { DEFAULT_TABLE_CONFIGURATIONS } from '../constants/EmployeesTableConstant'
import { type UseEmployeesTableReturnType } from '../hooks/useEmployeesTable'

const SORT_OPTIONS = [
  { header: 'name', label: 'Employee name' },
  { header: 'email', label: 'Email address' },
]

const EMPLOYEES_TABLE_TYPES = [
  {
    label: 'Active',
    value: 'Active',
    status: 'Active',
  },
  {
    label: 'Invited',
    value: 'Invited',
    status: 'Invited',
  },
  {
    label: 'Removed',
    value: 'Removed',
    status: 'Removed',
  },
]

type PartialEmployeesTableReturnType = $Shape<UseEmployeesTableReturnType>

type EmployeesTableControlsProps = {
  useEmployeesTablePayload: PartialEmployeesTableReturnType,
}

const EmployeesTableControls = (props: EmployeesTableControlsProps) => {
  const { useEmployeesTablePayload } = props
  const {
    setTableType,
    tableType,
    queryVariables,
    updateQueryVariables,
  } = useEmployeesTablePayload

  const handleOnChange = updatedTableType => {
    // set the table type and reset the queryVariables
    setTableType(updatedTableType)

    updateQueryVariables(DEFAULT_TABLE_CONFIGURATIONS[updatedTableType])
  }

  return (
    <>
      <FlexContainer justifyContent="space-between" paddingX="xxs">
        <FlexContainer gap="xxxs">
          <Box width="120px">
            <TableTypeSelectControl
              options={EMPLOYEES_TABLE_TYPES}
              onChange={updatedTableType => handleOnChange(updatedTableType)}
              tableType={tableType}
            />
          </Box>

          <FlexItem>
            <TableSortControl
              sortOptions={SORT_OPTIONS}
              queryVariables={queryVariables}
              updateQueryVariables={updateQueryVariables}
            />
          </FlexItem>
        </FlexContainer>

        <TableSearchControl
          queryVariables={queryVariables}
          updateQueryVariables={updateQueryVariables}
        />
      </FlexContainer>
    </>
  )
}

export default EmployeesTableControls
