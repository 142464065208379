// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Markdown from 'react-ui/components/Markdown'
import { FlexContainer, FlexItem, Text } from 'care-ui'

import type { AssessmentRequestsAnswerTableBase_answers } from './__generated__/AssessmentRequestsAnswerTableBase_answers.graphql'

type PropsType = {
  answers: ?AssessmentRequestsAnswerTableBase_answers,
}

const AssessmentRequestsAnswerTableBase = (props: PropsType) => {
  const { answers } = props

  return (
    <>
      <FlexContainer
        paddingY="lg"
        paddingX="xxs"
        alignItems="center"
        wrap="nowrap"
        gap="xs"
      >
        <FlexItem flex={3}>
          <Text size="lg" bold color="title">
            Question
          </Text>
        </FlexItem>
        <FlexItem flex={2}>
          <Text size="lg" bold color="title">
            Answer
          </Text>
        </FlexItem>
      </FlexContainer>

      {answers?.map((answer, index) => {
        return (
          <FlexContainer
            paddingY="lg"
            justifyContent="center"
            alignItems="start"
            wrap="nowrap"
            gap="xs"
            dataTestId="QuestionAnswerRow"
          >
            <FlexItem flex={3} dataTestId="Question">
              <Text size="lg" color="title">
                <Markdown
                  source={`${index + 1} ${answer.question.label || ''}`}
                />
              </Text>
            </FlexItem>

            <FlexItem flex={2} dataTestId="Answer">
              <Text size="lg" color="title">
                <Markdown source={answer.display_value} />
              </Text>
            </FlexItem>
          </FlexContainer>
        )
      })}
    </>
  )
}

export const AssessmentRequestsAnswerTableLoader = createFragmentContainer(
  AssessmentRequestsAnswerTableBase,
  {
    answers: graphql`
      fragment AssessmentRequestsAnswerTableBase_answers on AnswerInterface
        @relay(plural: true) {
        id
        display_value
        question {
          label
        }
      }
    `,
  },
)
