// @flow

import React from 'react'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'

import useGraphPagination from '../hooks/useGraphPagination'
import { type QueryVariableType } from '../hooks/useInsights'
import { query } from '../queries/IndividualLoginsInsightsQuery'

import { type IndividualLoginsInsightsQuery } from '../queries/__generated__/IndividualLoginsInsightsQuery.graphql'

type IndividualLoginsInsightsGraphProps = {
  loadIndividualLoginsQuery: (queryVariables: QueryVariableType) => void,
  queryReference: PreloadedQuery<IndividualLoginsInsightsQuery>,
  queryVariables: QueryVariableType,
}

const IndividualLoginsInsightsGraph = (
  props: IndividualLoginsInsightsGraphProps,
) => {
  const { queryVariables, queryReference, loadIndividualLoginsQuery } = props
  const queryResult = usePreloadedQuery(query, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.individual_logins.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.individual_logins.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    queryVariables,
    loadQuery: loadIndividualLoginsQuery,
  })

  return (
    <GraphContainer
      heading="Individual Sign Ins"
      graphInfoText="This graph visualises the number of individual sign ins over the specified period, helping to track user engagement and activity on the platform. The graph will break down the sign ins based on the number of times each individual signed in."
    >
      {series && (
        <Graph
          graphType="stack"
          series={series}
          seriesCategories={seriesCategories}
          onPaginateLeft={() => onPaginate('left')}
          onPaginateRight={() => onPaginate('right')}
          isRightPaginationDisabled={isRightPaginationDisabled}
          xAxisTitle="Number of individual sign ins"
        />
      )}
    </GraphContainer>
  )
}

export default IndividualLoginsInsightsGraph
