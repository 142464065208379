// @flow

import React from 'react'
import Loadable from 'react-loadable'

import { LoadingSpinner } from 'care-ui'

export const ConnectedOnboardingIndividualPage = Loadable({
  loader() {
    return import('./Individual').then(cjsModule => cjsModule.default)
  },
  loading() {
    return <LoadingSpinner message="Loading" />
  },
  render: React.createElement,
})

export const ConnectedSetPasswordPage = Loadable({
  loader() {
    return import('./SetPassword').then(cjsModule => cjsModule.default)
  },
  loading() {
    return <LoadingSpinner message="Loading" />
  },
  render: React.createElement,
})

export const ConnectedQuestionnaireIndividualPage = Loadable({
  loader() {
    return import('./IndividualQuestionnaire').then(
      cjsModule => cjsModule.default,
    )
  },
  loading() {
    return <LoadingSpinner message="Loading" />
  },
  render: React.createElement,
})
