// @flow

import React from 'react'
import { useRelayEnvironment } from 'react-relay'

import UserCheckBox from 'react-ui/components/Form/UserCheckBox'
import { type UserCheckBoxLoader_user } from 'react-ui/components/Form/UserCheckBox/__generated__/UserCheckBoxLoader_user.graphql'
import { useLoading } from 'react-ui/hooks/useLoading'
import { commit as commitClinicianIndividualAssignmentCreate } from 'mutations/ClinicianIndividualAssignmentCreate'
import { commit as commitClinicianIndividualAssignmentDelete } from 'mutations/ClinicianIndividualAssignmentDelete'

type AssignmentCheckboxPropsType = {
  assignable: boolean,
  assigned: boolean,
  clinician: { +id: string },
  individual: { +id: string },
  user: UserCheckBoxLoader_user,
}

export const AssignmentCheckbox = ({
  user,
  clinician,
  individual,
  assigned,
  assignable,
}: AssignmentCheckboxPropsType) => {
  const { isLoading, startLoading, endLoading } = useLoading()
  const environment = useRelayEnvironment()

  const onChange = () => {
    startLoading()
    const args = {
      environment,
      variables: {
        input: {
          clinician_assignment: {
            clinician_id: clinician.id,
            individual_id: individual.id,
          },
        },
        individual_id: individual.id,
        clinician_id: clinician.id,
      },
      onCompleted: endLoading,
    }
    if (assigned) commitClinicianIndividualAssignmentDelete({ ...args })
    else commitClinicianIndividualAssignmentCreate({ ...args })
  }

  return (
    <UserCheckBox
      block
      disabled={!assignable || isLoading}
      checked={assigned}
      onChange={onChange}
      user={user}
      staff
    />
  )
}
