// @flow

import React from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { get } from 'lodash'

import { FlexContainer, ScrollableBox } from 'care-ui'

import HelpNowCard from './HelpNowCard'

const HelpNowProvidersTenantQuery = graphql`
  query HelpNowModalBodyTenant_Query($id: ID!, $skip: Boolean!) {
    viewer @skip(if: $skip) {
      tenant(id: $id) {
        emergencyNumber
        help_now_providers {
          chat_url
          description
          id
          slug
          logo_url
          phone_number
          sms
          title
          url
        }
      }
    }
  }
`

const HelpNowModalBody = ({ tenantId }: { tenantId?: string }) => {
  const queryResult = useLazyLoadQuery(HelpNowProvidersTenantQuery, {
    id: tenantId || '',
    skip: !tenantId,
  })

  const providers =
    get(queryResult, 'viewer.tenant.help_now_providers') ||
    window.GLOBALS?.publicHelpNowProviders

  return (
    <ScrollableBox maxHeight="60vh" maxWidth="100%">
      <FlexContainer direction="column" gap="xs">
        {providers?.map(provider => (
          <HelpNowCard provider={provider} key={provider.slug} />
        ))}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default HelpNowModalBody
