// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import Markdown from 'react-ui/components/Markdown'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import SupportingSupporteeRemoveModal from './SupportingSupporteeRemoveModal'

import type { SupportingSupporteeInfoWrapper_individual_assignments$key } from './__generated__/SupportingSupporteeInfoWrapper_individual_assignments.graphql'

type PropsType = {
  individual_assignments: SupportingSupporteeInfoWrapper_individual_assignments$key,
}
const displayInline = ({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  maxWidth: '67rem',
  margin: '2rem auto',
  [theme.breakpoints.queries.sm]: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
})

const markdownStyle = () => ({
  display: 'inline',
  '& > p': {
    display: 'inline',
  },
})

const assignmentFragment = graphql`
  fragment SupportingSupporteeInfoWrapper_individual_assignments on SupportPersonAssignment
    @relay(plural: true) {
    id
    individual {
      id
      legal_name
      tenant {
        support_person_help
      }
    }
    ...SupportingSupporteeRemoveModal_individual_assignments
  }
`

const SupportingSupporteeInfoWrapper = ({
  individual_assignments: assignmentsFragmentRef,
}: PropsType) => {
  const { css } = useFela()
  const individualAssignments = useFragment(
    assignmentFragment,
    assignmentsFragmentRef,
  )

  const supportPersonHelpList = individualAssignments.map(
    ({ individual }, index) => {
      const { legal_name, tenant } = individual
      const { support_person_help } = tenant || {}

      return (
        support_person_help && (
          <span key={uniqueId('support-person-help-list-')}>
            {index !== 0 && <span>, or </span>}
            <Markdown
              source={support_person_help}
              className={css(markdownStyle)}
              data-testid="websiteLinks"
            />
            {individualAssignments.length > 1 && (
              <span> (for {legal_name})</span>
            )}
          </span>
        )
      )
    },
  )
  const firstContent = [
    'This questionnaire will help provide an additional perspective on the health and well being of the person you are supporting.',
    'Your answers will only be seen by the person you are supporting and their connected clinicians.',
  ]

  const contactOptions = (
    <>
      There’s no right or wrong way to go about supporting someone, often the
      first step is having an open conversation. While this can be difficult to
      do, check out the&nbsp;
      {supportPersonHelpList}
      &nbsp;website to help you plan better for these conversations.
    </>
  )

  const customNote = (
    <SupportingSupporteeRemoveModal
      individualAssignments={individualAssignments}
    />
  )

  return (
    <div className={css(displayInline)}>
      <QuestionnaireInfoCard
        title="Important information"
        points={firstContent}
        avatar={Team}
      />

      <QuestionnaireInfoCard
        title="What else can I do now?"
        points={[contactOptions]}
        avatar={InfoCircle}
        customNote={customNote}
      />
    </div>
  )
}

export default SupportingSupporteeInfoWrapper
