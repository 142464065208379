// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { concat, uniqBy } from 'lodash/fp'

import DefinitionList from 'react-ui/components/DefinitionList'
import { Heading, Text } from 'care-ui'

import { PersonalDetailFields } from './Settings/PersonalDetailFields'
import { fieldLinkGenerator } from './Settings/services'
import UserRemoveInstructions from './UserRemoveInstructions'

import type { UserSettingsPersonalDetailsPage_profileQuestionnaire } from './__generated__/UserSettingsPersonalDetailsPage_profileQuestionnaire.graphql'
import type { UserSettingsPersonalDetailsPage_userFields } from './__generated__/UserSettingsPersonalDetailsPage_userFields.graphql'

type PropsType = {
  +profileQuestionnaire: UserSettingsPersonalDetailsPage_profileQuestionnaire,
  +userFields: UserSettingsPersonalDetailsPage_userFields,
}

const renderedFields = (
  userFields: UserSettingsPersonalDetailsPage_userFields,
  profileQuestionnaire: UserSettingsPersonalDetailsPage_profileQuestionnaire,
) => {
  return uniqBy(
    'id',
    concat(
      fieldLinkGenerator(
        'settings_user_field',
        'CAN_UPDATE_USERS',
        userFields.profileFields,
      ),
      PersonalDetailFields(
        profileQuestionnaire,
        'CAN_UPDATE_INDIVIDUAL_PERSONAL_DETAIL',
        'settings_clinician_detail_field',
      ),
    ),
  )
}

export const UserSettingsPersonalDetailsPage = ({
  userFields: { tenant, unconfirmed_email },
  userFields: { current_role: { role_type } },
  userFields,
  profileQuestionnaire,
}: PropsType) => {
  const details = renderedFields(userFields, profileQuestionnaire)
  const updatedDetails = details.map(detail => {
    if (detail.id === 'email' && unconfirmed_email) {
      return {
        ...detail,
        warning: 'Email change requested, awaiting confirmation.',
      }
    }
    return detail
  })

  const isIndividual = role_type === 'INDIVIDUAL'
  return (
    <>
      <Heading level={3} margin="xxs">
        Profile Settings
      </Heading>
      <Text size="lg">
        On this page, you can manage your information, including your contact
        details
      </Text>
      <DefinitionList details={updatedDetails} />
      {isIndividual && (
        <UserRemoveInstructions
          contract={tenant?.contract}
          privacyUrl={tenant?.privacyUrl}
        />
      )}
    </>
  )
}

export const UserSettingsPersonalDetailsPageQuery = graphql`
  query UserSettingsPersonalDetailsPage_Query {
    viewer {
      currentUser {
        ...UserSettingsPersonalDetailsPage_userFields
        profileQuestionnaire {
          ...UserSettingsPersonalDetailsPage_profileQuestionnaire
        }
      }
    }
  }
`

export const UserSettingsPersonalDetailsPageLoader = createFragmentContainer(
  UserSettingsPersonalDetailsPage,
  {
    userFields: graphql`
      fragment UserSettingsPersonalDetailsPage_userFields on User {
        current_role {
          ... on RoleInterface {
            id
            role_type
          }
        }
        tenant {
          contract
          privacyUrl
        }
        multi_roles_enabled
        profileFields {
          id
          label
          value
          noLink
          placeholder
        }
        unconfirmed_email
      }
    `,
    profileQuestionnaire: graphql`
      fragment UserSettingsPersonalDetailsPage_profileQuestionnaire on SimpleQuestionnaire {
        questions {
          name
          label
          displayValue
        }
        orders
      }
    `,
  },
)
