// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { startCase } from 'lodash'

import { AccordionItem } from 'react-ui/components/Accordion'
import Markdown from 'react-ui/components/Markdown'

import type { ScoreCardsPageAbout_trackable } from './__generated__/ScoreCardsPageAbout_trackable.graphql'

type PropsType = {
  trackable: ScoreCardsPageAbout_trackable,
}

const markdownStyle = ({ theme }) => ({
  marginLeft: '1rem',
  lineHeight: '1rem',
  ...theme.care.typography.desktop.bodyLg,
})

const accordionItemStyle = (theme, isOpen) => ({
  AccordionItem: {
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: theme.palette.lightBackground,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    paddingBottom: '1rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    ...theme.care.typography.desktop.bodyLgBold,
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.lightBackground,
    paddingTop: '0.1rem',
    paddingBottom: '1rem',
    borderRadius: '0 0 4px 4px',
  },
})

const ScoreCardsPageAboutComponent = ({ trackable }: PropsType) => {
  const [isOpen, setOpen] = useState(false)
  const onToggleOpen = () => {
    setOpen(!isOpen)
  }
  const { css } = useFela()
  const { label, description } = trackable

  if (!label || !description) return null

  return (
    <AccordionItem
      withArrow
      extend={({ theme }) => accordionItemStyle(theme, isOpen)}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      heading={`More information about ${startCase(label)}`}
    >
      <Markdown source={description} className={css(markdownStyle)} />
    </AccordionItem>
  )
}

export const ScoreCardsPageAbout = createFragmentContainer(
  ScoreCardsPageAboutComponent,
  {
    trackable: graphql`
      fragment ScoreCardsPageAbout_trackable on Trackable {
        description
        label
      }
    `,
  },
)
