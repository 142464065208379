// @flow

import React from 'react'
import { connect } from 'react-fela'

import Heading from 'react-ui/components/Heading'
import Card from 'shared/ui/Layout/Card/Card'
import { Button } from 'care-ui'

import OnboardingTemplate from './OnboardingTemplate'
import styleRules from './styles'

import { type FelaRulesType } from 'react-ui/typing'

type PropsType = {
  handleWizardStart: () => void,
  pageContent?: string | Node,
  pageTitle?: string | Node,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
}

const OnboardingIntro = ({
  styles = {},
  rules,
  handleWizardStart,
  pageTitle,
  pageContent,
}: PropsType) => (
  <OnboardingTemplate pageTitle={pageTitle} pageContent={pageContent}>
    <Card className={styles.cardContainer}>
      <Heading level={3} extend={rules.cardTitleIntro}>
        About you
      </Heading>
      <Button variant="primary" onClick={handleWizardStart}>
        Start
      </Button>
    </Card>
  </OnboardingTemplate>
)

export default connect(styleRules)(OnboardingIntro)
