// @flow

import { formatDistance } from 'date-fns/fp'

import { createFlash } from 'platform_web/containers/Flash'

export const handleEmailClick = (text: string): void => {
  navigator.clipboard
    .writeText(text)
    .then(() =>
      createFlash({
        message: 'Copied to clipboard.',
        type: 'success',
        timeout: 1500,
      }),
    )
    .catch(() =>
      createFlash({
        message: 'Copy failed.',
        type: 'error',
        timeout: 1500,
      }),
    )
}

export const getLastSeenText = (text: ?string): string => {
  if (!text) return 'N/A'
  return `${formatDistance(new Date())(new Date(text))} ago`
}

export const calculateAge = (birthDateString: string): number => {
  const birthDate = new Date(birthDateString)
  const today = new Date()

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age -= 1
  }

  return age
}

export const ageGenderDisplay = (
  dateText: ?string,
  genderText: string,
): string => {
  const age = dateText ? calculateAge(dateText) : 'N/A'
  return `${age} / ${genderText}`
}
