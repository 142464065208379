// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { requiredTextField } from 'shared/services/formik'
import { FormError } from 'shared/ui/Forms'
import s from 'shared/ui/Styles/AdminForm.scss'
import { Button, FlexContainer, FlexItem, Text } from 'care-ui'

import { FeedbackTopicSelectLoader } from './FeedbackTopicSelect'

import type { FelaStylesType } from 'react-ui/typing'
import type { FeedbackForm_viewer } from './__generated__/FeedbackForm_viewer.graphql'

type ValuesType = {
  +description: string,
  +subject: string,
  +topic: string,
}

type PropsType = {
  feedback: ValuesType,
  styles: FelaStylesType,
  viewer: FeedbackForm_viewer,
}

type FormPropsType = PropsType & {
  errors: { [key: string]: string },
  handleBlur: () => void,
  handleChange: () => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  setFieldValue: () => void,
  setValues: ({ [string]: string }) => void,
  touched: { [key: string]: boolean },
  values: ValuesType,
  viewer: FeedbackForm_viewer,
}

const styleRules = ({ theme }) => ({
  pullRight: {
    float: 'right',
  },
  input: {
    [theme.care.breakpoints.md]: {
      ...theme.care.typography.desktop.bodyLg,
    },
  },
})

const Form = (props: FormPropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    viewer,
    setValues,
    styles,
  } = props

  return (
    <form onSubmit={handleSubmit} noValidate className={s.adminForm}>
      <FlexContainer direction="column" gap="xs">
        <div>
          <Text as="label" size="lg" htmlFor="topic">
            Topic
          </Text>
          <FeedbackTopicSelectLoader
            id="topic"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.topic}
            viewer={viewer}
            setValues={setValues}
          />
          <FormError
            errors={errors}
            touched={touched}
            values={values}
            attr="topic"
          />
        </div>

        <div>
          <Text as="label" size="lg" htmlFor="subject">
            Subject
          </Text>
          <input
            id="subject"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Let us know how we can help"
            type="text"
            value={values.subject}
            className={styles.input}
          />
          <FormError errors={errors} touched={touched} attr="subject" />
        </div>

        <div>
          <Text as="label" size="lg" htmlFor="description">
            Description
          </Text>
          <textarea
            cols="112"
            rows="8"
            id="description"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Please include as much information as possible"
            value={values.description}
            className={styles.input}
          />

          <FormError errors={errors} touched={touched} attr="description" />
        </div>

        <FlexItem alignSelf="end">
          <Button
            variant="primary"
            type="submit"
            disabled={!values?.topic || !values.subject || !values.description}
            ariaLabel="Send"
            dataTestId="send"
          >
            Send
          </Button>
        </FlexItem>
      </FlexContainer>
    </form>
  )
}

const FeedbackForm = withFormik({
  mapPropsToValues: (props: PropsType) => {
    return {
      topic: props.feedback.topic,
      subject: props.feedback.subject,
      description: props.feedback.description,
    }
  },
  handleSubmit: (values: ValuesType, { props, resetForm }) => {
    const { onSubmit } = props

    if (onSubmit) {
      onSubmit(values, () => {
        resetForm()
      })
    }
  },
  validationSchema: yup.object().shape({
    topic: requiredTextField('Topic'),
    subject: requiredTextField('Subject'),
    description: requiredTextField('Description'),
  }),
})(Form)

export const FeedbackFormLoader = connect(styleRules)(
  createFragmentContainer(FeedbackForm, {
    viewer: graphql`
      fragment FeedbackForm_viewer on Viewer {
        ...FeedbackTopicSelect_viewer
        enums {
          feedback {
            topic_list {
              key
              value
            }
          }
        }
      }
    `,
  }),
)
