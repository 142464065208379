// @flow

import React from 'react'
import { connect } from 'react-fela'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  firstContent?: Array<string>,
  firstTitle?: string,
  secondContent?: Array<string>,
  secondTitle?: string,
  styles: FelaStylesType,
}
const styleRules = ({ theme }) => ({
  displayInline: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '67rem',
    margin: '1rem auto',
    [theme.breakpoints.queries.sm]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  },
})

const benefits_of_innowell = [
  'Results of the questionnaire are shared with your clinician to help support your mental health care.',
  'Immediate access to your results and a holistic overview of your health and wellbeing.',
  'Each health area has associated activities, called Care Options, that provide tools for improvement.',
  'Complete the questionnaire in one sitting, preferred, or come back over time if necessary.',
]

const important_notes = [
  'The Innowell platform is not a diagnostic tool.',
  'Your information is secure and strictly confidential.',
]

const UserQuestionnaireInfoWrapper = ({
  styles,
  firstContent,
  firstTitle,
  secondContent,
  secondTitle,
}: PropsType) => (
  <div className={styles.displayInline}>
    <QuestionnaireInfoCard
      title={firstTitle || 'Benefits of Innowell'}
      points={firstContent || benefits_of_innowell}
      avatar={Team}
    />

    <QuestionnaireInfoCard
      title={secondTitle || 'Important notes'}
      points={secondContent || important_notes}
      avatar={InfoCircle}
    />
  </div>
)

export default connect(styleRules)(UserQuestionnaireInfoWrapper)
