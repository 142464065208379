// @flow

import React, { useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { startCase } from 'lodash/fp'

import MultiStepProgressBar from 'components/MultiStepProgressBar'
import { OnboardingHeader } from 'components/OnboardingHeader'
import UserQuestionnaireInfoWrapper from 'components/UserQuestionnaire/UserQuestionnaireInfoWrapper'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import { useOnboardingStepContext } from 'react-ui/contexts/OnboardingStepContext'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import type { UserQuestionnaire_role } from './__generated__/UserQuestionnaire_role.graphql'
import type { UserQuestionnaire_user_questionnaire } from './__generated__/UserQuestionnaire_user_questionnaire.graphql'

type PropsType = {
  role: UserQuestionnaire_role,
  user_questionnaire: UserQuestionnaire_user_questionnaire,
}

export const UserQuestionnaireBase = ({
  user_questionnaire: {
    user: { id: answereeUserId },
    ensure_questionnaire: { id: questionnaireId },
    label,
    description,
  },
  role: { role_type: answererRoleType },
}: PropsType) => {
  const [isLoading, setLoading] = useState(false)
  const { currentOnboardingSteps } = useOnboardingStepContext()

  const { start } = useQuestionnaireSession({
    answereeUserId,
    questionnaireId,
    answererRoleType,
  })

  const handleClick = () => {
    setLoading(true)
    start()
  }

  const customHeader = (
    <OnboardingHeader
      title={startCase(label)}
      infoText={description}
      button={{
        label: 'Start',
        onClick: handleClick,
        disabled: isLoading,
      }}
    />
  )

  return (
    <>
      <MultiStepProgressBar steps={currentOnboardingSteps} />
      <ResponsiveLayout customHeader={customHeader} hasEmptyHeader>
        <UserQuestionnaireInfoWrapper />
      </ResponsiveLayout>
    </>
  )
}

export const UserQuestionnaireLoader = createFragmentContainer(
  UserQuestionnaireBase,
  {
    user_questionnaire: graphql`
      fragment UserQuestionnaire_user_questionnaire on UserQuestionnaire {
        label
        description
        progress {
          decimal
          done
          total
          step
        }
        first_user_questionnaires_question_set {
          finished
          questionnaires_question_set {
            id
          }
        }
        user {
          id
        }
        questionnaire {
          id
        }
        ensure_questionnaire {
          id
        }
      }
    `,
    role: graphql`
      fragment UserQuestionnaire_role on Role {
        ... on RoleInterface {
          id
          role_type
        }
      }
    `,
  },
)
