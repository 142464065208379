// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { HealthHistoryLoader } from 'components/Dashboard/HealthHistory'
import { MyEscalationsTableLoader } from 'components/Escalation/MyEscalationsTable'
import { WithAuthWrapper } from 'components/hoc/WithAuthWrapper'
import { DownloadReportTaskButton } from 'components/SummaryGraph/'
import Accordion, { AccordionItem } from 'react-ui/components/Accordion'
import Section from 'react-ui/components/Section'

import type { HealthHistoryPage_user } from './__generated__/HealthHistoryPage_user.graphql'

type PropsType = {
  user: HealthHistoryPage_user,
}

const containerRules = ({ theme }) => ({
  Section: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
})

export const HealthHistoryPageQuery = graphql`
  query HealthHistoryPage_Query {
    viewer {
      currentUser {
        ...HealthHistoryPage_user
      }
    }
  }
`

const HealthHistoryPage = ({ user, user: { escalations } }: PropsType) => {
  return (
    <>
      <DownloadReportTaskButton />
      <Section color="white" extend={containerRules}>
        <HealthHistoryLoader user={user} />
        <Accordion>
          <AccordionItem
            data-component-id="EscalationHistory"
            heading="Notifications history"
          >
            <MyEscalationsTableLoader escalations={escalations} />
          </AccordionItem>
        </Accordion>
      </Section>
    </>
  )
}

const HealthHistoryWrapped = WithAuthWrapper(HealthHistoryPage, {
  requiredPolicies: ['CAN_VIEW_OWN_HEALTH_HISTORY'],
})

const HealthHistoryPageLoader = createFragmentContainer(HealthHistoryWrapped, {
  user: graphql`
    fragment HealthHistoryPage_user on User {
      ...HealthHistory_user
      escalations {
        ...MyEscalationsTable_escalations
      }
    }
  `,
})

export default HealthHistoryPageLoader
