// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import KebabVertical from 'react-ui/assets/icons/kebab-vertical.svg'
import ClinicianNoteForm from 'react-ui/components/ClinicianNote/ClinicianNoteForm'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import useRouteParams from 'react-ui/hooks/useRouteParams'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button, DropDownMenu, DropDownMenuItem, Icon, Modal } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import type { ClinicianDropdown_individual } from './__generated__/ClinicianDropdown_individual.graphql'
import type { ClinicianDropdown_user } from './__generated__/ClinicianDropdown_user.graphql'

type PropsType = {
  individual: ClinicianDropdown_individual,
  user: ?ClinicianDropdown_user,
}

const ClinicianDropdown = ({ individual, user: currentUser }: PropsType) => {
  const { isOpen, closeModal, openModal } = useModal()
  const { user, tenant } = individual || {}
  const { summary_questionnaire } = tenant || {}
  const { staff_individuals_page_actions } = currentUser || {}
  const { scores } = staff_individuals_page_actions || {}
  const { id } = useRouteParams()
  const policies = usePolicies()

  const { start } = useQuestionnaireSession({
    answereeUserId: user?.id,
    answererRoleType: 'CLINICIAN',
    questionnaireId: summary_questionnaire?.id,
  })

  const shouldGiveInput =
    summary_questionnaire?.answerable &&
    summary_questionnaire?.id &&
    scores.includes('give_input')

  const assigned = individual?.duty_of_care_clinicians
    ?.map(clinician => clinician.user.id)
    .includes(currentUser?.id)

  const shouldAddNote =
    assigned &&
    policies.CAN_USE_CLINICIAN_NOTE &&
    policies.CAN_VIEW_CLINICIAN_LINKS &&
    scores.includes('add_note')

  const { clinician_notes } = user
  const { totalCount } = clinician_notes || {}
  const counter = totalCount > 0 ? ` (${totalCount})` : ''

  return (
    <>
      <DropDownMenu
        position="end"
        dropDownTrigger={
          <Button
            variant="secondary"
            ariaLabel="clinician dropdown"
            dataTestId="clinicianDropdown"
            tooltipText="Actions"
          >
            <Icon as={KebabVertical} />
          </Button>
        }
      >
        <>
          {shouldAddNote &&
            clinician_notes && (
              <DropDownMenuItem
                onClick={openModal}
              >{`Add note${counter}`}</DropDownMenuItem>
            )}

          {shouldGiveInput && (
            <DropDownMenuItem onClick={start}>Give input</DropDownMenuItem>
          )}

          {id && (
            <DropDownMenuItem
              to={{
                name: 'user_health_history',
                params: {
                  id,
                },
              }}
            >
              View health history
            </DropDownMenuItem>
          )}

          {id && (
            <DropDownMenuItem
              to={{
                name: 'user_profile',
                params: {
                  id,
                },
              }}
            >
              View profile
            </DropDownMenuItem>
          )}
        </>
      </DropDownMenu>

      <Modal isOpen={isOpen} closeModal={closeModal} heading="Add Note">
        <ClinicianNoteForm userId={user?.id} />
      </Modal>
    </>
  )
}

export default createFragmentContainer(ClinicianDropdown, {
  individual: graphql`
    fragment ClinicianDropdown_individual on IndividualRole {
      tenant {
        summary_questionnaire {
          id
          answerable
        }
      }
      user {
        id
        clinician_notes {
          totalCount
        }
        ...ClinicianNoteActivity_user
      }
      duty_of_care_clinicians {
        user {
          id
        }
      }
    }
  `,
  user: graphql`
    fragment ClinicianDropdown_user on User {
      id
      staff_individuals_page_actions {
        scores
      }
    }
  `,
})
