// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input__root___QwEAt{height:2.5rem;padding:0 1rem;border:2px solid #a3adbb;border-radius:5px;color:#3a4656;background-color:#fff;transition:all .25s ease-in-out}.Input__root___QwEAt:focus{border:2px solid #0f77d9}.Input__root___QwEAt::-webkit-input-placeholder,.Input__root___QwEAt::-moz-placeholder,.Input__root___QwEAt:-ms-input-placeholder,.Input__root___QwEAt:-moz-placeholder{color:#637691}.Input__root___QwEAt.Input__danger___eNEwC{border:2px solid #e53935}.Input__root___QwEAt.Input__warning___Q3vH2{border:2px solid #fb8c00}.Input__root___QwEAt.Input__success___VsNAR{border:2px solid #43a047}.Input__root___QwEAt.Input__info___I2iDr{border:2px solid #43a047}.Input__root___QwEAt:disabled{border:2px solid #d0d6de}.Input__fullWidth___c_pRE{display:block;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Input__root___QwEAt",
	"danger": "Input__danger___eNEwC",
	"warning": "Input__warning___Q3vH2",
	"success": "Input__success___VsNAR",
	"info": "Input__info___I2iDr",
	"fullWidth": "Input__fullWidth___c_pRE"
};
export default ___CSS_LOADER_EXPORT___;
