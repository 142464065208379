// @flow

import React from 'react'
import { useFela } from 'react-fela'

import Section from 'react-ui/components/Section'
import { Button, Container, Heading } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'
import { type ToPropType } from 'care-ui/molecules/Button/Button'

import {
  headerStyle,
  scoreCardsHeaderStyle,
  wrapperStyle,
} from './PageHeader.style'

type PropsType = {
  action?: any,
  children: string,
  linkTo: ToPropType,
}

const PageHeader = ({ children, linkTo, action }: PropsType) => {
  const { css } = useFela()

  return (
    <header className={css(headerStyle)}>
      <Section noPadding color="white">
        <Container extend={wrapperStyle}>
          <div className={css(scoreCardsHeaderStyle)}>
            <Heading level={1}>{children}</Heading>
            <Button
              to={linkTo}
              variant="text"
              ariaLabel="Back to dashboard"
              dataTestId="back-to-dashboard"
              leftIcon={ChevronLeft}
            >
              Back to dashboard
            </Button>
          </div>
          <div>{action && action}</div>
        </Container>
      </Section>
    </header>
  )
}

export default PageHeader
