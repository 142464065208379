// @flow

import React, { useState } from 'react'

import { Button, FlexContainer, TextArea } from 'care-ui'

import useCreateClinicianNote from './useCreateClinicianNote'

type PropsType = {
  onToggleOpen: () => void,
  userId: string,
}

const textAreaStyle = () => ({
  width: '100%',
  height: '100%',
})

const ClinicianNoteForm = ({ userId, onToggleOpen }: PropsType) => {
  const [clinicianNote, setClinicianNote] = useState('')
  const { commit, isInFlight } = useCreateClinicianNote()

  const onChange = e => {
    setClinicianNote(e.target.value)
  }

  const onSubmit = () => {
    commit({
      variables: {
        input: {
          clinician_note: {
            about_id: userId,
            text: clinicianNote,
          },
        },
      },
      onCompleted: () => {
        onToggleOpen()
        setClinicianNote('')
      },
    })
  }

  return (
    <FlexContainer justifyContent="end" gap="xs">
      <TextArea
        placeholder="Enter note"
        onChange={onChange}
        value={clinicianNote}
        extend={textAreaStyle}
        rows={13}
        dataTestId="textBox"
      />
      <Button
        onClick={onSubmit}
        variant="primary"
        disabled={isInFlight || !clinicianNote}
        dataTestId="save"
        ariaLabel="Save"
      >
        Save
      </Button>
    </FlexContainer>
  )
}

export default ClinicianNoteForm
