// @flow

import React from 'react'
import { useFela, withTheme } from 'react-fela'

import { usePolicies } from 'react-ui/hooks/usePolicies'
import useRouteParams from 'react-ui/hooks/useRouteParams'

import Button from '../Button/Button'

const styleRules = ({ theme }) => ({
  ...theme.NavigationTabWrapper,
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  paddingLeft: 0,
})

const styleRulesButton = ({ theme }) => ({
  ...theme.NavigationTab,
  ':first-child': {
    paddingLeft: 0,
  },
})

const styleRulesSelectedButton = ({ theme }) => ({
  ...theme.NavigationTabSelected,
  pointerEvents: 'none',
  ':first-child': {
    paddingLeft: 0,
  },
  ':not(:last-child)': {
    borderRight: '2px solid black',
  },
  ':not(:first-child)': {
    paddingLeft: '20px',
  },
})

const DashboardTabs = () => {
  const { id, currentRouteName } = useRouteParams()

  const { css } = useFela()
  const policies = usePolicies()

  const canViewIndividuals =
    policies.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT

  const dashboardRouteName = canViewIndividuals ? 'user_dashboard' : 'dashboard'

  return (
    id && (
      <div className={css(styleRules)} data-component-id="DashboardTabs">
        <Button
          type="button"
          extend={
            currentRouteName === dashboardRouteName
              ? styleRulesSelectedButton
              : styleRulesButton
          }
          to={{
            name: dashboardRouteName,
            params: {
              id,
            },
          }}
        >
          Dashboard
        </Button>
        <Button
          type="button"
          extend={
            currentRouteName === 'user_health_history'
              ? styleRulesSelectedButton
              : styleRulesButton
          }
          to={{
            name: 'user_health_history',
            params: {
              id,
            },
          }}
        >
          Health History
        </Button>
        <Button
          type="button"
          extend={
            currentRouteName === 'user_profile'
              ? styleRulesSelectedButton
              : styleRulesButton
          }
          to={{
            name: 'user_profile',
            params: {
              id,
            },
          }}
        >
          Profile
        </Button>
      </div>
    )
  )
}

export default withTheme(DashboardTabs)
