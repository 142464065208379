// @flow

import React from 'react'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../hooks/useGraphPagination'
import { type QueryVariableType } from '../hooks/useInsights'
import { query } from '../queries/SummaryQuestionnairesInsightsQuery'

import { type SummaryQuestionnairesInsightsQuery } from '../queries/__generated__/SummaryQuestionnairesInsightsQuery.graphql'

type SummaryQuestionnairesInsightsGraphProps = {
  loadSummaryQuestionnairesQuery: (queryVariables: QueryVariableType) => void,
  queryReference: PreloadedQuery<SummaryQuestionnairesInsightsQuery>,
  queryVariables: QueryVariableType,
}

const SummaryQuestionnairesInsightsGraph = (
  props: SummaryQuestionnairesInsightsGraphProps,
) => {
  const {
    queryVariables,
    queryReference,
    loadSummaryQuestionnairesQuery,
  } = props
  const queryResult = usePreloadedQuery(query, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.summary_questionnaires.series_categories',
  )
  const series = get(
    queryResult,
    'viewer.insights.summary_questionnaires.series',
  )

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    queryVariables,
    loadQuery: loadSummaryQuestionnairesQuery,
  })

  return (
    <GraphContainer
      heading="Summary Questionnaire"
      graphInfoText="This graph visualises the number of Summary Questionnaires that have been completed over the specified period. The top line will show the total number of questionnaires completed and the bottom line will show the total number of unique individuals that completed those questionnaires."
    >
      {series && (
        <Graph
          colors={[navy[400], navy[500]]}
          graphType="line"
          series={series}
          seriesCategories={seriesCategories}
          onPaginateLeft={() => onPaginate('left')}
          onPaginateRight={() => onPaginate('right')}
          isRightPaginationDisabled={isRightPaginationDisabled}
        />
      )}
    </GraphContainer>
  )
}

export default SummaryQuestionnairesInsightsGraph
