// @flow

import React, { Fragment } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { ConnectedTenantsListLoader } from 'components/ConnectedTenants/ConnectedTenantsList'
import { IndividualRolesListLoader } from 'components/ConnectedTenants/IndividualRolesList'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'

import type { ConnectedTenantsPage_user } from './__generated__/ConnectedTenantsPage_user.graphql'

type PropsType = {
  +user: ConnectedTenantsPage_user,
}

const ConnectedTenantsPage = (props: PropsType) => {
  const { user } = props

  const { roles, individual_tenants } = user

  return (
    <Fragment>
      <Section color="default">
        <Container>
          <Heading level={2}>Connected organisations and access</Heading>
          <p>
            On this page you can see the organisations and clinicians that you
            are working with
          </p>

          <Heading level={3}>Organisations</Heading>
          <p>
            To manage access to your data please contact the relevant service
          </p>
          <ConnectedTenantsListLoader tenants={individual_tenants} />

          <Heading level={3}>Clinicians</Heading>
          {roles && <IndividualRolesListLoader individuals={roles.nodes} />}
        </Container>
      </Section>
    </Fragment>
  )
}

export const ConnectedTenantsPageQuery = graphql`
  query ConnectedTenantsPage_Query {
    viewer {
      currentUser {
        ...ConnectedTenantsPage_user
      }
    }
  }
`

export const ConnectedTenantsPageLoader = createFragmentContainer(
  ConnectedTenantsPage,
  {
    user: graphql`
      fragment ConnectedTenantsPage_user on User {
        roles(roleTypes: [INDIVIDUAL]) {
          nodes {
            ... on IndividualRole {
              ...IndividualRolesList_individuals
            }
          }
        }
        individual_tenants {
          ...ConnectedTenantsList_tenants
        }
      }
    `,
  },
)
