// @flow

import React from 'react'
import { connect } from 'react-fela'

import Background from 'react-ui/components/Background'
import { type UiStyleProps } from 'shared/ui/Forms/Fields/RadioSet/RadioSet'
import { Lock } from 'shared/ui/Typography/Icons'
import type {
  AnswerSetType,
  QuestionnaireType,
} from 'shared/utils/SimpleQuestionnaireTypes'

import OnboardingQuestionsForm from './OnboardingQuestionsForm'

import type { FelaRulesType } from 'react-ui/typing'

type PropsType = UiStyleProps & {
  handleWizardComplete: (values: AnswerSetType) => void,
  questionnaire: QuestionnaireType,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
}

const OnboardingWizard = ({
  styles = {},
  rules,
  handleWizardComplete,
  questionnaire,
  uiStyle,
}: PropsType) => (
  <div className={styles.wizardContainer}>
    <div className={styles.asideContainer}>
      <Background
        backgroundName="primary"
        backgroundElements={['leavesHorizontal', 'cloudX1']}
        extend={rules}
      >
        <div className={styles.wizardContentContainer}>
          <p className={styles.wizardCardTitle}>About you</p>
          <div className={styles.wizardFooterContainer}>
            <Lock addedClassName={styles.wizardLockIcon} />
            <p className={styles.wizardFooterText}>
              All answers are secure and strictly confidential.
            </p>
          </div>
        </div>
      </Background>
    </div>
    <div className={styles.mainContentContainer}>
      <OnboardingQuestionsForm
        uiStyle={uiStyle}
        questionnaire={questionnaire}
        handleWizardComplete={handleWizardComplete}
      />
    </div>
  </div>
)

const styleRules = ({ theme: { palette, breakpoints } }) => ({
  // ----------- Background -----------
  container: {
    minHeight: 'auto',
    height: '270px',
    [breakpoints.queries.md]: {
      minHeight: '100vh',
    },
  },

  // ----------- Main Content -----------
  mainContentContainer: {
    className: 'OnboardingWizard__mainContent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '28px',
    [breakpoints.queries.md]: {
      marginTop: 0,
      height: '100vh',
      overflow: 'scroll',
      alignItems: 'flex-start',
    },
  },

  // ----------- Wizard -----------
  wizardContainer: {
    className: 'OnboardingWizard__wizardContainer',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.queries.md]: {
      flexDirection: 'row',
    },
  },

  asideContainer: {
    className: 'OnboardingWizard__asideContainer',
    [breakpoints.queries.md]: {
      minWidth: '570px',
    },
  },

  wizardContentContainer: {
    className: 'OnboardingWizard__wizardContentContainer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.component.primary.text,
    [breakpoints.queries.md]: {
      marginTop: '261px',
    },
  },

  wizardCircle: {
    marginBottom: '28px',
    boxShadow: 'inset 0 -3px 0 0 #0968C3, 0 6px 0 0 #0968C3',
    backgroundColor: palette.component.primary.accent,
  },

  wizardCardTitle: {
    marginBottom: '8px',
    fontSize: '29px',
    lineHeight: '56px',
  },

  wizardFooterContainer: {
    className: 'OnboardingWizard__wizardFooterContainer',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: '98px',
  },

  wizardLockIcon: {
    width: '18px',
    height: '28px',
    [breakpoints.queries.md]: {
      marginBottom: '14px',
    },
  },

  wizardFooterText: {
    fontSize: '14px',
    color: palette.component.primary.text,
    textAlign: 'center',
  },
  // ----------- end Wizard -----------
})

export default connect(styleRules)(OnboardingWizard)
