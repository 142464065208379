// @flow

import React, { useEffect } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'

import { commit as commitSwitchRole } from 'mutations/SwitchRole'
import { useRouterReset } from 'services/routeConfig/Router'
import { LoadingSpinner } from 'care-ui'

export default function() {
  const environment = useRelayEnvironment()
  const resetRouter = useRouterReset()
  const { router, match: { params: { roleId } } } = useRouter()

  useEffect(() => {
    function onCompleted({ userUpdate }) {
      if (userUpdate) {
        resetRouter()
        router.push(userUpdate.user.homepage_path)
      }
    }

    const variables = {
      input: {
        user: {
          selected_role_id: roleId,
        },
      },
      role_id: roleId,
    }

    function onError() {
      router.go(-1)
    }

    commitSwitchRole({
      environment,
      onCompleted,
      onError,
      variables,
    })
  }, [])

  return <LoadingSpinner message="Changing Roles" />
}
