// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import { useRouter } from 'found'
import { uniqueId } from 'lodash'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { Heading, Text } from 'care-ui'

import HealthCard from '../HealthCard'

import type { HealthCardList_user_trackables } from './__generated__/HealthCardList_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthCardList_user_trackables,
}

const containerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.care.radius.md,
  border: `1px solid ${theme.care.palette.border.subtle}`,
  color: theme.care.palette.text.positive,
  padding: `${theme.care.spacing.lg} ${theme.care.spacing.sm} `,
  [theme.care.breakpoints.queries.md]: {
    padding: theme.care.spacing.xl,
  },
})

const headerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.care.spacing.lg,
})

const subHeaderStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.care.spacing.xxs,
})

const cardContainerStyle = ({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.care.spacing.sm,
  justifyItems: 'center',
  [theme.care.breakpoints.queries.md]: {
    justifyItems: 'start',
    gridTemplateColumns: 'repeat(auto-fill, minmax(232px, 1fr))',
  },
})

const linkStyle = ({ theme }) => {
  const { care } = theme

  return {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: care.spacing.sm,
    ...care.typography.desktop.BodyLgBold,
    [care.breakpoints.queries.md]: {
      marginRight: care.spacing.xxs,
    },
  }
}

const healthCardListFragment = graphql`
  fragment HealthCardList_user_trackables on UserTrackable
    @relay(plural: true) {
    ...HealthCard_user_trackable
  }
`

const HealthCardList = ({ user_trackables: fragmentRef }: PropsType) => {
  const user_trackables = useFragment(healthCardListFragment, fragmentRef)

  const { router, match } = useRouter()
  const { params } = match
  const { id } = params

  const { css } = useFela()

  const { roleType } = useCurrentUserRoles()

  const isNotIndividual = roleType !== 'INDIVIDUAL'

  const onLinkClick = () => {
    router.push({
      name: 'user_assessment_requests',
      params: { id },
      query: { tab: '1' },
    })
  }

  const healthCards = user_trackables?.map(user_trackable => {
    return (
      <HealthCard
        key={uniqueId('health-card-')}
        user_trackable={user_trackable}
      />
    )
  })

  return (
    <div className={css(containerStyle)}>
      <div className={css(headerStyle)}>
        <div className={css(subHeaderStyle)}>
          <Heading level={2}>Latest Results</Heading>
          {isNotIndividual && (
            <div
              className={css(linkStyle)}
              role="button"
              tabIndex="0"
              onKeyPress={onLinkClick}
              onClick={onLinkClick}
            >
              View assessment library
            </div>
          )}
        </div>
        <Text>
          Click on a health card to find fact sheets, browse a number of care
          options and review your results
        </Text>
      </div>
      <div className={css(cardContainerStyle)}>{healthCards}</div>
    </div>
  )
}

export default HealthCardList
