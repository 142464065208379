// @flow

import React from 'react'
import createRender from 'found/createRender'

import PageError from '../pages/PageError'

import createConnectedRouter from './routeConfig/createConnectedRouter'

const rerouteToSignInPageWhen40X = router => {
  router.replace('/users/sign_in')
}

export default createConnectedRouter({
  render: createRender({
    renderError: ({ error, router }) => {
      switch (error.status) {
        case 401: {
          rerouteToSignInPageWhen40X(router)
          return null
        }
        case 422: {
          rerouteToSignInPageWhen40X(router)
          return null
        }
        case 404: {
          return (
            <PageError
              title="We can&apos;t find this page."
              errorCode={error.status}
            />
          )
        }
        case 500: {
          return (
            <PageError title="Oops!" errorCode={error.status}>
              <p>
                Something went wrong. Please try again later.<br />
                If you need immediate help, please contact your service or send
                us a support email.
              </p>
            </PageError>
          )
        }
        case 502: {
          return (
            <PageError title="Oops!" errorCode={error.status}>
              <p>
                Something&apos;s gone wrong, but it&apos;s likely temporary.
                Please wait a few moments and try refreshing the page or come
                back again later.<br />
                If you need immediate help, please contact your service or send
                us a support email.
              </p>
            </PageError>
          )
        }
        case 504: {
          return (
            <PageError title="Oops!" errorCode={error.status}>
              <p>
                Sorry, this page is taking way too long to load. Please wait a
                few moments and try refreshing the page or come back again
                later.<br />
                If you need immediate help, please contact your service or send
                us a support email.
              </p>
            </PageError>
          )
        }
        default: {
          return <PageError errorCode={error.status} />
        }
      }
    },
  }),
})
