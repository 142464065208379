// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useRouter } from 'found'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import Section from 'react-ui/components/Section'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { SuspenseLoader } from 'care-ui'

import SupportingSupporteeQuestionnaireSubmissionComponent from './SupportingSupporteeQuestionnaireSubmissionComponent'

const wrapperStyle = () => ({
  overflow: 'hidden',
  position: 'relative',
})

const informationStyle = () => ({
  maxWidth: '850px',
  margin: '0 auto',
})

const listQuery = graphql`
  query SupportingSupporteeQuestionnaireSubmissionPage_Query(
    $id: ID!
    $supporteeUserId: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        contract
        current_role {
          ... on SupportPersonRole {
            id
            supportees {
              ...SupportingSupporteeQuestionnaireSubmissionComponent_supportees
            }
          }
        }
        ...SupportingSupporteeQuestionnaireSubmissionComponent_user
          @arguments(
            supporteeUserId: $supporteeUserId
            count: $count
            cursor: $cursor
          )
      }
    }
  }
`

const SupportingSupporteeQuestionnaireSubmissionPageLoader = () => {
  const { css } = useFela()

  const { match } = useRouter()
  const { params: { id: supporteeUserId } } = match
  const { id } = useCurrentUser()
  const { node } = useLazyLoadQuery(
    listQuery,
    { id, supporteeUserId, count: 10, cursor: null },
    { fetchPolicy: 'network-only' },
  )
  const { current_role, contract } = node || {}
  const { supportees: supporteesFragmentRef } = current_role || {}

  return (
    <div className={css(wrapperStyle)}>
      <Section center color="white">
        <Container>
          <Heading level={2} extend={informationStyle}>
            Thank you for successfully completing this questionnaire.
          </Heading>
        </Container>
        <Container>
          <SupportingSupporteeQuestionnaireSubmissionComponent
            user={node}
            supportees={supporteesFragmentRef}
            supporteeUserId={supporteeUserId}
          />
        </Container>
      </Section>

      <Section center color="white">
        <div className={css(informationStyle)}>
          <div>
            <Heading level={3}>What else can I do now?</Heading>
            <small>
              There’s no right or wrong way to go about supporting someone,
              often the first step is having an open conversation. While this
              can be difficult to do, check out the&nbsp;
              {contract === 'aus' && (
                <>
                  <Link to="https://www.beyondblue.org.au/about-us/about-our-work/youthbeyondblue/the-check-in-app">
                    Check-in
                  </Link>&nbsp;or&nbsp;
                  <Link to="https://au.reachout.com/tools-and-apps/chats-for-life">
                    Chats for Life
                  </Link>&nbsp;website&nbsp;
                </>
              )}
              {contract === 'ahs' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                    Kids Help Phone
                  </Link>&nbsp;or&nbsp;
                  <Link to="https://albertahealthservices.ca/amh/amh.aspx">
                    Alberta Health Services
                  </Link>&nbsp;website&nbsp;
                </>
              )}
              {contract === 'london' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                    Kids Help Phone
                  </Link>&nbsp;website, or contact&nbsp;
                  <Link to="mail:femapresearch@lhsc.on.ca">
                    femapresearch@lhsc.on.ca
                  </Link>, or call 519-646-6000, ext. 65196&nbsp;
                </>
              )}
              to help you plan better for these conversations.
            </small>
          </div>
          <div>
            <Heading level={3}>What is this questionnaire for?</Heading>
            <small>
              This questionnaire will help to provide different perspectives on
              the health and well being of the person you are caring for.
            </small>
          </div>

          <div>
            <Heading level={3}>Who will see your answers?</Heading>
            <small>
              Your answers will be seen by the person you are caring for and
              their connected clinicians.
            </small>
          </div>

          <div>
            <Heading level={3}>
              How often should you answer this questionnaire?
            </Heading>
            <small>
              You can re-answer the questionnaire at anytime by logging into the
              Innowell platform. The person you are caring for can also send an
              email request for you to update the questionnaire.
            </small>
          </div>
        </div>
      </Section>
    </div>
  )
}

const SupportingSupporteeQuestionnaireSubmissionPage = () => (
  <SuspenseLoader>
    <SupportingSupporteeQuestionnaireSubmissionPageLoader />
  </SuspenseLoader>
)

export default SupportingSupporteeQuestionnaireSubmissionPage
