// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import Background from 'react-ui/components/Background'
import { Column, Container, Row } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'

import type { FelaRulesType } from 'react-ui/typing'

type PropsType = {
  children?: Node,
  pageContent?: string | Node,
  pageTitle?: string | Node,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
}

const styleRules = ({ theme: { palette } }) => ({
  container: {
    className: 'OnboardingTemplate',
    marginBottom: '56px',
    position: 'relative',
  },

  firstRow: {
    marginTop: '98px',
    marginBottom: '40px',
  },

  row: {
    justifyContent: 'center',
    color: palette.component.primary.text,
  },

  primaryTitle: {
    color: palette.component.primary.text,
    textAlign: 'center',
  },

  introText: {
    width: '70%',
    margin: '0 auto',
    fontSize: '18px',
    textAlign: 'center',
  },
})

const OnboardingTemplate = ({
  styles = {},
  rules,
  pageTitle,
  pageContent,
  children,
}: PropsType) => (
  <Background
    backgroundName="primary"
    backgroundElements={['leavesHorizontal', 'clouds']}
  >
    <Container extend={rules.container}>
      <Row extend={rules.row} className={styles.firstRow}>
        <Column>
          <Heading level={2} extend={rules.primaryTitle}>
            {pageTitle}
          </Heading>
          <p className={styles.introText}>{pageContent}</p>
        </Column>
      </Row>
      <Row extend={rules.row}>
        <Column>{children}</Column>
      </Row>
    </Container>
  </Background>
)

export default connect(styleRules)(OnboardingTemplate)
