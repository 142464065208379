// @flow

import { routeQuestionnaire } from 'services/questionnaire/routeQuestionnaire'
import { routeQuestionSet } from 'services/questionnaire/routeQuestionnaireQuestionSet'
import { type UserQuestionnairesQuestionSet_user_questionnaires_question_set } from 'platform_web/components/UserQuestionnairesQuestionSet/__generated__/UserQuestionnairesQuestionSet_user_questionnaires_question_set.graphql'

type LinkToType = {
  role: Object,
  roleType: ?string,
  router: Object,
  uqQuestionSet: UserQuestionnairesQuestionSet_user_questionnaires_question_set,
}

/**
 * Function to route to Next Question Set or Thank You page as appropriate
 *
 * @param {UserQuestionnairesQuestionSet_user_questionnaires_question_set} uqQuestionSet
 * @param {Object} role
 *
 */
export const linkTo = ({
  uqQuestionSet,
  role,
  router,
  roleType,
}: LinkToType) => {
  const nextQqs = uqQuestionSet.next_user_questionnaires_question_set
  const { user, user_questionnaire } = uqQuestionSet
  const assessmentRequestId = user_questionnaire.assessment_request?.id

  const answererRoleType = roleType || role.role_type

  if (nextQqs) {
    routeQuestionSet({
      answererRoleType,
      answereeUserId: user.id,
      questionnaireQuestionSetId: nextQqs.questionnaires_question_set.id,
      assessmentRequestId,
      router,
    })
  } else {
    const {
      questionnaires_question_set: { questionnaire: { id: questionnaireId } },
    } = uqQuestionSet
    routeQuestionnaire({
      answererRoleType,
      answereeUserId: user.id,
      questionnaireId,
      assessmentRequestId,
      router,
    })
  }
}
