/*
 * @flow
 */

import React from 'react'

import { HoverTracker } from 'services/analytics'
import { trackHelpNowButtonInteraction } from 'services/analytics/events'
import { Button, Modal } from 'care-ui'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'
import useModal from 'care-ui/molecules/hooks/useModal'

import HelpNowModalBody from './HelpNowModalBody'
import useHelpNowVisitLink from './useHelpNowVisitLink'

type Props = {
  +tenant?: ?{
    +id: string,
  },
}

const HelpNowButton = ({ tenant }: Props) => {
  const { commit } = useHelpNowVisitLink()
  const { md } = useBreakpoints()

  const { isOpen, closeModal, openModal } = useModal()

  const handleHelpNowClick = () => {
    if (tenant) {
      commit({
        variables: {
          input: {},
        },
        onCompleted: () => {
          openModal()
        },
      })
    }

    openModal()
  }

  return (
    <>
      <HoverTracker action={trackHelpNowButtonInteraction}>
        {trackers => (
          <Button
            variant="danger"
            ariaLabel={md ? 'I need help now' : 'Need help'}
            {...(trackers: any)}
            onClick={handleHelpNowClick}
            dataTestId="help-now"
          >
            {md ? 'I need help now' : 'Need help'}
          </Button>
        )}
      </HoverTracker>

      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        heading="Need Help Now?"
        width="780px"
      >
        <HelpNowModalBody tenantId={tenant?.id} />
      </Modal>
    </>
  )
}

export default HelpNowButton
