// @flow

import React, { Component } from 'react'

import { updateRegistrationRequest } from 'shared/services/api/registration'
import Sentry from 'shared/services/sentry'
import { Layout as l } from 'shared/ui/Styles'
import EditRegistrationForm, {
  type EditRegistrationFormValues,
} from 'platform_web/pages/authentication/components/EditRegistrationForm'
import { Heading } from 'care-ui'

import type {
  FormikSetStatusFunc,
  FormikSetSubmittingFunc,
} from 'services/flow'

type StateType = {
  updated: boolean,
}

class EditRegistrationPage extends Component<{}, StateType> {
  state = { updated: false }

  updateRegistration = (
    values: EditRegistrationFormValues,
    setSubmitting: FormikSetSubmittingFunc,
    setStatus: FormikSetStatusFunc,
  ) => {
    updateRegistrationRequest(values, {
      onSuccess: () => {
        this.setState({ updated: true })
      },
      onError: error => {
        switch (error.response.status) {
          case 422:
            error.response
              .json()
              .then(body => {
                const message = this.formatError(body.errors)
                setStatus({ error: message })
                setSubmitting(false)
                return body
              })
              .catch(Sentry.captureException)
            break

          default:
            setStatus({
              error:
                "Sorry, an error occured with our server. We've notified our team so that they can address it. Please try again.",
            })
            setSubmitting(false)
            break
        }
      },
    })
    setSubmitting(false)
  }

  formatError(errors: any) {
    if (errors.password) {
      return `Sorry, your new password is ${errors.password[0]}`
    }
    return "Sorry, we couldn't change your password"
  }

  renderedForm(updated: boolean) {
    if (updated) {
      return (
        <div>
          <Heading
            level={3}
            extend={({ theme }) => ({
              color: theme.palette.component.primary.base,
            })}
          >
            Success
          </Heading>
          <p>Your password has been updated.</p>
        </div>
      )
    }

    return (
      <div>
        <Heading level={3} margin="xs">
          Update your password
        </Heading>
        <div className={l['my-2']} />
        <EditRegistrationForm onSubmit={this.updateRegistration} />
      </div>
    )
  }

  render() {
    const { updated } = this.state
    return this.renderedForm(updated)
  }
}

export default EditRegistrationPage
