// @flow

import React from 'react'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import Banner, { BannerContent } from 'react-ui/components/Banner'
import { commit } from 'mutations/UserNotificationsMarkAsRead'

import type { UserNotifications_user } from './__generated__/UserNotifications_user.graphql'

type PropsType = { user: UserNotifications_user }

const UserNotificationsComponent = ({
  user: { id, user_notifications },
}: PropsType) => {
  const environment = useRelayEnvironment()
  if (!user_notifications || user_notifications.length < 1) {
    return null
  }

  const markNotificationsRead = () => {
    commit({
      environment,
      variables: {
        input: {
          userId: id,
        },
      },
    })
  }

  return (
    <Banner onClick={markNotificationsRead}>
      {user_notifications.map(notification => {
        return (
          <BannerContent key={notification.id}>
            {notification.text}
          </BannerContent>
        )
      })}
    </Banner>
  )
}

export const UserNotifications = createFragmentContainer(
  UserNotificationsComponent,
  {
    user: graphql`
      fragment UserNotifications_user on User {
        id
        user_notifications(read: false) {
          id
          user_id
          text
          read
        }
      }
    `,
  },
)
