// @flow

import React from 'react'

import { BasicCellRenderer } from 'react-ui/components/DataGrid'
import UserActivityCard from 'react-ui/components/UserActivityCard'
import type {
  ClinicianIndividualAssignmentStatusEnumType,
  IndividualsPropsType,
  IndividualType,
} from 'pages/Staff/Individuals/IndividualsTable'
import {
  buildCombinedSorter,
  createSortByBoolean,
} from 'shared/services/sorters'

import userColumnConfig from './userColumnConfig'

export default {
  ...userColumnConfig,
  width: '14rem',
  format: null,
  sort: buildCombinedSorter([
    createSortByBoolean('escalated'),
    userColumnConfig.sort,
  ]),
  cellRenderer: <T = any>(
    data: {
      assignmentStatus: ClinicianIndividualAssignmentStatusEnumType,
      individual: IndividualType,
      showFlag: boolean,
    },
    key: string,
    props: IndividualsPropsType<T>,
  ) => {
    const { individual, showFlag } = data
    if (!individual) return null
    const { escalated, user, do_with_clinician: { requested } } = individual

    return (
      <BasicCellRenderer key={key} {...(props: any)}>
        <UserActivityCard
          escalated={escalated}
          showFlag={showFlag}
          user={user}
          clinHelpQuestionnaire={requested}
          status="staff"
          staff
        />
      </BasicCellRenderer>
    )
  },
}
