// @flow

import React from 'react'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { Form } from 'shared/ui/Forms'
import { EnterPassword, Input } from 'shared/ui/Forms/Fields'
import { Button } from 'care-ui'

import s from './PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type EditRegistrationFormValues = {
  current_password: string,
  password: string,
  password_confirmation: string,
}

type EditRegistrationFormPropsType = FormikProps & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  values: EditRegistrationFormValues,
}

const EditRegistrationForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  status = {},
  touched,
  values,
}: EditRegistrationFormPropsType) => (
  <Form onSubmit={handleSubmit}>
    {status.error && <p className={s.mainError}>{status.error}</p>}
    <Input
      errors={errors}
      fullWidth
      label="Current Password"
      name="current_password"
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Enter old password"
      required
      touched={touched}
      type="password"
      value={values.current_password}
      style={{ maxWidth: '25rem' }}
    />

    <EnterPassword
      errors={errors}
      fullWidth
      hasConfirm
      lightGrayLabel
      onBlur={handleBlur}
      onChange={handleChange}
      touched={touched}
      userPasswordConfirm={values.password_confirmation}
      value={values.password}
    />

    <p>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </p>
  </Form>
)

export default withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({
    current_password: '',
    password: '',
    password_confirmation: '',
  }),

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: yup.object().shape({
    current_password: yup
      .string()
      .label('Current Password')
      .required('Current password is required'),
    password: yup
      .string()
      .label('Password')
      .min(6)
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .label('Password confirmation')
      .oneOf([yup.ref('password'), null], 'New passwords must match')
      .required('Password confirmation is required'),
  }),
})(EditRegistrationForm)
