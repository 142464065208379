// @flow

import { useEffect, useState } from 'react'

import {
  type OnboardingStepTypes,
  individualOnboardingStepNumber,
  individualOnboardingSteps,
} from 'services/onboardingSteps'

const useOnboardingSteps = () => {
  // prettier-ignore
  const [onboardingSteps, setOnboardingSteps ] = useState<OnboardingStepTypes>(individualOnboardingSteps)

  // prettier-ignore
  const [ currentStep, setCurrentStep ] = useState<number>(0)

  const {
    RESEARCH_AND_DATA_SHARING,
    PERSONAL_DETAILS,
    INITIAL_QUESTIONNAIRE,
    VIEW_RESULTS,
  } = individualOnboardingStepNumber

  useEffect(
    () => {
      setOnboardingSteps((prevState: OnboardingStepTypes) => {
        const updatedState = { ...prevState }

        if (currentStep >= PERSONAL_DETAILS) {
          if (updatedState.PERSONAL_DETAILS) {
            updatedState.PERSONAL_DETAILS.active = true
          }
        }
        if (currentStep >= RESEARCH_AND_DATA_SHARING) {
          if (updatedState.RESEARCH_AND_DATA_SHARING) {
            updatedState.RESEARCH_AND_DATA_SHARING.active = true
          }
          updatedState.PERSONAL_DETAILS.completed = true
        }
        if (currentStep >= INITIAL_QUESTIONNAIRE) {
          if (updatedState.RESEARCH_AND_DATA_SHARING) {
            updatedState.RESEARCH_AND_DATA_SHARING.completed = true
          }
          updatedState.INITIAL_QUESTIONNAIRE.active = true
        }
        if (currentStep >= VIEW_RESULTS) {
          updatedState.INITIAL_QUESTIONNAIRE.completed = true
          updatedState.VIEW_RESULTS.active = true
        }
        return updatedState
      })
    },
    [currentStep],
  )

  return { onboardingSteps, setCurrentStep }
}

export default useOnboardingSteps
