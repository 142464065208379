// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Circle__Circle___RWcS4{box-sizing:content-box;border-radius:50%;display:flex;flex-direction:column}.Circle__Circle___RWcS4.Circle__outline--lightGrey___TRykR{border:2px solid #d0d6de}.Circle__Circle___RWcS4.Circle__outline--green___OJnOR{border:2px solid #4caf50}.Circle__Circle___RWcS4.Circle__outline--white___gSMD_{border:2px solid #fff}.Circle__Circle___RWcS4.Circle__outline--primary___zXqZl{background:#0f77d9}.Circle__Circle___RWcS4.Circle__outline--darkBlue___wk9Vx{background:#0358b1}.Circle__Circle___RWcS4.Circle__outline--grey___Q6iXz{background:#637691}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__darkBlue___ogSbB{background-color:#0358b1}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__green___bMLWj{background-color:#4caf50}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__primary____BNHh{background-color:#0f77d9}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__secondary___N3Hfn{background-color:#01786f}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__white___jbfV_{background-color:#fff}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__lightGrey___SiBF3{background:#d0d6de}.Circle__Circle___RWcS4.Circle__fill___CNI5d.Circle__grey___Sq0cf{background:#637691}.Circle__Circle___RWcS4.Circle__materialShadow--green___TiwxE{box-shadow:0 5px 0 #388e3c}.Circle__Circle___RWcS4.Circle__materialShadow--primary___TIZY6{box-shadow:0 5px 0 #0358b1}.Circle__Circle___RWcS4.Circle__materialShadow--secondary___gacHD{box-shadow:0 5px 0 #006059}.Circle__shadow___Xnt45{box-shadow:0 1px 2px rgba(0,0,0,.2)}.Circle__centered___siJlk{margin:auto auto}.Circle__m___nOfLY{width:1rem;height:1rem;padding:.5rem}.Circle__ml___r__MT{width:2rem;height:2rem;padding:.75rem}.Circle__l___hu8x6{width:2.5rem;height:2.5rem;padding:1rem}.Circle__xl___DxZKC{width:6rem;height:6rem;padding:1.5rem}.Circle__Circle___RWcS4 span{flex:1;-ms-flex:1 1 auto;margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Circle": "Circle__Circle___RWcS4",
	"outline--lightGrey": "Circle__outline--lightGrey___TRykR",
	"outline--green": "Circle__outline--green___OJnOR",
	"outline--white": "Circle__outline--white___gSMD_",
	"outline--primary": "Circle__outline--primary___zXqZl",
	"outline--darkBlue": "Circle__outline--darkBlue___wk9Vx",
	"outline--grey": "Circle__outline--grey___Q6iXz",
	"fill": "Circle__fill___CNI5d",
	"darkBlue": "Circle__darkBlue___ogSbB",
	"green": "Circle__green___bMLWj",
	"primary": "Circle__primary____BNHh",
	"secondary": "Circle__secondary___N3Hfn",
	"white": "Circle__white___jbfV_",
	"lightGrey": "Circle__lightGrey___SiBF3",
	"grey": "Circle__grey___Sq0cf",
	"materialShadow--green": "Circle__materialShadow--green___TiwxE",
	"materialShadow--primary": "Circle__materialShadow--primary___TIZY6",
	"materialShadow--secondary": "Circle__materialShadow--secondary___gacHD",
	"shadow": "Circle__shadow___Xnt45",
	"centered": "Circle__centered___siJlk",
	"m": "Circle__m___nOfLY",
	"ml": "Circle__ml___r__MT",
	"l": "Circle__l___hu8x6",
	"xl": "Circle__xl___DxZKC"
};
export default ___CSS_LOADER_EXPORT___;
