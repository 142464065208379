// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useSelector } from 'react-redux'
import { graphql, useFragment } from 'react-relay'
import { get, getOr } from 'lodash/fp'

import { generateInitials } from 'shared/services/user'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Avatar } from 'care-ui'

import HealthCardMenu from './HealthCardMenu'

import type { HealthCardFooter_user_trackable$key } from './__generated__/HealthCardFooter_user_trackable.graphql'

type PropsType = {
  +user_trackable: HealthCardFooter_user_trackable$key,
}

const containerStyle = ({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  paddingLeft: theme.care.spacing.sm,
  paddingRight: theme.care.spacing.sm,
  backgroundColor: theme.care.palette.surface.subtle,
  position: 'relative',
})

const linkStyle = ({ theme }) => {
  const { care } = theme

  return {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: care.spacing.sm,
    ...care.typography.desktop.bodySmBold,
    [care.breakpoints.queries.md]: {
      marginRight: care.spacing.xxs,
    },
  }
}

const ctaStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const healthCardFragment = graphql`
  fragment HealthCardFooter_user_trackable on UserTrackable {
    ...HealthCardMenu_user_trackable
    participants {
      name
    }
    primary_user_questionnaire {
      questionnaire {
        id
      }
    }
    requires_support
    refresh_required
    trackable {
      id
    }
    user {
      id
    }
  }
`

const HealthCardFooter = ({ user_trackable: fragmentRef }: PropsType) => {
  const user_trackable = useFragment(healthCardFragment, fragmentRef)
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const { css } = useFela()

  const participants = get('participants')(user_trackable)
  const updateRequired = get('refresh_required')(user_trackable) && isIndividual
  const requireSupport = get('requires_support')(user_trackable)
  const questionnaireId = getOr(
    null,
    'primary_user_questionnaire.questionnaire.id',
  )(user_trackable)

  const groupInitials = participants?.map(({ name }) => {
    const initials = generateInitials(name)
    return initials
  })

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  return (
    <div className={css(containerStyle)}>
      <Avatar avatarInitials={groupInitials} requireSupport={requireSupport} />

      <div className={css(ctaStyle)}>
        {updateRequired && (
          <div
            className={css(linkStyle)}
            role="button"
            tabIndex="0"
            onKeyPress={start}
            onClick={start}
          >
            Update
          </div>
        )}
        <HealthCardMenu user_trackable={user_trackable} />
      </div>
    </div>
  )
}

export default HealthCardFooter
