// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BaseIcon__root___WMbUh{display:inline-block}.BaseIcon__root___WMbUh svg{width:100%;height:100%}.BaseIcon__right___Hp3D0{transform:rotate(90deg)}.BaseIcon__down___a9_23{transform:rotate(180deg)}.BaseIcon__left___pTGij{transform:rotate(-90deg)}.BaseIcon__translucent___B6Zul{opacity:.5}.BaseIcon__black____md_j svg>g{fill:#3a4656}.BaseIcon__lightBlue___f7CVD svg>g{fill:#4eabff}.BaseIcon__lightGrey___M75Rk svg>g{fill:#d0d6de}.BaseIcon__darkGreen___VyGLo svg>g{fill:#008479}.BaseIcon__white___Ndxkb svg>g{fill:#fff}.BaseIcon__primary___vOQLi svg>g{fill:#0968c3}.BaseIcon__secondary___wQany svg>g{fill:#0968c3}.BaseIcon__success___bnBX1 svg>g{fill:#388e3c}.BaseIcon__warning___Xdk32 svg>g{fill:#ef6c00}.BaseIcon__danger___HJQAp svg>g{fill:#c62828}.BaseIcon__shadow--secondary___MhbNS svg{filter:drop-shadow(0 5px 0 #006059)}.BaseIcon__shadow--primary___KndLs svg{filter:drop-shadow(0 5px 0 #0358b1)}.BaseIcon__s___oBNvB{width:.5rem;height:.5rem}.BaseIcon__ms___RT8ve{width:.7rem;height:.7rem}.BaseIcon__m___oFdft{width:1rem;height:1rem}.BaseIcon__ml___LfR8Q{width:1.5rem;height:1.5rem}.BaseIcon__l___thqjo{width:2rem;height:2rem}.BaseIcon__xl___a4Wpx{width:4rem;height:4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "BaseIcon__root___WMbUh",
	"right": "BaseIcon__right___Hp3D0",
	"down": "BaseIcon__down___a9_23",
	"left": "BaseIcon__left___pTGij",
	"translucent": "BaseIcon__translucent___B6Zul",
	"black": "BaseIcon__black____md_j",
	"lightBlue": "BaseIcon__lightBlue___f7CVD",
	"lightGrey": "BaseIcon__lightGrey___M75Rk",
	"darkGreen": "BaseIcon__darkGreen___VyGLo",
	"white": "BaseIcon__white___Ndxkb",
	"primary": "BaseIcon__primary___vOQLi",
	"secondary": "BaseIcon__secondary___wQany",
	"success": "BaseIcon__success___bnBX1",
	"warning": "BaseIcon__warning___Xdk32",
	"danger": "BaseIcon__danger___HJQAp",
	"shadow--secondary": "BaseIcon__shadow--secondary___MhbNS",
	"shadow--primary": "BaseIcon__shadow--primary___KndLs",
	"s": "BaseIcon__s___oBNvB",
	"ms": "BaseIcon__ms___RT8ve",
	"m": "BaseIcon__m___oFdft",
	"ml": "BaseIcon__ml___LfR8Q",
	"l": "BaseIcon__l___thqjo",
	"xl": "BaseIcon__xl___a4Wpx"
};
export default ___CSS_LOADER_EXPORT___;
