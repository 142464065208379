// @flow

import React, { Suspense, useEffect } from 'react'
import { useQueryLoader } from 'react-relay'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import useRouter from 'found/useRouter'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { PageHeader } from 'platform_web/components'
import {
  Box,
  Callout,
  Container,
  FlexContainer,
  FlexItem,
  LoadingSpinner,
} from 'care-ui'
import alertInfo from 'care-ui/atoms/icons/alert-info.svg'

import ClinicianLoginsInsightsGraph from './components/ClinicianLoginsIsightsGraph'
import IndividualLoginsInsightsGraph from './components/IndividualLoginsInsightsGraph'
import IndividualOnboardingInsightsGraph from './components/IndividualOnboardingInsightsGraph'
import InsightsPageHeaderActions from './components/PageHeaderActions'
import StbEscalationsInsightsGraph from './components/StbEscalationsGraph'
import SummaryQuestionnairesInsightsGraph from './components/SummaryQuestionnairesInsightsGraph'
import useInsights from './hooks/useInsights'
import { query as clinicianLoginsQuery } from './queries/ClinicianLoginsInsightsQuery'
import { query as individualLoginsQuery } from './queries/IndividualLoginsInsightsQuery'
import { query as individualOnboardingQuery } from './queries/IndividualOnboardingInsightsQuery'
import { query as stbEscalationsQuery } from './queries/StbEscalationsInsightsQuery'
import { query as summaryQuestionnairesQuery } from './queries/SummaryQuestionnairesInsightsQuery'

const defaultQueryVariables = {
  interval: 'monthly',
  start_date: format(startOfMonth(subMonths(new Date(), 5)), 'yyyy-MM-dd'),
  end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
}

const Insights = () => {
  const [
    individualOnboardingQueryReference,
    loadIndividualOnboardingQuery,
  ] = useQueryLoader(individualOnboardingQuery)

  const [
    stbEscalationsQueryReference,
    loadStbEscalationsQuery,
  ] = useQueryLoader(stbEscalationsQuery)

  const [
    summaryQuestionnairesQueryReference,
    loadSummaryQuestionnairesQuery,
  ] = useQueryLoader(summaryQuestionnairesQuery)

  const [
    clinicianLoginsQueryReference,
    loadClinicianLoginsQuery,
  ] = useQueryLoader(clinicianLoginsQuery)

  const [
    individualLoginsQueryReference,
    loadIndividualLoginsQuery,
  ] = useQueryLoader(individualLoginsQuery)

  // For owner role, we need to set default tenant_id filter
  const { currentUser, ownerTenants, hasOwnerRole } = useCurrentUserRoles()
  const ownerDefaultTenantId = ownerTenants?.[0]?.id

  const useInsightsPayload = useInsights({ defaultQueryVariables })

  const { queryVariables, setQueryVariables } = useInsightsPayload
  const { interval = 'monthly' } = queryVariables

  const { router } = useRouter()

  // Feature Toggle
  const displayNewInsights = window.GLOBALS?.features?.NEW_INSIGHTS

  useEffect(() => {
    if (!displayNewInsights) {
      router.replace({
        name: 'insights',
      })
    }
  }, [])

  // Load query when query variables change from interval selector
  useEffect(
    () => {
      if (!currentUser.loggedIn) return

      if (hasOwnerRole && !queryVariables.owner_tenant_id) return

      loadIndividualOnboardingQuery(queryVariables)
      loadStbEscalationsQuery(queryVariables)
      loadSummaryQuestionnairesQuery(queryVariables)
      loadClinicianLoginsQuery(queryVariables)
      loadIndividualLoginsQuery(queryVariables)
    },
    [queryVariables],
  )

  // Preload all the query on insights page load
  // Require current user redux state to be loaded for getting all the ownerTenants for owner roles
  useEffect(
    () => {
      if (!currentUser.loggedIn) return

      setQueryVariables({ owner_tenant_id: ownerDefaultTenantId })
    },
    [currentUser],
  )

  const queryReady =
    individualLoginsQueryReference &&
    clinicianLoginsQueryReference &&
    summaryQuestionnairesQueryReference &&
    stbEscalationsQueryReference &&
    individualOnboardingQueryReference

  const calloutText = (
    <span>
      Welcome to your Insights page. We’ll be updating it regularly for you with
      new features, so if there are metrics you’d like to see or you have
      questions, let us know at{' '}
      <a href="mailto:feedback@innowell.org">feedback@innowell.org</a>.
    </span>
  )

  return (
    <>
      <PageHeader
        pageTitle="Insights"
        pageAction={
          <InsightsPageHeaderActions
            interval={interval}
            setQueryVariables={setQueryVariables}
          />
        }
      />

      <Container>
        <Box marginY="sm">
          <Callout
            calloutText={calloutText}
            variant="warning"
            calloutIcon={alertInfo}
          />
        </Box>

        {queryReady && (
          <>
            <Box marginY="sm" minHeight="500px">
              <FlexContainer gap="xs" wrap="nowrap">
                {individualOnboardingQueryReference && (
                  <FlexItem flex={1}>
                    <Suspense>
                      <IndividualOnboardingInsightsGraph
                        queryVariables={queryVariables}
                        queryReference={individualOnboardingQueryReference}
                        loadIndividualOnboardingQuery={
                          loadIndividualOnboardingQuery
                        }
                      />
                    </Suspense>
                  </FlexItem>
                )}

                {individualLoginsQueryReference && (
                  <FlexItem flex={1}>
                    <Suspense>
                      <IndividualLoginsInsightsGraph
                        queryVariables={queryVariables}
                        queryReference={individualLoginsQueryReference}
                        loadIndividualLoginsQuery={loadIndividualLoginsQuery}
                      />
                    </Suspense>
                  </FlexItem>
                )}
              </FlexContainer>
            </Box>

            {summaryQuestionnairesQueryReference && (
              <Box marginY="sm" minHeight="500px">
                <Suspense>
                  <SummaryQuestionnairesInsightsGraph
                    queryVariables={queryVariables}
                    queryReference={summaryQuestionnairesQueryReference}
                    loadSummaryQuestionnairesQuery={
                      loadSummaryQuestionnairesQuery
                    }
                  />
                </Suspense>
              </Box>
            )}

            <Box marginY="sm" minHeight="500px">
              <FlexContainer gap="xs" wrap="nowrap">
                {clinicianLoginsQueryReference && (
                  <FlexItem flex={1}>
                    <Suspense>
                      <ClinicianLoginsInsightsGraph
                        queryVariables={queryVariables}
                        queryReference={clinicianLoginsQueryReference}
                        loadClinicianLoginsQuery={loadClinicianLoginsQuery}
                      />
                    </Suspense>
                  </FlexItem>
                )}

                {stbEscalationsQueryReference && (
                  <FlexItem flex={1}>
                    <Suspense>
                      <StbEscalationsInsightsGraph
                        queryVariables={queryVariables}
                        queryReference={stbEscalationsQueryReference}
                        loadStbEscalationsQuery={loadStbEscalationsQuery}
                      />
                    </Suspense>
                  </FlexItem>
                )}
              </FlexContainer>
            </Box>
          </>
        )}

        {!queryReady && <LoadingSpinner />}
      </Container>
    </>
  )
}

export default Insights
