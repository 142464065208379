// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'

import { usePolicies } from 'platform_web/features/Policies'
import { SuspenseLoader, Tabs } from 'care-ui'

import { ActivityLogCardContainer } from './ActivityLog'
import { ClinicianNoteCardContainer, ClinicianNoteModal } from './ClinicianNote'

type PropsType = {
  assigned: ?boolean,
  userId: string,
}

const panelStyle = ({ theme }) => ({
  padding: `${theme.care.spacing.lg} ${theme.care.spacing.sm}`,
  background: theme.care.palette.surface.default,
  border: `1px solid ${theme.care.palette.border.subtle}`,
  borderRadius: theme.care.radius.md,
  width: '100%',
})

const tabContainerStyle = ({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.care.spacing.xs,
})

const containerStyle = () => ({
  height: '453px',
  overflowX: 'hidden',
})

const ActivityPanel = ({ userId, assigned }: PropsType) => {
  const { css } = useFela()
  const policies = usePolicies()

  const canAddNote =
    assigned &&
    policies.CAN_USE_CLINICIAN_NOTE &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  const recentActivityContent = (
    <SuspenseLoader>
      <ActivityLogCardContainer userId={userId} />
    </SuspenseLoader>
  )

  const clinicianNoteContent = (
    <SuspenseLoader>
      <ClinicianNoteCardContainer userId={userId} />
    </SuspenseLoader>
  )

  const tabs = [
    {
      label: 'Recent Activity',
      content: recentActivityContent,
      disabled: !canAddNote,
      dataTestId: 'recentActivityTab',
    },
    ...((canAddNote && [
      {
        label: 'Notes',
        content: clinicianNoteContent,
        dataTestId: 'notesTab',
      },
    ]) ||
      []),
  ]

  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = index => {
    setActiveTab(index)
  }

  const activeContent = tabs[activeTab].content

  const isClinicianNoteLog = activeTab === 1

  return (
    <div className={css(panelStyle)}>
      <div className={css(tabContainerStyle)}>
        <Tabs tabs={tabs} defaultTab={0} onChange={handleTabChange} />
        {isClinicianNoteLog && <ClinicianNoteModal userId={userId} />}
      </div>
      <div className={css(containerStyle)}>{activeContent}</div>
    </div>
  )
}

export default ActivityPanel
