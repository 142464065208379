// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { textStyle } from './TextNew.style'

type TextProps = {
  as?: string,
  children: string,
  color?: 'positive' | 'title',
  dataTestId?: string,
  typography?:
    | 'display'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'bodyLg'
    | 'bodyLgBold'
    | 'bodyLgLink'
    | 'bodyMd'
    | 'bodyMdBold'
    | 'bodyMdLink'
    | 'bodySm'
    | 'bodySmBold'
    | 'bodySmLink',
}

const TextNew = (props: TextProps) => {
  const { children, as: Component = 'p' } = props

  const { css } = useFela({ ...props })

  return <Component className={css(textStyle)}>{children}</Component>
}

export default TextNew
