// @flow

import { type ThemeType } from 'care-ui/atoms'

type ModalStyleProps = {
  theme: ThemeType,
  width: string,
}
export const modalStyle = ({ theme, width }: ModalStyleProps) => ({
  background: theme.care.palette.surface.default,
  width: width || '70vw',
  overflow: 'hidden',
  padding: '0px',
  borderRadius: theme.care.radius.md,
  border: 'none',
  '::backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
})

export const modalHeader = ({ theme }: { theme: ThemeType }) => ({
  borderBottom: `1px solid ${theme.care.palette.border.subtle}`,
  padding: `${theme.care.spacing.sm} ${theme.care.spacing.lg}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const modalBody = ({ theme }: { theme: ThemeType }) => ({
  padding: `${theme.care.spacing.sm} ${theme.care.spacing.lg}`,
})
