// @flow

import React from 'react'
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay'
import useRouter from 'found/useRouter'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

const query = graphql`
  query AssessmentRequestsCheckPageQuery($assessmentRequestId: ID!) {
    viewer {
      assessment_request(id: $assessmentRequestId) {
        questionnaire_answer_sets {
          id
        }
      }
    }
  }
`

const AssessmentRequestsCheckPage = () => {
  const environment = useRelayEnvironment()
  const { match, router } = useRouter()
  const { params } = match || {}
  const { assessment_request_id: assessmentRequestId } = params || {}

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
  })

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{ assessmentRequestId }}
      render={response => (
        <QueryRendererLoadingIndicator response={response}>
          {({ viewer }) => {
            const { assessment_request } = viewer || {}
            const { questionnaire_answer_sets } = assessment_request || {}

            if (assessment_request && !questionnaire_answer_sets.length) {
              start()
            }

            router.replace({
              name: 'dashboard',
            })

            return null
          }}
        </QueryRendererLoadingIndicator>
      )}
    />
  )
}

export default AssessmentRequestsCheckPage
