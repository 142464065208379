// @flow

import React from 'react'
import { connect } from 'react-fela'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink: string,
  closeModal?: () => void,
  disconnect: () => void,
}

const styleRules = () => ({
  container: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '10rem',
    margin: 'auto',
    height: 'auto',
  },
  list: {
    margin: '20px 30px',
    '>li': {
      padding: '5px 0 ',
    },
  },
  note: {
    textAlign: 'center',
    margin: '20px 0',
    padding: '0 15%',
    lineHeight: '20px',
  },
  buttonGroup: {
    marginTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '>a': {
      margin: '10px',
    },
    '>button': {
      margin: '10px',
    },
  },
})

const FitbitDisconnectActivity = ({
  closeModal,
  disconnect,
  styles,
  rules,
}: PropTypes) => (
  <Container extend={rules.container}>
    <FitbitLogo className={styles.logo} />
    <Heading level={2} extend={() => ({ textAlign: 'center' })}>
      Are you sure you would like to disconnect?
    </Heading>

    <p className={styles.note}>
      Syncing your fitbit with the Platform allows you to view your health and
      wellbeing information in one place.
    </p>

    <div className={styles.buttonGroup}>
      <Button
        variant="secondary"
        onClick={closeModal}
        dataTestId="fitbit-disconnect-no"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={disconnect}
        dataTestId="fitbit-disconnect-yes"
      >
        Disconnect
      </Button>
    </div>
  </Container>
)

export default connect(styleRules)(FitbitDisconnectActivity)
