// @flow

import React from 'react'
import { graphql } from 'react-relay'

import StaffCliniciansTable from 'components/Staff/StaffCliniciansTable'
import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs'
import TableDataLoader from 'platform_web/features/TableDataLoader/TableDataLoader'
import { Container, Divider } from 'care-ui'

import useCliniciansTable from '../hooks/useCliniciansTable'

import CliniciansTableControl from './ClinciansTableControl'

const StaffCliniciansPagePaginatedQuery = graphql`
  query StaffCliniciansPage_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $sort: [RoleSortable!]
  ) {
    viewer {
      currentUser {
        current_role {
          ... on RoleInterface {
            id
            tenant {
              ...StaffCliniciansPage_tenant
                @arguments(
                  count: $count
                  cursor: $cursor
                  search: $search
                  sort: $sort
                )
            }
          }
        }
      }
    }
  }
`

const StaffCliniciansTableFragment = {
  tenant: graphql`
    fragment StaffCliniciansPage_tenant on Tenant
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        search: { type: "String" }
        sort: { type: "[RoleSortable!]" }
      ) {
      roles(
        roleTypes: [CLINICIAN]
        first: $count
        after: $cursor
        search: $search
        sort: $sort
      ) @connection(key: "StaffCliniciansPage_roles") {
        edges {
          node {
            ... on ClinicianRole {
              id
              legal_name
              preferred_name
              ...StaffCliniciansTable_clinicians
            }
          }
        }
      }
    }
  `,
}

const StaffCliniciansPage = () => {
  const useIndividualTablePayload = useCliniciansTable()
  const { count, queryVariables } = useIndividualTablePayload

  return (
    <>
      <nav>
        <Container>
          <StaffNavigationTabs />
        </Container>
        <Divider />
      </nav>

      <main>
        <Container>
          {/* Table Controls */}
          <CliniciansTableControl
            useIndividualTablePayload={useIndividualTablePayload}
          />

          {/* Table Data */}
          <TableDataLoader
            query={StaffCliniciansPagePaginatedQuery}
            fragments={StaffCliniciansTableFragment}
            queryVariables={queryVariables}
            getFragments={data => data.viewer.currentUser.current_role}
          >
            {({ relay, tenant }) => {
              const clinicians = tenant.roles.edges.map(({ node }) => node)
              return (
                <>
                  <StaffCliniciansTable clinicians={clinicians} />

                  {relay.hasMore() && (
                    <LoadMore handleClick={() => relay.loadMore(count)} />
                  )}
                </>
              )
            }}
          </TableDataLoader>
        </Container>
      </main>
    </>
  )
}

export const StaffCliniciansPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy([
    'CAN_VIEW_LEAD_CLINICIAN_ADMIN_NAMESPACE',
    'CAN_LIST_CLINICIANS',
  ])

  if (isLoading) return null

  return isAuthorized ? <StaffCliniciansPage /> : <UnauthorizedPage />
}
