// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import parser from 'html-react-parser'

import { longDateTimeFormat } from 'services/dateTime'
import { Text } from 'care-ui'

import ActivityLogChangeSet from './ActivityLogChangeSet'

import type { ActivityLogCard_activityLog$key } from './__generated__/ActivityLogCard_activityLog.graphql'

type PropsType = {
  activityLog: ActivityLogCard_activityLog$key,
}

const cardStyle = ({ theme }) => ({
  padding: `${theme.care.spacing.xxs} ${theme.care.spacing.sm}`,
  background: theme.care.palette.surface.subtle,
  borderRadius: theme.care.radius.sm,
  width: '-webkit-fill-available',
})

const dateStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  marginBottom: theme.care.spacing.xxs,
})

const infoStyle = () => ({
  display: 'block',
  whiteSpace: 'pre-wrap',
})

const fragment = graphql`
  fragment ActivityLogCard_activityLog on ActivityLog {
    display_text
    occured_at
    ...ActivityLogChangeSet_activityLog
  }
`

const ActivityLogCard = ({ activityLog: fragmentRef }: PropsType) => {
  const activityLog = useFragment(fragment, fragmentRef)
  const { display_text, occured_at } = activityLog
  const { css } = useFela()

  const parsedDisplayText = parser(display_text)
  const parsedOccuredAt = longDateTimeFormat(new Date(occured_at))

  return (
    <div className={css(cardStyle)} data-testid="activityContainer">
      <Text size="sm" color="title" extend={dateStyle} dataTestId="date">
        {parsedOccuredAt}
      </Text>
      <Text
        size="lg"
        color="title"
        extend={infoStyle}
        dataTestId="activityText"
      >
        {parsedDisplayText}
      </Text>
      <ActivityLogChangeSet activityLog={activityLog} />
    </div>
  )
}

export default ActivityLogCard
