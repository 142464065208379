// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import {
  graphql,
  useFragment,
  usePaginationFragment,
  useRelayEnvironment,
} from 'react-relay'
import { filter, find, flatMap, get, map, remove, some, uniqueId } from 'lodash'

import SupportingSupporteeQuestionnaire from 'pages/Supporting/SupportingSupporteeQuestionnaire'
import { commit as commitUserQuestionnaireSubmit } from 'mutations/UserQuestionnaireSubmit'
import { jsonSessionStorage } from 'services/browserStorage'
import { Button } from 'care-ui'

import type { SupportingSupporteeQuestionnaireSubmissionComponent_supportees$key } from './__generated__/SupportingSupporteeQuestionnaireSubmissionComponent_supportees.graphql'
import type { SupportingSupporteeQuestionnaireSubmissionComponent_user$key } from './__generated__/SupportingSupporteeQuestionnaireSubmissionComponent_user.graphql'

type PropsType = {
  supporteeUserId: string,
  supportees: SupportingSupporteeQuestionnaireSubmissionComponent_supportees$key,
  user: SupportingSupporteeQuestionnaireSubmissionComponent_user$key,
}

const buttonContainerStyle = () => ({
  display: 'flex',
  maxWidth: '45rem',
  justifyContent: 'end',
  margin: '2rem auto 0',
})

const supporteeFragment = graphql`
  fragment SupportingSupporteeQuestionnaireSubmissionComponent_supportees on IndividualRole
    @relay(plural: true) {
    user {
      id
      legal_name
    }
    supporting_user_questionnaire {
      ready_to_complete
      ensure_questionnaire {
        id
        label
      }
    }
  }
`

const paginationFragment = graphql`
  fragment SupportingSupporteeQuestionnaireSubmissionComponent_user on User
    @argumentDefinitions(
      supporteeUserId: { type: "ID" }
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(
      queryName: "SupportingSupporteeQuestionnaireSubmissionComponentPaginationQuery"
    ) {
    id
    current_role {
      ... on SupportPersonRole {
        assessment_request_questionnaires(
          supportee_user_id: $supporteeUserId
          first: $count
          after: $cursor
        )
          @connection(
            key: "SupportingSupporteeQuestionnaireSubmissionComponent_assessment_request_questionnaires"
          ) {
          edges {
            node {
              questionnaire_answer_set {
                ready_to_complete
              }
              questionnaire {
                id
                label
              }
              assessment_request {
                id
                individual {
                  id
                  legal_name
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const SupportingSupporteeQuestionnaireSubmissionComponent = ({
  supportees: supporteesFragmentRef,
  user,
  supporteeUserId,
}: PropsType) => {
  const [readyQuestionnaires, setReadyQuestionnaires] = useState([])
  const [submittedQuestionnaires, setSubmittedQuestionnaires] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const environment = useRelayEnvironment()

  const { css } = useFela()
  const supportees = useFragment(supporteeFragment, supporteesFragmentRef)
  const { data } = usePaginationFragment(paginationFragment, user) || {}

  const { current_role } = data || {}
  const { assessment_request_questionnaires } = current_role || {}
  const { edges } = assessment_request_questionnaires || {}
  const assessmentRequestQuestionnaires = map(edges, 'node')

  const questionnairesCopy = [...assessmentRequestQuestionnaires]
  const summaryQuestionnaires = remove(
    questionnairesCopy,
    ({ questionnaire }) => questionnaire?.label === 'Summary Questionnaire',
  )
  const sortedQuestionnaires = [...questionnairesCopy, ...summaryQuestionnaires]

  const currentSupportee = find(
    supportees,
    supportee => supportee.user.id === supporteeUserId,
  )

  const ensureQuestionnaireId = get(
    currentSupportee,
    'supporting_user_questionnaire.ensure_questionnaire.id',
  )

  useEffect(
    () => {
      const { supporting_user_questionnaire } = currentSupportee || {}
      const { ready_to_complete: ready, ensure_questionnaire } =
        supporting_user_questionnaire || {}

      if (ready)
        setReadyQuestionnaires(prev => [...prev, ensure_questionnaire.id])
    },
    [currentSupportee],
  )

  useEffect(() => {
    const readyQuestionnaireIds = flatMap(
      sortedQuestionnaires,
      ({ questionnaire_answer_set, questionnaire }) => {
        return questionnaire_answer_set?.ready_to_complete
          ? questionnaire?.id
          : []
      },
    )
    setReadyQuestionnaires(prev => [...prev, ...readyQuestionnaireIds])
  }, [])

  const onSubmit = () => {
    setSubmitting(true)
    readyQuestionnaires.forEach(questionnaireId => {
      commitUserQuestionnaireSubmit({
        environment,
        variables: {
          input: {
            questionnaireId,
            answereeId: supporteeUserId,
          },
        },
        onCompleted: () => {
          setSubmitting(false)
          setSubmittedQuestionnaires(prev => [...prev, questionnaireId])
          setReadyQuestionnaires(prev =>
            filter(prev, id => id !== questionnaireId),
          )
          if (questionnaireId === ensureQuestionnaireId) {
            jsonSessionStorage.setItem('submittedSummaryQuestionnaire', true)
          }
        },
        onError: () => {
          setSubmitting(false)
        },
      })
    })
  }

  const renderSummaryQuestionnaires = () => {
    const { user: supporteeUser, supporting_user_questionnaire } =
      currentSupportee || {}
    const { id: answereeUserId, legal_name: supporteeName } =
      supporteeUser || {}

    const { ensure_questionnaire } = supporting_user_questionnaire || {}

    const { id: summaryQuestionnaireId, label } = ensure_questionnaire || {}

    const isSubmitted = some(
      submittedQuestionnaires,
      id => id === summaryQuestionnaireId,
    )
    const isReady = some(
      readyQuestionnaires,
      id => id === summaryQuestionnaireId,
    )

    const summaryRequested = some(
      summaryQuestionnaires,
      ({ assessment_request }) => {
        const {
          individual: { user: { id: currentUserId } },
        } = assessment_request
        return currentUserId === answereeUserId
      },
    )

    const summaryAnswered =
      !isReady &&
      !isSubmitted &&
      jsonSessionStorage.getItem('submittedSummaryQuestionnaire')

    const shouldNotRender = summaryRequested || summaryAnswered

    if (shouldNotRender) return null

    return (
      <SupportingSupporteeQuestionnaire
        key={uniqueId('summary-questionnaire-')}
        questionnaireId={summaryQuestionnaireId}
        label={label}
        answereeUserId={answereeUserId}
        supporteeName={supporteeName}
        ready={isReady}
        submitted={isSubmitted}
      />
    )
  }

  const renderAssessmentRequestQuestionnaires = () => {
    return sortedQuestionnaires.map(({ questionnaire, assessment_request }) => {
      const { id: questionnaireId, label: questionnaireLabel } = questionnaire
      const { individual } = assessment_request || {}

      const {
        user: { id: answereeUserId },
        legal_name: supporteeName,
      } = individual

      const isSubmitted = some(
        submittedQuestionnaires,
        id => id === questionnaireId,
      )
      const isReady = some(readyQuestionnaires, id => id === questionnaireId)

      return (
        <SupportingSupporteeQuestionnaire
          key={uniqueId('assessment-request-questionnaire-')}
          questionnaireId={questionnaireId}
          label={questionnaireLabel}
          answereeUserId={answereeUserId}
          supporteeName={supporteeName}
          assessmentRequestId={assessment_request?.id}
          ready={isReady}
          submitted={isSubmitted}
        />
      )
    })
  }

  const disable = readyQuestionnaires.length === 0 || submitting

  return (
    <>
      {renderAssessmentRequestQuestionnaires()}
      {renderSummaryQuestionnaires()}
      <div className={css(buttonContainerStyle)}>
        <Button
          dataTestId="submitQuestionnaire"
          disabled={disable}
          variant="primary"
          onClick={() => onSubmit()}
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </>
  )
}

export default SupportingSupporteeQuestionnaireSubmissionComponent
