// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ count = 2, theme }) => ({
  columnCount: count,
  columnGap: `calc(${theme.Grid.gutter} * 2)`,
})

export default createComponentWithProxy(styleRules)
