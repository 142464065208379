// @flow

import { useState } from 'react'

const useModal = () => {
  // prettier-ignore
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return {
    isOpen,
    setIsOpen,
    openModal,
    closeModal,
  }
}

export default useModal
