// @flow

import React, { type Node, useContext } from 'react'

import { type OnboardingStepTypes } from 'services/onboardingSteps'

type PropsType = {
  children: Node,
  currentOnboardingSteps: OnboardingStepTypes,
  setCurrentStep: (num: number) => void,
}

const OnboardingStepContext: any = React.createContext({})
export const OnboardingStepContextConsumer = OnboardingStepContext.Consumer
export const useOnboardingStepContext = () => {
  const onboardingSteps = useContext(OnboardingStepContext)
  return onboardingSteps
}

export const OnboardingStepContextProvider = ({
  children,
  currentOnboardingSteps,
  setCurrentStep,
}: PropsType) => (
  <OnboardingStepContext.Provider
    value={{ currentOnboardingSteps, setCurrentStep }}
  >
    {children}
  </OnboardingStepContext.Provider>
)

export default OnboardingStepContext
