// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import { Container } from 'react-ui/components/Grid'
import PopOver, {
  PopOverDoneFooter,
  PopOverSearchContents,
} from 'react-ui/components/PopOver'
import { commit as commitRemoveSupportAssignment } from 'mutations/SupportPersonAssignment/Remove'
import { Button } from 'care-ui'

import SupportingSupporteeCheckBox from './SupportingSupporteeCheckBox'

import type { SupportingSupporteeRemoveModal_individual_assignments$key } from './__generated__/SupportingSupporteeRemoveModal_individual_assignments.graphql'

type PropsType = {
  individualAssignments: SupportingSupporteeRemoveModal_individual_assignments$key,
}

const containerStyle = () => ({
  container: {
    width: '450px',
    ':before': {
      display: 'none',
    },
  },
  tether: {
    minHeight: '400px',
  },
  scrollPane: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
})

const listContainerStyle = () => ({
  maxHeight: '346px',
  overflowY: 'auto',
  marginTop: '5px',
  overflowX: 'hidden',
})

const assignmentFragment = graphql`
  fragment SupportingSupporteeRemoveModal_individual_assignments on SupportPersonAssignment
    @relay(plural: true) {
    id
    individual {
      id
      legal_name
    }
  }
`

const SupportingSupporteeRemoveModal = ({
  individualAssignments: fragRef,
}: PropsType) => {
  const [selectedAssignments, setSelectedAssignments] = useState([])
  const individualAssignments = useFragment(assignmentFragment, fragRef)
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const renderTrigger = ({ onToggleOpen }) => (
    <Button onClick={onToggleOpen} variant="text">
      Remove yourself as support person
    </Button>
  )

  const onSubmit = onToggleOpen => {
    if (selectedAssignments.length > 0) {
      selectedAssignments.forEach(id => {
        commitRemoveSupportAssignment({
          environment,
          variables: {
            input: {
              id,
            },
          },
        })
      })
    }
    onToggleOpen()
  }

  return (
    <PopOver
      attachment="middle center"
      targetModifier="visible"
      target={document.body}
      extend={containerStyle}
      overlay
      renderTrigger={renderTrigger}
      renderFooter={({ onToggleOpen }) => (
        <PopOverDoneFooter
          onToggleOpen={() => onSubmit(onToggleOpen)}
          label={selectedAssignments.length === 0 ? 'Close' : 'Remove'}
        />
      )}
    >
      <Container className={css(listContainerStyle)}>
        <PopOverSearchContents
          data={individualAssignments}
          searchFields={[{ path: 'individual.legal_name' }]}
        >
          {({ searchResult }) => (
            <>
              {searchResult.map(({ id, individual }) => (
                <SupportingSupporteeCheckBox
                  key={uniqueId('supporting-supportee-checkbox-')}
                  assignmentId={id}
                  label={individual?.legal_name}
                  selectedAssignments={selectedAssignments}
                  setSelectedAssignments={setSelectedAssignments}
                />
              ))}
            </>
          )}
        </PopOverSearchContents>
      </Container>
    </PopOver>
  )
}

export default SupportingSupporteeRemoveModal
