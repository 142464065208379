// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useRouter } from 'found'
import { uniqueId } from 'lodash/fp'

import { usePolicies } from 'platform_web/features/Policies'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Text } from 'care-ui'

type PropsType = {
  questionnaireId: string,
  trackableId: string,
  userId: string,
}

const containerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  gap: theme.care.spacing.lg,
  padding: `${theme.care.spacing.xxs} ${theme.care.spacing.lg}`,
  [theme.care.breakpoints.queries.md]: {
    padding: theme.care.spacing.sm,
  },
})

const optionStyle = () => ({
  cursor: 'pointer',
})

const HealthCardMenuOptions = ({
  questionnaireId,
  userId,
  trackableId,
}: PropsType) => {
  const { css } = useFela()
  const policies = usePolicies()
  const { router } = useRouter()
  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  const routeToOptions = () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: userId,
        trackable_id: trackableId,
      },
    })
  }

  const routeToAnswers = () => {
    router.push({
      name: 'user_questionnaire_details',
      params: {
        id: userId,
        questionnaire_id: questionnaireId,
      },
    })
  }

  const viewCardDetails = (
    <div
      onClick={routeToOptions}
      onKeyPress={routeToOptions}
      role="button"
      tabIndex="0"
    >
      Browse care options
    </div>
  )

  const answerQuestions = policies.CAN_CREATE_USER_QUESTIONNAIRE ? (
    <div onClick={start} onKeyPress={start} role="button" tabIndex="0">
      Retake questionnaire
    </div>
  ) : null

  const viewAnswers = (
    <div
      onClick={routeToAnswers}
      onKeyPress={routeToAnswers}
      role="button"
      tabIndex="0"
    >
      View answers
    </div>
  )

  const options = [viewCardDetails, answerQuestions, viewAnswers].filter(
    value => !!value,
  )

  return (
    <div className={css(containerStyle)}>
      {options.map(option => (
        <Text extend={optionStyle} key={uniqueId('health-card-menu-option-')}>
          {option}
        </Text>
      ))}
    </div>
  )
}

export default HealthCardMenuOptions
