// @flow

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useRouter } from 'found'

import { HealthPreferences } from 'platform_web/features/HealthCard'
import { Button, Container, Grid, GridItem, Section } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import { containerStyle } from './DashboardPreferences.style'

const query = graphql`
  query DashboardPreferencesQuery {
    viewer {
      currentUser {
        extensiveUserTrackables: user_trackables(
          kinds: [text, additional_assessment]
        ) {
          ...HealthPreferences_user_trackables
        }
        homepage_path
      }
    }
  }
`

const DashboardPreferences = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const { router } = useRouter()

  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])

  const { homepage_path } = currentUser

  useEffect(
    () => {
      if (!isIndividual) {
        router.replace(homepage_path)
      }
    },
    [isIndividual],
  )

  return (
    <Section>
      <Container extend={containerStyle}>
        <Grid>
          <GridItem>
            <Button
              to="dashboard"
              variant="text"
              ariaLabel="Back to Dashboard"
              dataTestId="back-to-dashboard"
              iconLeft={ChevronLeft}
            >
              Back to Dashboard
            </Button>
          </GridItem>
          <GridItem>
            <HealthPreferences
              user_trackables={currentUser.extensiveUserTrackables}
            />
          </GridItem>
        </Grid>
      </Container>
    </Section>
  )
}

export default DashboardPreferences
