// @flow

import React, { type Node } from 'react'
import { Field as FormikField } from 'formik'
import { capitalize } from 'lodash/fp'

import { Checkbox, Input } from 'care-ui'

type FormFieldType = {
  component?: Node,
  id: string,
  initialValue?: string | boolean | number,
  label?: string,
  placeholder?: string,
  type: 'checkbox' | 'text' | 'email' | 'password' | 'component',
}

type FormFieldProps = {
  formField: FormFieldType,
}

const FormField = (props: FormFieldProps) => {
  const { formField: { id, label, type, placeholder, component } } = props

  // If passed component, which is not form element, return early with the component
  if (type === 'component' && component) {
    return component
  }

  return (
    <FormikField name={id}>
      {formikFieldProps => {
        const { field, form: { touched, errors } } = formikFieldProps
        const error = touched[id] ? errors[id] || errors[capitalize(id)] : ''

        // Checkbox
        if (type === 'checkbox') {
          return (
            <Checkbox
              inputAttributes={{ id, label, ...field }}
              dataTestId={`${id}Checkbox`}
            />
          )
        }

        return (
          <Input
            id={id}
            label={label}
            type={type}
            placeholder={placeholder}
            error={error}
            labelDataTestId={`${id}Label`}
            fieldDataTestId={`${id}Field`}
            {...field}
          />
        )
      }}
    </FormikField>
  )
}

export default FormField
