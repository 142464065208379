// @flow

import React, { Fragment } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import withRouter from 'found/withRouter'
import { flowRight } from 'lodash/fp'

import Spacer from 'react-ui/components/Spacer'
import { commit as commitFeedbackCreate } from 'mutations/Feedback/SubmitFeedback'
import { Heading, Text } from 'care-ui'

import { FeedbackFormLoader } from './FeedbackForm'

import type { FeedbackPage_viewer } from './__generated__/FeedbackPage_viewer.graphql'

type PropsType = {
  relay: Object,
  viewer: FeedbackPage_viewer,
}

const emptyFeedback = {
  topic: '',
  subject: '',
  description: '',
}

const FeedbackPage = (props: PropsType) => {
  const { viewer, relay: { environment } } = props

  const onFormSubmit = (values: Object, resetForm: () => void) => {
    commitFeedbackCreate({
      environment,
      viewer,
      onCompleted: resetForm,
      variables: {
        input: {
          feedback: {
            topic: values.topic,
            subject: values.subject,
            description: values.description,
          },
        },
      },
    })
  }

  return (
    <Fragment>
      <Heading level={3} margin="xs">
        Contact us
      </Heading>

      <Text>
        Your feedback is important to us. It helps us to continue improving how
        the Innowell Platform works best for you.
      </Text>

      <Spacer units={1} />

      <Text>
        <strong>What if I am reporting on behalf of an individual?</strong>
        <Spacer units={0.5} />
        For privacy purposes, if reporting on behalf of an individual, please do
        not include any sensitive and personal information of the individual
        user (e.g. email address or user name). Innowell will be responsible for
        responding to your query. Any follow-up with the individual thereafter
        should be managed through you and your service.
      </Text>

      <Spacer units={1} />

      <FeedbackFormLoader
        id="contactUsForm"
        onSubmit={onFormSubmit}
        feedback={emptyFeedback}
        viewer={viewer}
      />
    </Fragment>
  )
}

export const FeedbackPageQuery = graphql`
  query FeedbackPage_Query {
    viewer {
      ...FeedbackPage_viewer
    }
  }
`

const withRelay = component =>
  createFragmentContainer(component, {
    viewer: graphql`
      fragment FeedbackPage_viewer on Viewer {
        ...FeedbackForm_viewer
      }
    `,
  })

export default flowRight([withRelay, withRouter])(FeedbackPage)
