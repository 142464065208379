// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import Background from 'react-ui/components/Background'
import { Layout2Col } from 'react-ui/components/Layout'
import { type PaletteType } from 'react-ui/themes/synergy-base-theme/palette'
import { PublicFooter, PublicHeader } from 'shared/ui/Layout/Public'

import type { FelaPropsType } from 'react-ui/typing'

type ThemeType = {
  theme: {
    mq: Object,
    palette: PaletteType,
  },
}

type PropsType = FelaPropsType & {
  aside: Node,
  children: Node,
  fluid: boolean,
  reverse: boolean,
}

const styleRules = ({ theme }: ThemeType) => ({
  mainContentContainer: {
    alignItems: 'center',
    className: 'LayoutOnboarding__mainContentContainer',
    color: theme.palette.component.primary.text,
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    margin: '0 16px',
    // Add arbitrary paddingBottom to ensure that content can be scrolled
    // without being obscured by the fixed position footer.
    // TODO: This is a stopgap fix. Rebuild this layout properly.
    paddingBottom: '90px',

    [theme.mq.MULTI_COLUMN]: {
      margin: '170px 16px 0',
    },
  },

  asideOverride: {
    className: 'LayoutOnboarding__asideOverride',
    justifyContent: 'flex-start',

    [theme.mq.MULTI_COLUMN]: {
      marginTop: '218px',
    },
  },

  asideContentOverride: {
    alignItems: 'flex-start',
    className: 'LayoutOnboarding__asideContentOverride',
  },
})

const OnboardingLayout = ({
  styles = {},
  rules,
  children,
  aside,
  reverse,
  fluid,
}: PropsType) => (
  <Layout2Col
    aside={aside}
    reverse={reverse}
    fluid={fluid}
    extend={(...args) => ({
      aside: rules.asideOverride(...args),
      asideContent: rules.asideContentOverride(...args),
    })}
  >
    <Background>
      <PublicHeader white />
      <div className={styles.mainContentContainer}>{children}</div>
      <PublicFooter align={reverse ? 'left' : 'right'} white />
    </Background>
  </Layout2Col>
)

export default connect(styleRules)(OnboardingLayout)
