// @flow

import React from 'react'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import Section from 'react-ui/components/Section'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { SuspenseLoader } from 'care-ui'

import SupportingSupporteeInfoWrapper from './SupportingSupporteeInfoWrapper'
import SupportingSupporteeQuestionnairesList from './SupportingSupporteeQuestionnairesList'

const supporteesFragment = graphql`
  fragment SupportingSupporteeSelectionPage_supportees on IndividualRole
    @relay(plural: true) {
    ...QuestionnaireShowPage_supportee @relay(mask: false)
  }
`

const query = graphql`
  query SupportingSupporteeSelectionPage_Query(
    $id: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        name
        current_role {
          ... on SupportPersonRole {
            id
            supportees {
              ...SupportingSupporteeQuestionnairesList_supportees
              ...SupportingSupporteeSelectionPage_supportees
            }
            individual_assignments {
              ...SupportingSupporteeInfoWrapper_individual_assignments
            }
          }
        }
        ...SupportingSupporteeQuestionnairesList_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

const withoutSupportees = (
  <Heading level={3}>You are not supporting anyone</Heading>
)

function SupportingSupporteeSelectionPage() {
  const { id } = useCurrentUser()
  const { node } = useLazyLoadQuery(query, { id, count: 10, cursor: null })
  const { current_role, name } = node || {}
  const { supportees: supporteesFragmentRef, individual_assignments } =
    current_role || {}
  const supportees = useFragment(supporteesFragment, supporteesFragmentRef)

  const withSupportees = () => (
    <>
      <SupportingSupporteeQuestionnairesList
        user={node}
        supportees={supporteesFragmentRef}
      />
      <SupportingSupporteeInfoWrapper
        individual_assignments={individual_assignments}
      />
    </>
  )

  return (
    <ResponsiveLayout
      title={`Welcome ${name} and thank you for your time today!`}
    >
      <Section color="white" center>
        <Container>
          {supportees.length <= 0 ? withoutSupportees : withSupportees()}
        </Container>
      </Section>
    </ResponsiveLayout>
  )
}

const SupportingSupporteeSelectionPageLoader = () => {
  const currentUser = useCurrentUser()

  if (currentUser.completed_onboarding) {
    return (
      <SuspenseLoader>
        <SupportingSupporteeSelectionPage />
      </SuspenseLoader>
    )
  }

  return null
}

export default SupportingSupporteeSelectionPageLoader
