// @flow

import React from 'react'
import { connect } from 'react-fela'

import AlertIcon from 'react-ui/assets/icons/alert-circle.svg'
import Card from 'react-ui/components/Card'
import { H2 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import { Button } from 'care-ui'

const styledRules = ({ theme }) => ({
  page: {
    backgroundColor: theme.palette.bodyBackgroundBlue,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    maxWidth: '480px',
    minHeight: '400px',
    justifyContent: 'center',
  },
  cardHeader: {
    maxWidth: '400px',
  },
  errorCode: {
    color: theme.palette.component.muted.base,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
})

const PageError = ({ styles, rules, children, ...props }) => (
  <div className={styles.page}>
    <Card extend={rules.card}>
      <Icon as={AlertIcon} scale={4} />
      <H2 extend={rules.cardHeader}>{props.title || 'Error'}</H2>
      {children || (
        <Button variant="primary" to="/">
          Back to Home
        </Button>
      )}
      {props.errorCode && (
        <small className={styles.errorCode}>
          Error code: {props.errorCode}
        </small>
      )}
    </Card>
  </div>
)

export default connect(styledRules)(PageError)
