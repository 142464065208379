// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Icon from 'react-ui/components/Icon'
import { shortDate } from 'services/dateTime'
import TextToolTip from 'platform_web/components/TextToolTip'

import type { ScoreCardsChangeLog_changeLog } from './__generated__/ScoreCardsChangeLog_changeLog.graphql'

type PropsType = {
  changeLog: ScoreCardsChangeLog_changeLog,
  label: string,
}

const containerStyle = () => ({
  padding: '1rem',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
})

const iconStyle = () => ({
  marginRight: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const toolTipStyle = () => ({
  marginLeft: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ScoredCardsChangeLog = ({ changeLog, label }: PropsType) => {
  const { css } = useFela()

  const date = format(shortDate)(new Date(changeLog.created_at))
  return (
    <div className={css(containerStyle)}>
      <div className={css(iconStyle)}>
        <Icon as={InfoCircle} scale={0.5} />
      </div>
      <div>{`As of ${date}, ${label} was updated.`}</div>
      <div className={css(toolTipStyle)}>
        <TextToolTip label="Learn more.">{changeLog.reason}</TextToolTip>
      </div>
    </div>
  )
}

const ScoredCardsChangeLogLoader = createFragmentContainer(
  ScoredCardsChangeLog,
  {
    changeLog: graphql`
      fragment ScoreCardsChangeLog_changeLog on ChangeLogEntry {
        id
        created_at
        reason
      }
    `,
  },
)

export default ScoredCardsChangeLogLoader
