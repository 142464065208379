// @flow

import React, { useEffect } from 'react'
import { graphql, useFragment, usePaginationFragment } from 'react-relay'
import { map, remove, some, uniqueId } from 'lodash'

import Heading from 'react-ui/components/Heading'
import Spacer from 'react-ui/components/Spacer'
import { jsonSessionStorage } from 'services/browserStorage'

import SupportingSupporteeAssessment from './SupportingSupporteeQuestionnaire'

import type { SupportingSupporteeQuestionnairesList_supportees$key } from './__generated__/SupportingSupporteeQuestionnairesList_supportees.graphql'
import type { SupportingSupporteeQuestionnairesList_user$key } from './__generated__/SupportingSupporteeQuestionnairesList_user.graphql'

type PropsType = {
  supportees: SupportingSupporteeQuestionnairesList_supportees$key,
  user: SupportingSupporteeQuestionnairesList_user$key,
}

const supporteeFragment = graphql`
  fragment SupportingSupporteeQuestionnairesList_supportees on IndividualRole
    @relay(plural: true) {
    user {
      id
      legal_name
    }
    supporting_user_questionnaire {
      ensure_questionnaire {
        id
        label
      }
    }
  }
`

const paginationFragment = graphql`
  fragment SupportingSupporteeQuestionnairesList_user on User
    @argumentDefinitions(
      supporteeUserId: { type: "ID" }
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(
      queryName: "SupportingSupporteeQuestionnairesListPaginationQuery"
    ) {
    id
    current_role {
      ... on SupportPersonRole {
        assessment_request_questionnaires(
          supportee_user_id: $supporteeUserId
          first: $count
          after: $cursor
        )
          @connection(
            key: "SupportingSupporteeQuestionnairesList_assessment_request_questionnaires"
          ) {
          edges {
            node {
              questionnaire {
                id
                label
              }
              assessment_request {
                id
                individual {
                  id
                  legal_name
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const SupportingSupporteeQuestionnairesList = ({
  supportees: supporteesFragmentRef,
  user,
}: PropsType) => {
  const supportees = useFragment(supporteeFragment, supporteesFragmentRef)
  const { data } = usePaginationFragment(paginationFragment, user) || {}
  const { current_role: { assessment_request_questionnaires } } = data || {}
  const { edges } = assessment_request_questionnaires || {}
  const assessmentRequestQuestionnaires = map(edges, 'node')

  const questionnairesCopy = [...assessmentRequestQuestionnaires]
  const summaryQuestionnaires = remove(
    questionnairesCopy,
    ({ questionnaire }) => questionnaire?.label === 'Summary Questionnaire',
  )
  const sortedQuestionnaires = [...summaryQuestionnaires, ...questionnairesCopy]

  useEffect(() => {
    jsonSessionStorage.setItem('submittedSummaryQuestionnaire', false)
  }, [])

  const renderSummaryQuestionnaires = () => {
    return supportees.map(
      ({ user: supporteeUser, supporting_user_questionnaire }) => {
        const { id: answereeUserId, legal_name: supporteeName } = supporteeUser

        const shouldNotRender = some(
          summaryQuestionnaires,
          ({ assessment_request }) => {
            const {
              individual: { user: { id: currentUserId } },
            } = assessment_request
            return currentUserId === answereeUserId
          },
        )

        if (shouldNotRender) return null

        const { ensure_questionnaire } = supporting_user_questionnaire || {}
        const { id: summaryQuestionnaireId, label } = ensure_questionnaire
        return (
          <SupportingSupporteeAssessment
            key={uniqueId('summary-questionnaire-')}
            questionnaireId={summaryQuestionnaireId}
            label={label}
            answereeUserId={answereeUserId}
            supporteeName={supporteeName}
          />
        )
      },
    )
  }

  const renderAssessmentRequestQuestionnaires = () => {
    return sortedQuestionnaires.map(({ questionnaire, assessment_request }) => {
      const { id: questionnaireId, label: questionnaireLabel } = questionnaire
      const { individual } = assessment_request
      const {
        user: { id: answereeUserId },
        legal_name: supporteeName,
      } = individual
      return (
        <SupportingSupporteeAssessment
          key={uniqueId('assessment-request-questionnaire-')}
          questionnaireId={questionnaireId}
          label={questionnaireLabel}
          answereeUserId={answereeUserId}
          supporteeName={supporteeName}
          assessmentRequestId={assessment_request?.id}
        />
      )
    })
  }

  return (
    <>
      <Heading level={3}>
        You&apos;ve been requested to answer the following questionnaire(s)
        for...
      </Heading>
      <Spacer units={0.5} />
      {renderSummaryQuestionnaires()}
      {renderAssessmentRequestQuestionnaires()}
    </>
  )
}

export default SupportingSupporteeQuestionnairesList
