/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffIndividualSelectionList_user$ref: FragmentReference;
declare export opaque type StaffIndividualSelectionList_user$fragmentType: StaffIndividualSelectionList_user$ref;
export type StaffIndividualSelectionList_user = {|
  +id: string,
  +individuals: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +legal_name: ?string,
        +user: {|
          +id: string,
          +username: string,
        |},
      |}
    |}>
  |},
  +$refType: StaffIndividualSelectionList_user$ref,
|};
export type StaffIndividualSelectionList_user$data = StaffIndividualSelectionList_user;
export type StaffIndividualSelectionList_user$key = {
  +$data?: StaffIndividualSelectionList_user$data,
  +$fragmentRefs: StaffIndividualSelectionList_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "assigned"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "prioritise"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "individuals"
        ]
      }
    ]
  },
  "name": "StaffIndividualSelectionList_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "individuals",
      "args": [
        {
          "kind": "Variable",
          "name": "assignedToMe",
          "variableName": "assigned"
        },
        {
          "kind": "Variable",
          "name": "prioritiseEscalations",
          "variableName": "prioritise"
        },
        {
          "kind": "Variable",
          "name": "role_status",
          "variableName": "status"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "ClinicianIndividualConnection",
      "kind": "LinkedField",
      "name": "__Individuals_currentUser_individuals_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianIndividualEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "legal_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ecf72f99f9f1827527a0e82550b85fd';

module.exports = node;
