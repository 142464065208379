// @flow

import React, { useCallback, useState } from 'react'
import {
  createFragmentContainer,
  fetchQuery,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import debounce from 'lodash/debounce'

import Link from 'react-ui/components/Link'
import Spacer from 'react-ui/components/Spacer'
import { useTenant } from 'react-ui/contexts/TenantContext'
import { commit as commitUserUpdate } from 'mutations/UserUpdate'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import Sentry from 'shared/services/sentry'
import { Input, RadioSet } from 'shared/ui/Forms/Fields'
import { getLanguageFromLocale } from 'shared/utils/Internationalization'
import FieldUpdater from 'platform_web/components/Settings/FieldUpdater'

import type { UserSettingsUserFieldPage_user } from './__generated__/UserSettingsUserFieldPage_user.graphql'

type PropsType = {
  +user: UserSettingsUserFieldPage_user,
}

const usernameUniqueCheck = graphql`
  query UserSettingsUserFieldPage_usernameUniqueCheck_Query(
    $testUsername: String!
  ) {
    viewer {
      currentUser {
        usernameUniqueness(testUsername: $testUsername)
      }
    }
  }
`

function UserSettingsUserFieldPage({ user }: PropsType) {
  const { match: { params: { field } }, router } = useRouter()
  const [value, setValue] = useState(user[field])
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState()
  const environment = useRelayEnvironment()

  const { tenant } = useTenant()
  const { supported_languages = [] } = tenant || {}

  function handleSubmit(event) {
    event.preventDefault()

    commitUserUpdate({
      environment,
      variables: {
        input: {
          user: {
            [field]: value,
          },
        },
      },
      onCompleted: () => router.replace('settings_personal_details'),
    })
  }

  function handleChange({ target }: SyntheticInputEvent<*>) {
    setTouched(true)
    setValue(target.value)
  }

  const commonInputProps = {
    errors: error ? { [field]: error } : {},
    name: field,
    onChange: handleChange,
    touched: { [field]: touched },
    value,
    fullWidth: true,
    style: { maxWidth: '25rem' },
  }

  const {
    title,
    editComponent,
    description,
    submit,
    validator = () => true,
  } = (() => {
    switch (field) {
      case 'username':
        return {
          title: 'Username',
          editComponent: ({ setValid, setWaiting }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const validateUsername = useCallback(
              debounce(testUsername => {
                validateUsername.cancel()
                setWaiting(true)
                fetchQuery(environment, usernameUniqueCheck, {
                  testUsername,
                }).subscribe({
                  next: data => {
                    const {
                      viewer: { currentUser: { usernameUniqueness } },
                    } = data
                    setValid(usernameUniqueness)

                    setError(
                      usernameUniqueness
                        ? null
                        : 'Sorry, this username has already been taken',
                    )
                    return data
                  },
                  error: e => {
                    Sentry.captureException(e)
                    setError(e.message)
                  },
                })
                setWaiting(false)
              }, 750),
              [],
            )

            return (
              <Input
                {...commonInputProps}
                type="text"
                onChange={event => {
                  handleChange(event)
                  validateUsername(event.target.value)
                }}
              />
            )
          },
          description: 'What is your Username?',
          submit: 'Update username',
          validator: () => {
            return false
          },
        }
      case 'email':
        return {
          title: 'Email',
          editComponent: () => (
            <Input {...commonInputProps} type="email" title="Email address" />
          ),
          description: 'What is your email?',
          submit: 'Update email',
        }
      case 'legal_name':
        return {
          title: 'Legal name',
          editComponent: () => (
            <Input
              {...commonInputProps}
              type="text"
              pattern=".*[\w][\s]+[\w].*"
              title="Legal name must contain at least a first name and a family name."
            />
          ),
          description: 'What is your Legal Name?',
          submit: 'Update name',
        }
      case 'preferred_name':
        return {
          title: 'Preferred name',
          editComponent: () => <Input {...commonInputProps} type="text" />,
          description: 'What is your Preferred Name?',
          submit: 'Update name',
        }
      case 'preferred_language':
        return {
          title: 'Preferred Language',
          editComponent: () => (
            <RadioSet
              {...commonInputProps}
              options={supported_languages.map(supported_language => ({
                title: getLanguageFromLocale(supported_language),
                value: supported_language,
              }))}
              uiStyle="primary"
            />
          ),
          description: 'What is your Preferred Language?',
          submit: 'Update preferred language',
        }
      case 'phone_number':
        return {
          title: 'Add your mobile number',
          editComponent: () => (
            <Input
              {...commonInputProps}
              pattern="^(04)\d{8}$"
              placeholder="e.g. 0426664444"
              title="Mobile number is 10 digits and starts with 04 e.g. 0426664444"
              type="text"
            />
          ),
          description: (
            <>
              Your mobile number will be used to send you reminders and
              notifications. You can turn notifications on or off at any time on
              `your notifications` page.
              <Spacer units={1} />
              Innowell will never share your mobile number. If you want more
              information on your data please see our{' '}
              <Link to={privacyPolicyUrl()}>Privacy policy</Link>
              <Spacer units={1} />
              <strong>Mobile number</strong>
            </>
          ),
          submit: 'Update phone number',
        }
      default:
        throw new Error('Unknown edit type')
    }
  })()

  return (
    <FieldUpdater
      title={title}
      description={description}
      handleSubmit={handleSubmit}
      editComponent={editComponent}
      submit={submit}
      validator={validator}
    />
  )
}

export const UserSettingsUserFieldPageQuery = graphql`
  query UserSettingsUserFieldPage_Query {
    viewer {
      currentUser {
        ...UserSettingsUserFieldPage_user
      }
    }
  }
`

export const UserSettingsUserFieldPageLoader = createFragmentContainer(
  UserSettingsUserFieldPage,
  {
    user: graphql`
      fragment UserSettingsUserFieldPage_user on User {
        id
        legal_name
        preferred_name
        preferred_language
        phone_number
        username
        email
        unconfirmed_email
      }
    `,
  },
)
