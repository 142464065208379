// @flow

import React, { type Node, createContext, useContext, useState } from 'react'
import { useRouter } from 'found'

import { useCurrentUser } from 'react-ui/contexts/UserContext'

type PropsType = {
  children: Node,
  individualId?: string,
  individualUserId?: string,
}

const AssessmentRequesteeContext: any = createContext()

export const AssessmentRequesteeProvider = ({
  children,
  individualId,
  individualUserId,
}: PropsType) => {
  const [assessmentRequesteeId, setAssessmentRequesteeId] = useState()
  const [preSelected, setPreSelected] = useState()
  const { id: currentUserId, current_role } = useCurrentUser()
  const { role_type } = current_role || {}

  const isIndividual = role_type === 'INDIVIDUAL'

  const { match } = useRouter()
  const { params } = match || {}
  const { id: paramsId } = params || {}

  const userId = individualUserId || paramsId || currentUserId

  const value = {
    assessmentRequesteeId,
    setAssessmentRequesteeId,
    preSelected,
    setPreSelected,
    userId,
    isIndividual,
    individualId,
  }

  return (
    <AssessmentRequesteeContext.Provider value={value}>
      {children}
    </AssessmentRequesteeContext.Provider>
  )
}

export const useAssessmentRequesteeContext = () => {
  const context = useContext(AssessmentRequesteeContext)
  if (context === undefined) {
    throw new Error(
      'useAssessmentRequesteeContext must be used within a AssessmentRequesteeProvider',
    )
  }
  return context
}
