// @flow

import React from 'react'
import { type FormikProps } from 'formik'
import * as yup from 'yup'

import { createForm } from 'react-ui/components/Form'

type FormProps = {
  code: string,
}

type SubmitHandlerType = (FormProps, FormikProps) => void

type PropsType = {
  onSubmit: SubmitHandlerType,
}

const MultiFactorAuthenticationForm = (props: PropsType) => {
  const fieldDefinitions = [
    {
      block: true,
      id: 'code',
      fieldType: 'password',
      validationSchema: yup.string().required('Please enter verification code'),
      placeholder: 'Enter verification code',
    },
  ]

  const Form = createForm(fieldDefinitions)

  return (
    <Form {...(props: any)}>
      {({ errorMessage, renderedFields, submitButton }) => (
        <>
          {renderedFields}

          {errorMessage}

          {React.cloneElement(submitButton, {
            children: 'Next',
          })}
        </>
      )}
    </Form>
  )
}

export default MultiFactorAuthenticationForm
