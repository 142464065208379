// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import { isEmpty, uniqueId } from 'lodash'

import { FlexContainer, Icon, Text } from 'care-ui'
import ArrowRight from 'care-ui/atoms/icons/arrow-right.svg'

import { trackableColorfromColorCode } from './helpers'

import type { ActivityLogChangeSet_activityLog$key } from './__generated__/ActivityLogChangeSet_activityLog.graphql'

type PropsType = {
  activityLog: ActivityLogChangeSet_activityLog$key,
}

const resultStyle = ({ theme }) => ({
  textTransform: 'uppercase',
  padding: `${theme.care.spacing.xxxs} ${theme.care.spacing.xxs}`,
  borderRadius: theme.care.radius.sm,
})

const listContainerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.care.spacing.xxs} 0`,
  gap: theme.care.spacing.xxs,
})

const listStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: theme.care.spacing.xxs,
  padding: 0,
  margin: 0,
  listStyleType: 'none',
})

const trackableContainerStyle = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.care.spacing.xxs,
})

const trackableStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.care.spacing.xxxs} ${theme.care.spacing.xxs}`,
  borderRadius: theme.care.radius.sm,
  background: theme.care.palette.surface.disabled,
})

const labelStyle = () => ({
  display: 'flex',
})

const fragment = graphql`
  fragment ActivityLogChangeSet_activityLog on ActivityLog {
    action_type
    changeset
  }
`

const ActivityLogChangeSet = ({ activityLog: fragmentRef }: PropsType) => {
  const { action_type: actionType, changeset } = useFragment(
    fragment,
    fragmentRef,
  )

  const { css, theme } = useFela()
  if (isEmpty(changeset)) return null

  if (actionType === 'user_trackable_questionnaire_answer') {
    const { after, before } = changeset || {}

    const beforeColor = trackableColorfromColorCode({
      colorCode: before?.color,
      theme,
    })
    const afterColor = trackableColorfromColorCode({
      colorCode: after?.color,
      theme,
    })

    return (
      <FlexContainer gap="xxs" alignItems="center" marginTop="xxs">
        <Text
          size="sm"
          extend={[{ background: beforeColor }, resultStyle]}
          dataTestId="oldRating"
        >
          {before?.label}
        </Text>

        {after?.label && (
          <>
            <Icon as={ArrowRight} />

            <Text
              size="sm"
              extend={[{ background: afterColor }, resultStyle]}
              dataTestId="newRating"
            >
              {after?.label}
            </Text>
          </>
        )}
      </FlexContainer>
    )
  }

  if (actionType === 'user_trackable_write_changed') {
    const { added, removed } = changeset || {}
    const showDivider = !isEmpty(added) && !isEmpty(removed)

    const dividerStyle = showDivider && {
      borderTop: `1px solid ${theme.care.palette.border.lighter}`,
    }

    return (
      <div className={css(trackableContainerStyle)}>
        {!isEmpty(removed) && (
          <div className={css(listContainerStyle)}>
            <Text size="sm" color="title" extend={labelStyle}>
              Removed
            </Text>
            <ul className={css(listStyle)}>
              {removed.map(item => (
                <Text
                  as="li"
                  size="sm"
                  extend={trackableStyle}
                  key={uniqueId('removed-trackable-')}
                >
                  {item}
                </Text>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(added) && (
          <div className={css(dividerStyle, listContainerStyle)}>
            <Text size="sm" color="title" extend={labelStyle}>
              Added
            </Text>
            <ul className={css(listStyle)}>
              {added.map(item => (
                <Text
                  as="li"
                  size="sm"
                  extend={trackableStyle}
                  key={uniqueId('added-trackable-')}
                >
                  {item}
                </Text>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return null
}

export default ActivityLogChangeSet
