// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { graphql, useFragment } from 'react-relay'
import { useRouter } from 'found'
import { get, uniqueId } from 'lodash/fp'

import { Button, Grid, GridItem, Heading, Text } from 'care-ui'

import HealthCard from '../HealthCard'

import { containerStyle, subtitleStyle } from './HealthPreferences.style'
import useSaveUserTrackable from './useSaveUserTrackable'

import type { HealthPreferences_user_trackables$key } from './__generated__/HealthPreferences_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthPreferences_user_trackables$key,
}

const healthCardListFragment = graphql`
  fragment HealthPreferences_user_trackables on UserTrackableConnection {
    nodes {
      id
      in_plan
      trackable {
        id
      }
      ...HealthCard_user_trackable
    }
  }
`

const HealthPreferences = ({ user_trackables: fragmentRef }: PropsType) => {
  const { nodes: user_trackables } = useFragment(
    healthCardListFragment,
    fragmentRef,
  )

  const [inPlan, setInPlan] = useState([])

  const { css } = useFela()
  const { router } = useRouter()

  useEffect(
    () => {
      const newInPlan = (user_trackables || []).reduce(
        (arr, user_trackable) => {
          if (user_trackable && user_trackable.in_plan) {
            arr.push(user_trackable.trackable.id)
          }
          return arr
        },
        [],
      )

      setInPlan(newInPlan)
    },
    [user_trackables],
  )

  useEffect(
    () => {
      window.scrollTo(0, 0)
    },
    [window],
  )

  const action = (trackableId: string) => {
    if (inPlan?.includes(trackableId)) {
      return setInPlan(prev => prev?.filter(id => id !== trackableId))
    } else if (inPlan && inPlan.length < 3) {
      return setInPlan(prev => prev?.concat(trackableId))
    }

    return undefined
  }

  const { commit } = useSaveUserTrackable()
  const handleTrackablePreferencesSave = () => {
    commit({
      variables: {
        input: {
          trackableIds: inPlan,
        },
      },
      onCompleted: () => {
        router.push({ name: 'dashboard' })
      },
    })
  }

  const healthCards = user_trackables?.map(user_trackable => {
    const trackableId = get('trackable.id')(user_trackable)
    const selected = inPlan?.includes(trackableId)

    return (
      user_trackable && (
        <GridItem
          key={uniqueId('health-preference-')}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          center
        >
          <HealthCard
            user_trackable={user_trackable}
            selectable
            action={action}
            selected={selected}
          />
        </GridItem>
      )
    )
  })

  return (
    <Grid extend={containerStyle}>
      <GridItem>
        <Heading level={1}>Select Health Domains</Heading>
      </GridItem>
      <GridItem>
        <Text className={css(subtitleStyle)}>
          Select up to 3 domains to focus on to improve your overall well-being.
        </Text>
      </GridItem>
      <GridItem>
        <Grid>{healthCards}</Grid>
      </GridItem>
      <GridItem center>
        <Button
          variant="primary"
          onClick={handleTrackablePreferencesSave}
          ariaLabel="Save"
          dataTestId="save"
        >
          Save
        </Button>
      </GridItem>
    </Grid>
  )
}

export default HealthPreferences
