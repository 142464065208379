// @flow

import React from 'react'
import classNames from 'classnames'
import { compact, omit } from 'lodash'

import DataGridRowFooterContent, {
  DataGridRowFooterRenderer,
} from 'components/DataGridRowFooter'
import DataGrid, { TextCell } from 'react-ui/components/DataGrid'
import { userColumnConfig } from 'react-ui/components/Tables/columns'
import UserCard from 'react-ui/components/UserCard'
import { roleStatusWithUserInvitationCreatedAt } from 'services/roles'
import StaffActions from 'platform_web/components/Staff/StaffActions'

import type { QueryVariablesType } from '../hooks/useEmployeesTable'

import type { StaffEmployeesTableLoader_staff_admin } from './__generated__/StaffEmployeesTableLoader_staff_admin.graphql'

const customStyle = () => ({
  DataGrid: {
    maxHeight: 'initial',
  },
  headerVisible: {
    '& div': {
      ':last-child': {
        borderColor: 'transparent',
      },
    },
  },
  body: {
    '& .TextCell': {
      paddingTop: 0,
      '&.actionCell': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      },
      '&.roleList': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    '& .roleRow': {
      lineHeight: '44px',
      marginBottom: '10px',
      clear: 'both',
      '& > div': {
        float: 'right',
      },
      ':last-child': {
        marginBottom: 0,
      },
      ':after': {
        display: 'table-cell',
        content: '',
        clear: 'both',
      },
    },
    '& .BasicCellRenderer': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      padding: '25px 16px',
      '&:first-child': {
        paddingLeft: '60px',
      },
      '&:last-child': {
        padding: 0,
        '& > div': {
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            margin: 0,
            padding: '1rem',
          },
        },
      },
      '& div': {
        padding: 0,
      },
    },
  },
  row: {
    maxHeight: '80px',
    minWidth: '540px',
  },
  rowParentBorder: {
    position: 'relative',
  },
})
type TenantType = {
  requires_mfa: boolean,
}

type StaffEmployeesTableType = {
  queryVariables: QueryVariablesType,
  staff_admin: StaffEmployeesTableLoader_staff_admin,
  tenant: TenantType,
}

const renderRow = (data, index, props, children, footer) => {
  const { componentId } = props
  const { id, roles } = data

  // rowId is the className that is used for backend tests
  const rowId = componentId && id ? `${componentId}-${id}` : ''

  const newProps = omit(
    {
      ...props,
      className: classNames(props.className, rowId),
    },
    ['componentId', 'border', 'status'],
  )

  const existingStatusRoles = roles.filter(
    ({ role_status }) => role_status === props.status,
  )

  return existingStatusRoles.length ? (
    <div key={props.key} className={props.border}>
      <div {...newProps}>{children}</div>
      {footer && <DataGridRowFooterRenderer content={footer(data)} />}
    </div>
  ) : (
    ''
  )
}

const footer = ({ user }) => {
  return <DataGridRowFooterContent user={user} />
}

const StaffEmployeesTable = (tableProps: StaffEmployeesTableType) => {
  const { staff_admin: { staff: { edges } }, queryVariables } = tableProps
  const { status } = queryVariables

  return (
    <DataGrid
      data={compact(edges)}
      componentId="StaffEmployeesTableBody"
      extend={customStyle}
      renderRow={renderRow}
      footer={footer}
      mapRowProps={(rowData, _, rowProps) => ({
        ...rowProps,
        status,
      })}
      mapData={({ node, node: { ...props } }) => ({
        actions: node,
        email: props.user.email,
        status,
        ...props,
      })}
      columns={['user', 'email', 'roles', 'actions']}
      columnConfig={{
        user: {
          ...userColumnConfig,
          label: 'Employee name',
          width: 'calc(calc(100% + 120px)/3)',
          sortable: false,
          format: user => <UserCard user={user} link={false} staff />,
        },
        email: {
          label: 'Email address',
          width: 'calc(calc(100% - 60px)/3)',
          format: email => <TextCell>{email}</TextCell>,
          sortable: false,
        },
        roles: {
          label: 'Role(s)',
          width: 'calc(calc(100% - 180px)/3)',
          sortable: false,
          format: (roles, row) => (
            <TextCell className="actionCell">
              <TextCell className="roleList">
                {roles.map(
                  ({ id, role_status, description, tenant: roleTenant }) => {
                    return role_status && role_status === status ? (
                      <span
                        title={roleTenant?.name}
                        key={id}
                      >{`${description} ${roleStatusWithUserInvitationCreatedAt(
                        role_status,
                        row?.user?.invitation_created_at,
                      )}`}</span>
                    ) : null
                  },
                )}
              </TextCell>
            </TextCell>
          ),
        },
        actions: {
          width: '40px',
          label: ' ',
          sortable: false,
          format: node => <StaffActions user={node?.user} roles={node.roles} />,
        },
      }}
    />
  )
}

export default StaffEmployeesTable;