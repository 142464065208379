// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'

import escalatedIcon from 'react-ui/assets/icons/Icon material-warning.svg'
import Icon from 'react-ui/components/Icon'
import Link from 'react-ui/components/Link'
import { commit as commitEscalationResolve } from 'mutations/EscalationResolveUpdate'
import titleize from 'services/titleize'
import { createFlash } from 'platform_web/containers/Flash'
import {
  Button,
  FlexContainer,
  FlexItem,
  SimpleSelect,
  Text,
  TextArea,
} from 'care-ui'

import type { CurrentResultCardFormula_user_trackable } from './__generated__/CurrentResultCardFormula_user_trackable.graphql'

type PropsType = {
  careOptionSupported: boolean,
  setNotification: Function,
  userTrackable: CurrentResultCardFormula_user_trackable,
}

const careOptionAlert = () => ({
  fontSize: '0.9rem',
  width: '100%',
  textAlign: 'center',
})

const ScoreCardsNotifications = ({
  userTrackable,
  setNotification,
  careOptionSupported,
}: PropsType) => {
  const [option, setOption] = useState('')
  const [textOption, setTextOption] = useState('')
  const environment = useRelayEnvironment()
  const otherOption = option === 'other'
  const careOption = option === 'care_option_assigned'
  const { id, resolution_option_list } = userTrackable
  const { css } = useFela()

  const completeSubmit = () => {
    setNotification(true)

    createFlash({
      message: 'Thank you, your input was recorded.',
      type: 'notice',
      timeout: 0,
    })
  }

  const hasValidAnswer = () => {
    const hasSelectedOption = option !== ''

    if (hasSelectedOption) {
      if (careOption && careOptionSupported) {
        return true
      }
      if (otherOption && textOption.trim() !== '') {
        return true
      }
      if (!otherOption && !careOption) {
        return true
      }
    }

    return false
  }

  const handleOnChange = value => {
    setOption(value)
  }

  const EscalationResolutionOptions = [
    'care_option_assigned',
    'other',
    'transferred_to_another_service',
    'unable_to_contact',
    '%future added value',
  ]

  const handleSubmit = () => {
    if (option === '') {
      return false
    }

    commitEscalationResolve({
      onCompleted: () => completeSubmit(),
      environment,
      variables: {
        input: {
          id,
          escalation: {
            resolutionOption:
              EscalationResolutionOptions.find(
                resolutionOption => resolutionOption === option,
              ) || 'other',
            resolutionText: otherOption ? textOption : null,
          },
        },
      },
    })

    return true
  }

  /** Disabling checking for this lines since ESLINT is complaining about the use of onChange on the dropdowns */
  /* eslint-disable */

  const resolutionOptionList = resolution_option_list.map(option => ({
    value: option.key,
    label: titleize(option.value),
  }))

  return (
    <FlexContainer
      direction="column"
      justifyContent="space-between"
      height="100%"
      gap="sm"
    >
      <FlexContainer direction="column" gap="xs">
        <FlexContainer direction="row" wrap="nowrap" gap="xs">
          <FlexItem>
            <Icon color="hsla" as={escalatedIcon} />
          </FlexItem>

          <Text size="md">
            <span>
              This individual has registered a{' '}
              <Link to="staff_individuals">notification</Link>. Please take
              action.
            </span>
          </Text>
        </FlexContainer>

        <SimpleSelect
          onChange={value => handleOnChange(value)}
          options={resolutionOptionList}
          dataTestIdForDropdown="actionDropdown"
          dataTestIdForDropdownOptions="actions"
        />

        {otherOption && (
          <TextArea
            onBlur={({ target }) => setTextOption(target.value)}
            onChange={({ target }) => setTextOption(target.value)}
            rows={3}
            value={textOption}
          />
        )}

        {careOption &&
          !careOptionSupported && (
            <p className={css(careOptionAlert)}>
              Please select a care option and then submit
            </p>
          )}
      </FlexContainer>

      <FlexItem alignSelf="center">
        <Button
          disabled={!hasValidAnswer()}
          onClick={handleSubmit}
          variant="primary"
        >
          Submit
        </Button>
      </FlexItem>
    </FlexContainer>
  )
}

export default ScoreCardsNotifications
