// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { format } from 'date-fns/esm/fp'
import { first, flow, reverse, sortBy } from 'lodash/fp'

import { UserQuestionSetDetailsViewLoader } from 'components/UserQuestionSet/UserQuestionSetDetailsView'
import { ButtonGroup } from 'react-ui/components/Button'
import { shortDate } from 'services/dateTime'
import createComponentId from 'shared/services/id'
import { BasicTable } from 'shared/ui/Tables'

import type { UserQuestionSetTable_user_question_sets } from './__generated__/UserQuestionSetTable_user_question_sets.graphql'

type PropsType = {
  componentId?: string,
  relay: Object,
  user_question_sets: UserQuestionSetTable_user_question_sets,
}

const mostRecentAnswerSet = flow(sortBy('submitted_at'), reverse, first)

const renderedAnswerSet = (userQuestionSet: {
  +submitted_answer_sets: { +submitted_at: string },
}) => {
  const answerSet = mostRecentAnswerSet(userQuestionSet.submitted_answer_sets)
  return answerSet ? format(shortDate)(new Date(answerSet.submitted_at)) : null
}

const defaultId = createComponentId(__filename)

export const answerStatus = ({
  skipped,
  skip_reason_value,
}: {
  skip_reason_value: string,
  skipped: string,
}) => (skipped ? `Skipped - ${skip_reason_value}` : 'Completed')

const UserQuestionSetTableBase = (props: PropsType) => {
  const { componentId = defaultId, user_question_sets } = props

  return (
    <BasicTable
      className={componentId}
      data={user_question_sets}
      renderHeaders={() => (
        <tr>
          <th>Question Set</th>
          <th>Last Answered</th>
          <th>Status</th>
          <th>Answered by</th>
          <th />
        </tr>
      )}
      sort={sortBy('label')}
    >
      {userQuestionSet => {
        const latestAnswer = mostRecentAnswerSet(
          userQuestionSet.submitted_answer_sets,
        )
        return (
          <tr
            className={`${componentId}-${userQuestionSet.id}`}
            key={userQuestionSet.id}
          >
            <td>{userQuestionSet.question_set.label}</td>
            <td>{renderedAnswerSet(userQuestionSet)}</td>
            <td>{answerStatus(latestAnswer)}</td>
            <td>{latestAnswer.answered_by.user.name}</td>

            <td>
              <ButtonGroup>
                <UserQuestionSetDetailsViewLoader
                  user_question_set={userQuestionSet}
                />
              </ButtonGroup>
            </td>
          </tr>
        )
      }}
    </BasicTable>
  )
}

export const UserQuestionSetTableLoader = createFragmentContainer(
  UserQuestionSetTableBase,
  {
    user_question_sets: graphql`
      fragment UserQuestionSetTable_user_question_sets on UserQuestionSet
        @relay(plural: true) {
        ...UserQuestionSetDetailsView_user_question_set
        id
        submitted_answer_sets {
          submitted_at
          skip_reason_value
          skipped
          answered_by {
            ... on RoleInterface {
              id
              user {
                name
              }
            }
          }
        }
        question_set {
          id
          label
        }
      }
    `,
  },
)
