// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RemoveGenericMutationResponse,
  RemoveGenericMutationVariables,
} from './__generated__/RemoveGenericMutation.graphql'

const mutation = graphql`
  mutation RemoveGenericMutation($input: RoleRemoveInput!) {
    roleRemove(input: $input) {
      role {
        ... on RoleInterface {
          id
          ...StaffEmployeesTableLoader_role
          tenant {
            id
            individuals: roles(roleTypes: [INDIVIDUAL]) {
              edges {
                node {
                  ...IndividualsTable_individual
                }
              }
            }
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: RemoveGenericMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: RemoveGenericMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
