// @flow

export type OnboardingStepType = {
  active: boolean,
  completed: boolean,
  label: string,
  order: number,
}

export type OnboardingStepTypes = {
  [key: string]: OnboardingStepType,
}

export const individualOnboardingSteps: OnboardingStepTypes = {
  PERSONAL_DETAILS: {
    order: 1,
    label: 'Tell us a bit about yourself',
    completed: false,
    active: false,
  },
  RESEARCH_AND_DATA_SHARING: {
    order: 2,
    label: 'Research and data sharing',
    completed: false,
    active: false,
  },
  INITIAL_QUESTIONNAIRE: {
    order: 3,
    label: 'Initial questionnaire',
    completed: false,
    active: false,
  },
  VIEW_RESULTS: {
    order: 4,
    label: 'View results',
    completed: false,
    active: false,
  },
}

export const individualOnboardingStepNumber = {
  PERSONAL_DETAILS: 1,
  RESEARCH_AND_DATA_SHARING: 2,
  INITIAL_QUESTIONNAIRE: 3,
  VIEW_RESULTS: 4,
}
