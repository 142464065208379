// @flow

import React from 'react'
import { useFela } from 'react-fela'

import Plus from 'react-ui/assets/icons/plus.svg'
import {
  Box,
  Button,
  FlexContainer,
  FlexItem,
  Heading,
  Icon,
  Text,
} from 'care-ui'

import { descriptionStyle } from './AssessmentRequestsItem.style'

type PropsType = {
  description: ?string,
  label: string,
  questionnaireId: string,
  toggleList: string => void,
}

const AssessmentRequestsItem = ({
  label,
  description,
  toggleList,
  questionnaireId,
}: PropsType) => {
  const { css } = useFela()

  return (
    <Box
      width="100%"
      height="260px"
      paddingX="sm"
      paddingY="sm"
      borderRadius="sm"
      boxShadow={3}
    >
      <FlexContainer direction="column" justifyContent="space-between">
        <Box height="210px">
          <Heading level={4}>{label}</Heading>

          <Text
            size="md"
            color="title"
            title={description}
            className={css(descriptionStyle)}
          >
            {description}
          </Text>
        </Box>

        <FlexItem alignSelf="end">
          <Button
            onClick={() => toggleList(questionnaireId)}
            dataTestId="toggle-list"
          >
            <Icon as={Plus} scale={0.8} />
          </Button>
        </FlexItem>
      </FlexContainer>
    </Box>
  )
}

export default AssessmentRequestsItem
