// @flow

import React from 'react'

import Plus from 'react-ui/assets/icons/plus.svg'
import { Button, Icon, Modal } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import ClinicianNoteForm from './ClinicianNoteForm'

type PropsType = {
  userId: string,
}

const ClinicianNoteModal = ({ userId }: PropsType) => {
  const { isOpen, closeModal, openModal } = useModal()

  const triggerLabel = <Icon as={Plus} scale={0.7} />

  return (
    <>
      <Button onClick={openModal} dataTestId="clinician-note-modal-trigger">
        {triggerLabel}
      </Button>

      <Modal isOpen={isOpen} heading="Create Note" closeModal={closeModal}>
        <ClinicianNoteForm userId={userId} onToggleOpen={closeModal} />
      </Modal>
    </>
  )
}

export default ClinicianNoteModal
