// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"14\" viewBox=\"0 0 29 14\" width=\"29\"><path fill=\"#d1d1d1\" d=\"M9.37727 3.625l5.08154 6.93523L19.54036 3.625\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminForm__adminForm___hIYlj [type=date],.AdminForm__adminForm___hIYlj [type=email],.AdminForm__adminForm___hIYlj [type=file],.AdminForm__adminForm___hIYlj [type=number],.AdminForm__adminForm___hIYlj [type=password],.AdminForm__adminForm___hIYlj [type=search],.AdminForm__adminForm___hIYlj [type=tel],.AdminForm__adminForm___hIYlj [type=text],.AdminForm__adminForm___hIYlj [type=url],.AdminForm__adminForm___hIYlj textarea,.AdminForm__adminForm___hIYlj select{-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:3px;border:1px solid #c5c5c5;box-shadow:1px 1px 3px rgba(0,0,0,.1) inset;box-sizing:inherit;margin:.25rem 0 .5rem;padding:.5rem;width:100%}.AdminForm__adminForm___hIYlj [type=checkbox]{vertical-align:middle;margin-left:1rem}.AdminForm__adminForm___hIYlj select{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center right no-repeat;background-color:#fff}.AdminForm__adminForm___hIYlj button[type=submit]{margin:1rem 0}.AdminForm__adminForm___hIYlj fieldset{box-sizing:border-box;border:1px solid #999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminForm": "AdminForm__adminForm___hIYlj"
};
export default ___CSS_LOADER_EXPORT___;
